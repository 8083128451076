import styles from './Form.module.scss'
import { LoadingBlocker } from '@/components/Loader'
import React, { useRef, useState } from 'react'
import { ModalTemplate } from '@/ui'
import { ECollectiveContractForm } from '@/entity/eCollectiveContracts/ui/form'


export const Form = ({ initialValues,formRef,tradeUnion, isEdit,saveColContract, options = {}, onCancel, onSubmit }) => {

	return (
		<div>
			<ModalTemplate hideBackButton onClose={onCancel}>
				<div className={`scroll ${styles.wrapper}`}>
					{options?.workTimeCollectiveContract?.length > 0 && options?.relaxTimeCollectiveContract?.length > 0 ? (
						<ECollectiveContractForm
							saveColContract={saveColContract}
							initialValues={initialValues}
							options={options}
							onCancel={onCancel}
							onSubmit={onSubmit}
							isEdit={isEdit}
							formRef={formRef}
							tradeUnion={tradeUnion}
						></ECollectiveContractForm>
					) : (
						<LoadingBlocker localStyles={{ width: '65vw', height: '50vw' }} />
					)}
				</div>
			</ModalTemplate>
		</div>
	)
}
