import * as types from './types'

const data = {
	loading: false,
	success: false,
	failed: false,
	data: null
}

const initialState = {
	agreements: data,
	download: data,
	faq: data,
	faqDict: data,
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.GET_AGREEMENT_DATA:
			return { ...state, agreements: {...data, loading: true} }

		case types.GET_AGREEMENT_DATA_DONE:
			return { ...state, agreements: {...data, success: true, data: payload} }

		case types.GET_AGREEMENT_DATA_FAIL:
			return { ...state, agreements: {...data, failed: true} }


		case types.GET_ONE_AGREEMENT:
			return { ...state, download: {...data, loading: true} }

		case types.GET_ONE_AGREEMENT_DONE:
			return { ...state, download: {...data, success: true, data: payload} }

		case types.GET_ONE_AGREEMENT_FAIL:
			return { ...state, download: {...data, failed: true} }


		case types.GET_FAQ_DATA:
			return { ...state, faq: {...data, loading: true} }

		case types.GET_FAQ_DATA_DONE:
			return { ...state, faq: {...data, success: true, data: payload} }

		case types.GET_FAQ_DATA_FAIL:
			return { ...state, faq: {...data, failed: true} }


		case types.GET_FAQ_DICT:
			return { ...state, faqDict: {...data, loading: true} }

		case types.GET_FAQ_DICT_DONE:
			return { ...state, faqDict: {...data, success: true, data: payload} }

		case types.GET_FAQ_DICT_FAIL:
			return { ...state, faqDict: {...data, failed: true} }

		default:
			return state
	}
}
