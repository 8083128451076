import React from 'react'
import styles from './WorkplaceInformation.module.scss'
import { DataGridTable } from '@/ui'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '@/utils'
import { getCurrentLanguage } from '@/i18next'
import { useSelector } from 'react-redux'
import { staffingSelectors } from '@/entity'

export const WorkplaceInformation = ({ workplace }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const langPositionName = lang === 'rus' ? 'nameRu' : 'nameKz'
	const innerStatInfo = useSelector(staffingSelectors.wplaceEmployeeStatInfo)

	const workplaceInformation = () => {
		return [
			{ title: t('staffing_table.group_code'), data: workplace.codeGroup },
			{ title: t('staffing_table.workplace_code_label'), data: workplace.code },
			{ title: t('staff_modal.position_kz'), data: workplace.establishedPost.nameKz },
			{ title: t('staff_modal.position_ru'), data: workplace.establishedPost.nameRu },
			{
				title: t('contract.info.nkz_position'),
				data: workplace.dposition ? workplace.dposition[langPositionName] : '',
			},
			...(workplace.dstaffCategory
				? [{ title: t('staff_modal.position_category'), data: workplace.dstaffCategory[langPositionName] }]
				: []),
			{ title: t('staff_modal.freelancer'), data: workplace.freelance ? t('yes') : t('no') },
			{ title: t('staff.count_bets'), data: (innerStatInfo?.workplaceTariffRateSum || 0) + '/' + (innerStatInfo?.freeWorkplaceTariffRateSum || 0) },
			{ title: t('staffing_table.salary'), data: formatNumber(workplace.grossSalary || '') },
		]
	}
	return (
		<DataGridTable
			hideSubTitle
			titleClass={styles.tableTitleCell}
			title={t('workplace.title')}
			information={workplaceInformation()}
		/>
	)
}
