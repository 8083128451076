import * as types from './types'

export const actions = {
	getAgreementsDataAction: (payload) => ({
		type: types.GET_AGREEMENT_DATA,
		payload,
	}),
	getAgreementsDataActionDone: (payload) => ({
		type: types.GET_AGREEMENT_DATA_DONE,
		payload,
	}),
	getAgreementsDataActionFail: (payload) => ({
		type: types.GET_AGREEMENT_DATA_FAIL,
		payload,
	}),

	getOneAgreementAction: (payload) => ({
		type: types.GET_ONE_AGREEMENT,
		payload,
	}),
	getOneAgreementActionDone: (payload) => ({
		type: types.GET_ONE_AGREEMENT_DONE,
		payload,
	}),
	getOneAgreementActionFail: (payload) => ({
		type: types.GET_ONE_AGREEMENT_FAIL,
		payload,
	}),

	getFaqDataAction: (payload) => ({
		type: types.GET_FAQ_DATA,
		payload,
	}),
	getFaqDataActionDone: (payload) => ({
		type: types.GET_FAQ_DATA_DONE,
		payload,
	}),
	getFaqDataActionFail: (payload) => ({
		type: types.GET_FAQ_DATA_FAIL,
		payload,
	}),

	getFaqDictAction: (payload) => ({
		type: types.GET_FAQ_DICT,
		payload,
	}),
	getFaqDictActionDone: (payload) => ({
		type: types.GET_FAQ_DICT_DONE,
		payload,
	}),
	getFaqDictActionFail: (payload) => ({
		type: types.GET_FAQ_DICT_FAIL,
		payload,
	}),
}
