import React, { useEffect } from 'react'
import { ModalTemplate, Title } from '@/ui'
import styles from './GroupWorkPlaceModalForm.module.scss'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { ValidationSchema } from './validationSchema'
import { GroupWorkPlaceForm } from './GroupWorkPlaceForm'
import { useDispatch, useSelector } from 'react-redux'
import { contractActionsSelectors } from '@features/contracts/model'
import { LoadingBlocker, Loader } from '@/components/Loader'
import { staffSelectors, staffThunks } from '@/entity/staffingProject'
import { GroupWorkPlaceLmcForm } from '@features/staffing/components/groupWorkPlace/GroupWorkPlaceLmcForm'
import { DigitalSign } from '@/utils/digitalSign'
import { message } from 'antd'
import { staffingSelectors, staffingThunks } from '@/entity'

function mapDictData(data, position, positionByKnz, laborMobilityCenterCategorys) {
	const isDicPosition2 = data.isDicPosition === '2'

	return {
		destablishedPost: {
			code: isDicPosition2 ? null : position.code,
			nameKz: isDicPosition2 ? data.nameKz : position.kz,
			nameRu: isDicPosition2 ? data.nameRu : position.rus,
		},
		dposition: {
			code: positionByKnz.code,
			nameKz: positionByKnz.kz,
			nameRu: positionByKnz.rus,
		},
		dstaffCategory: {
			code: laborMobilityCenterCategorys.code,
			nameKz: laborMobilityCenterCategorys.kz,
			nameRu: laborMobilityCenterCategorys.rus,
		},
	}
}

export const GroupWorkPlaceModalForm = ({
	initialValues,
	onClose,
	actions,
	activeStructId,
	staff,
	lmc, //Признак ЦТМ (Центра трудовой мобильности)
	groupRow,
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { edit, create } = actions
	const isSuccess = useSelector(staffSelectors.isSuccess)
	const isLoading = useSelector(staffingSelectors.isLoading)
	const { positions, positionsByKnz, laborMobilityCenterCategory } = useSelector(contractActionsSelectors.options)
	const isActualStaff = staff.actual

	const digitalSign = new DigitalSign()

	useEffect(() => {
		if (isSuccess) {
			onClose()
		}
	}, [isSuccess])

	const onSubmit = (data) => {
		let position = positions?.find((item) => item.code === data.destablishedPost)
		let positionByKnz = positionsByKnz?.find((item) => item.code === data.dposition)
		let laborMobilityCenterCategorys = laborMobilityCenterCategory?.find((item) => item.code === data.dstaffCategory)
		const dictMapData = mapDictData(data, position, positionByKnz, laborMobilityCenterCategorys)
		let newGroupWorkPlace = {}
		if (create) {
			newGroupWorkPlace = {
				...dictMapData,
				factSalary: data.factSalary,
				freelance: data.freelance,
				grossSalary: data.grossSalary,
				salarySupplement: data.salarySupplement,
				structureExternalId: activeStructId,
				workplaceCount: data.workplaceCount,
			}
			dispatch(staffThunks.createGroupWorkPlace(newGroupWorkPlace))
		} else {
			newGroupWorkPlace = {
				...dictMapData,
				freelance: data.freelance,
				posGroupExternalId: groupRow.externalId,
				...(isActualStaff ? { structureExternalId: activeStructId } : {}),
			}
			if (isActualStaff) {
				const type = 'posGroup'
				let body = {
					newGroupWorkPlace,
					type,
					activeStructId,
					onSuccess: async (xml) => {
						const res = await digitalSign.handleSend(xml)

						let signedBody = {
							data: { data: res },

							onSuccess: () => {
								message.success('Группа успешно изменена!')
								onClose()
							},
							type,
							activeStructId,
						}

						dispatch(staffingThunks.sendSignActualGroup(signedBody))
					},
				}

				dispatch(staffingThunks.setActualEditGroup(body))
			} else {
				dispatch(staffThunks.editGroupWorkPlace(newGroupWorkPlace, activeStructId))
			}
		}
	}

	let editGroupWorkPlace
	if (edit && groupRow) {
		let establishedPostNames = { ...groupRow.establishedPost }
		let otherProps = { ...groupRow }
		editGroupWorkPlace = {
			...(establishedPostNames || {}),
			...(otherProps || {}),
			isDicPosition: !establishedPostNames.code ? '2' : '3',
			destablishedPost: establishedPostNames.code ? establishedPostNames.code : null,
			dposition: groupRow?.dposition?.code ? groupRow?.dposition?.code : null,
			dstaffCategory: groupRow?.dstaffCategory?.code ? groupRow?.dstaffCategory?.code : null,
		}
	}

	return (
		<>
			<ModalTemplate onClose={onClose} priority={edit || create ? 2 : 1}>
				<div className={`scroll ${styles.wrapper}`}>
					<Title color="black">{create ? t('staff.create_group') : t('staff.edit_group')}</Title>
					<Formik
						initialValues={create ? initialValues : editGroupWorkPlace}
						validationSchema={ValidationSchema(edit)}
						onSubmit={onSubmit}
					>
						{positions?.length > 0 && positionsByKnz?.length > 0 && laborMobilityCenterCategory?.length > 0 ? (
							<>
								{lmc ? (
									<GroupWorkPlaceLmcForm onClose={onClose} edit={edit} actualStaffing={isActualStaff} />
								) : (
									<GroupWorkPlaceForm onClose={onClose} edit={edit} actualStaffing={isActualStaff} />
								)}
							</>
						) : (
							<div className={styles.loaderWrapper}>
								<Loader />
							</div>
						)}
					</Formik>
				</div>
			</ModalTemplate>

			{isLoading ? <LoadingBlocker /> : null}
		</>
	)
}
