import React from 'react'
import { useTranslation } from 'react-i18next'
import { DataGridTable } from '@/ui'
import styles from './GroupWorkPlaceInformation.module.scss'
import { getCurrentLanguage } from '@/i18next'

export const GroupWorkPlaceInformation = ({ groupRow }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const optionLang = lang === 'rus' ? 'nameRu' : 'nameKz'

	let errors = groupRow.errors
	const errorInformation = () => {
		const errorDescriptions = errors.map((error) => error.code + ': ' + error[optionLang])
		return [
			{
				title: t('staff.errors'),
				data: errorDescriptions.join(',\n'),
			},
		]
	}

	const positionInformation = () => {
		return [
			{ title: t('staffing_table.group_code'), data: groupRow.code },
			{ title: t('staff_modal.position_kz'), data: groupRow.establishedPost.nameKz },
			{ title: t('staff_modal.position_ru'), data: groupRow.establishedPost.nameRu },
			{
				title: t('contract.info.nkz_position'),
				data: !!groupRow.dposition ? groupRow?.dposition[optionLang] : '',
			},
			...(groupRow.dstaffCategory
				? [{ title: t('staff_modal.position_category'), data: groupRow?.dstaffCategory[optionLang] }]
				: []),
			{ title: t('staffing_table.count_work_place'), data: groupRow.staffUnitsCount },
		]
	}

	return (
		<>
			<DataGridTable
				hideSubTitle
				titleClass={styles.tableTitleCell}
				title={t('staff_modal.info_workPlace')}
				information={positionInformation()}
			/>
			{groupRow.errors?.length > 0 && (
				<DataGridTable
					hideSubTitle
					titleClass={styles.tableTitleCell}
					dataClass={styles.dataClassCell}
					information={errorInformation()}
				/>
			)}
		</>
	)
}
