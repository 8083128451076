import { Logo as LogoImage } from '@/app/images'
import { Hamburger, User, Close } from '@/app/icons'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@/components/Icon'
import { useTranslation } from 'react-i18next'
import cl from 'classnames'
import styles from './Style.module.scss'
import wrapperStyles from '../../pages/Home/Home.module.scss'
import useEnbekEcoSystemLink from '@/shared/lib/hooks/useEnbekEcoSystemLink'
import { LANGUAGE_ENUM, useLanguage } from '@/shared'
import LogoLang from '@/assets/home/lang.svg'
import { useSelector } from 'react-redux'


const HeaderMobile = () => {
	const { t } = useTranslation()
	const { lang, handleChangeLanguage } = useLanguage()

	const main = document.getElementById('main')
	const app = document.getElementById('app')

	const { user } = useSelector((state) => ({
		user: state.user.user,
	}))

	const { refEnbek, refSkills, refBusiness, refCareer, refMigration, refMansap } = useEnbekEcoSystemLink(lang)

	const [isMenuVisible, setIsMenuVisible] = useState(false)

	const handleBurgerClick = () => {
		setIsMenuVisible(prevState => !prevState)

		main.classList.toggle(wrapperStyles.hidden);
	}

	const menuArray = [
		{
			link: '/about-project',
			label: t('about_project'),
		},
		{
			link: '/for-employers',
			label: t('for_employer'),
		},
		{
			link: '/for-workers',
			label: t('for_worker'),
		},
		{
			link: '/reference-information/1',
			label: t('reference_information'),
		},
		{
			link: refEnbek,
			label: 'Enbek',
			isExternal: true
		},
		{
			link: refSkills,
			label: 'Enbek Skills',
			isExternal: true
		},
		{
			link: refBusiness,
			label: 'Enbek Business',
			isExternal: true
		},
		{
			link: refCareer,
			label: 'Enbek Career',
			isExternal: true
		},
		{
			link: refMigration,
			label: 'Enbek Migration',
			isExternal: true
		},
		{
			link: refMansap,
			label: 'Enbek Mansap Kompasy',
			isExternal: true
		},
	]

	const handleLangClick = (e) => {
		e.preventDefault()

		const currentLanguage = {
			[LANGUAGE_ENUM.kz]: LANGUAGE_ENUM.rus,
			[LANGUAGE_ENUM.rus]: LANGUAGE_ENUM.kz,
		}

		handleChangeLanguage(currentLanguage[lang])
	}

	const langLabel = {
		[LANGUAGE_ENUM.kz]: t('rus'),
		[LANGUAGE_ENUM.rus]: t('rus'),
	}

	let urlAuthByLang = {
		[LANGUAGE_ENUM.kz]: process.env.REACT_APP_LOGIN + 'ru' + process.env.REACT_APP_REDIRECT_ROLE,
		[LANGUAGE_ENUM.rus]: process.env.REACT_APP_LOGIN + 'kk' + process.env.REACT_APP_REDIRECT_ROLE,
	}

	return (
		<>
			<header className={cl(styles.container, styles.headerMobile)}>
				<Icon component={Hamburger} className={cl(styles.headerMobile__icon)} onClick={handleBurgerClick} />

				<Link to={'/'} className={cl(styles.headerMobile__logo)}>
					<img src={LogoImage} alt="enbek" width={146} height={52} />
				</Link>

				{
					!user ? (
						<a href={urlAuthByLang[lang]}>
							<Icon component={User} className={cl(styles.headerMobile__icon)} />
						</a>
					) : (
						<Link to={'/role'}>
							<Icon component={User} className={cl(styles.headerMobile__icon)} />
						</Link>
					)
				}
			</header>

			<aside className={cl(styles.mobileMenu, {
				[styles.mobileMenuVisible]: isMenuVisible,
				[wrapperStyles.isAppVisible]: app
			})} id='mobile-menu'>
				<div className={cl(styles.container, styles.headerMobile)}>
					<Icon component={Close} className={cl(styles.headerMobile__icon)} onClick={handleBurgerClick} />

					<Link to={'/'} className={cl(styles.headerMobile__logo)}>
						<img src={LogoImage} alt="enbek" width={146} height={52} />
					</Link>

					{
						!user ? (
							<a href={urlAuthByLang[lang]}>
								<Icon component={User} className={cl(styles.headerMobile__icon)} />
							</a>
						) : (
							<Link to={'/role'}>
								<Icon component={User} className={cl(styles.headerMobile__icon)} />
							</Link>
						)
					}
				</div>

				<ul className={cl('p-0', styles.mobileMenu__menu)}>
					{menuArray.map((item, index) => (
						<li key={index}>
							{item.isExternal ?
								<a href={item.link}>
									{item.label}
								</a> :
								<Link to={item.link}>
									{item.label}
								</Link>
							}
						</li>
					))}

					<li>
						<a onClick={handleLangClick} className={styles.header__lang}>
							{langLabel[lang]}
							<img src={LogoLang} alt={'globe'} className={'ms-2'} />
						</a>
					</li>
				</ul>
			</aside>
		</>
	)
}

export default HeaderMobile