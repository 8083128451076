import { RedirectUnAuthUser } from '@/processes'
import { ProfileTemplate } from '@/widgets'
import { Tab, TabsList, Tabs, TabPanel } from '@mui/base'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentLanguage } from '@/i18next'
import { useEffect, useMemo, useRef, useState } from 'react'
import styles from './Administration.module.scss'
import { Button } from '@/ui'
import { AdministrationInfoWarning, AdministrationListItem } from '@features/administration'
import { administrationThunks } from '@/entity/administration'
import { handbookThunks } from '@/entity/handbook'
import { FieldArray, Form, Formik } from 'formik'
import { LoadingBlocker } from '@/components/Loader'
import cl from 'classnames'
import { DigitalSign } from '@/utils/digitalSign'
import { message } from 'antd'

const ADMINISTRATION_KEY = {
	request: 'request', //Запрос
	grant: 'grant', //Предоставление доступа
}

const GRANT_KEY = {
	// contractRegistration: 'contractRegistration',
	stRegistration: 'stRegistration',
	stView: 'stView',
}

export const Administration = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const lang = getCurrentLanguage()
	const formRef = useRef(null)

	const digitalSign = new DigitalSign()

	const { serviceBinGrantType, branchesList } = useSelector((state) => ({
		serviceBinGrantType: state.handbook.serviceBinGrantType,
		branchesList: state.administration.branchesList,
	}))

	const tabs = [
		{
			key: ADMINISTRATION_KEY.request,
			label: t('administration.query'),
		},
		{
			key: ADMINISTRATION_KEY.grant,
			label: t('administration.set_access'),
		},
	]

	const [activeTab, setActiveTab] = useState(tabs[0].key)

	const [isSignButtonVisible, setIsSignButtonVisible] = useState(false)

	const getBranchByType = (type) => {
		dispatch(administrationThunks.getBranchesList(type))
	}

	useEffect(() => {
		getBranchByType(activeTab)

		if (!serviceBinGrantType.length) dispatch(handbookThunks.getServiceBinGrantType())
	}, [])

	const handleChangeTab = (e, newValue) => {
		setActiveTab(newValue)

		getBranchByType(newValue)
	}

	const handleSign = () => {
		const filteredArray = formRef.current?.values?.array
			?.filter((item) => item.isEdited)
			?.map((item) => ({ ...item, grants: item.grants?.filter((grant) => grant.isEdited) }))
		const data = filteredArray?.map(({ isEdited, ...item }) => ({
			...item,
			grants: item.grants?.map(({ isEdited, ...grant }) => ({ ...grant })),
		}))

		let body = {
			data,
			onSuccess: async (xml) => {
				const res = await digitalSign.handleSend(xml)

				let signedBody = {
					data: { signedXml: res },

					changedData: data,

					onSuccess: () => {
						setIsSignButtonVisible(false)
						message.success('Успешно!')
					},
				}

				dispatch(administrationThunks.sendSignedXml(signedBody))
			},
		}

		dispatch(administrationThunks.setSign(body))
	}

	const conditionRequest = (item, grant, body, key) => {
		if (item.status === grant.status) {
			let regBody = {
				...body,
				data: {
					...body.data,
					grantTypeCode: key,
				},
			}

			dispatch(administrationThunks.setGrant(regBody))
		}
	}

	const handleSetRequest = (item, grant) => {
		let body = {
			type: grant.status === false ? 'request' : 'revoke',
			data: {
				bin: item.bin,
				grantTypeCode: grant.grantCode,
			},
			onSuccess: () => {
				message.success('Успешно!')
			},
		}

		dispatch(administrationThunks.setGrant(body))

		if (grant.grantCode === GRANT_KEY.stView) {
			let regItem = item?.grants?.find((i) => i.grantCode === GRANT_KEY.stRegistration)

			if (regItem.status === null) {
				conditionRequest(regItem, grant, body, GRANT_KEY.stRegistration)
			}
		} else if (grant.grantCode === GRANT_KEY.stRegistration) {
			let viewItem = item?.grants?.find((i) => i.grantCode === GRANT_KEY.stView)

			if (viewItem.status === false) {
				conditionRequest(viewItem, grant, body, GRANT_KEY.stView)
			}
		}
	}

	return (
		<>
			<RedirectUnAuthUser>
				<ProfileTemplate title={'administration.title'}>
					<Tabs value={activeTab} onChange={handleChangeTab} className={styles.tab}>
						<div className={styles.tab__header}>
							<TabsList>
								{tabs.map((item) => (
									<Tab value={item?.key} key={item?.key} id={item.key} className={styles.tab__item}>
										{item?.label}
									</Tab>
								))}
							</TabsList>

							<div>
								{isSignButtonVisible && activeTab === ADMINISTRATION_KEY.grant && (
									<Button onClick={handleSign}>{t('administration.sign_changes')}</Button>
								)}
							</div>
						</div>
						{tabs.map((item) => (
							<TabPanel key={item.key} value={item.key}>
								<div className={styles.list}>
									{activeTab === ADMINISTRATION_KEY.grant ? (
										isSignButtonVisible && <AdministrationInfoWarning text={t('administration.isSelected_alert')} />
									) : (
										<AdministrationInfoWarning text={t('administration.default_alert')} />
									)}

									<Formik
										innerRef={formRef}
										enableReinitialize={true}
										initialValues={{
											array: branchesList.data?.map((item) => ({
												...item,
												isEdited: false,
												grants: item?.grants?.map((grant) => ({ ...grant, isEdited: false })),
											})),
										}}
									>
										{() => {
											return (
												<Form className={cl('mt-4')}>
													<FieldArray name={'array'}>
														<div>
															{branchesList.data?.map((item, index) => {
																return (
																	<div key={item.bin}>
																		<FieldArray name={`array.${index}.grants`}>
																			<AdministrationListItem
																				outerKey={index}
																				item={item}
																				setIsSignButtonVisible={setIsSignButtonVisible}
																				handleSetRequest={handleSetRequest}
																				type={activeTab === ADMINISTRATION_KEY.request ? 'button' : 'switch'}
																			/>
																		</FieldArray>
																	</div>
																)
															})}
														</div>
													</FieldArray>
												</Form>
											)
										}}
									</Formik>
								</div>
							</TabPanel>
						))}
					</Tabs>
				</ProfileTemplate>
			</RedirectUnAuthUser>

			{branchesList.loading && <LoadingBlocker />}
		</>
	)
}
