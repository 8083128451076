import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import styles from './TradeUnionsTable.module.scss'
import React, { useCallback, useMemo, useState } from 'react'
import { headCells } from './tradeUnionsTable.headings'
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu'
import { useDispatch, useSelector } from 'react-redux'
import { contractActionsSelectors } from '@features/contracts/model'
import { profUnionsThunks } from '@/entity'
import onActionMenuClick from '@features/profUnions/table/onActionMenuClick'
import { colors, status } from '@features/profUnions/table/utils'
import { employerProfileSelectors } from '@features/employerProfile/model/index'

export const TradeUnionsTable = ({ onToggleAction, profUnionsList, isEmployee }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const dispatch = useDispatch()
	const options = useSelector(contractActionsSelectors.options)
	const { unionType } = options
	const [actionMenuItems, setActionMenuItems] = useState([])

	const approveTradeUnion = useCallback(
		(externalId) => dispatch(profUnionsThunks.approveTradeUnion(externalId, false, isEmployee)),
		[dispatch]
	)

	const rejectTradeUnion = useCallback(
		(externalId) => dispatch(profUnionsThunks.rejectTradeUnion(externalId, false, isEmployee)),
		[dispatch]
	)

	const activeCompany = useSelector(employerProfileSelectors.activeCompany)

	const excludeTradeUnion = useCallback(
		(externalId) => dispatch(profUnionsThunks.excludeTradeUnion(externalId, false, activeCompany.bin, isEmployee)),
		[dispatch]
	)

	const onActionMenuChoose = (mode, row) => {
		switch (mode) {
			case 'view':
				dispatch(profUnionsThunks.getUnionTrade(row.externalId))
					.then(() => {
						onToggleAction(mode, true)
					})
				break
			case 'approve':
				{
					approveTradeUnion(row.externalId)
					onToggleAction(mode, true)
				}
				break
			case 'reject':
				{
					rejectTradeUnion(row.externalId)
					onToggleAction(mode, true)
				}
				break
			case 'exclude':
				{
					excludeTradeUnion(row.externalId)
					onToggleAction(mode, true)
				}
				break
			default:
				break

		}
	}

	const fastView = (row) => {
		onActionMenuChoose('view', row)
	}

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	const getTableRow = useCallback(
		(row, index) => {
			return (
				<tr key={index} onClick={() => fastView(row)}>
					{headCells.map((cell) => {
						switch (cell.id) {
							case 'dateCreate':
								return <td key={cell.id}>{row.dateCreate}</td>
							case 'typeCode': {
								const typeObject = unionType.find((unionObject) => unionObject.code === row.typeCode)
								let typeName = '-'
								if (typeObject) {
									typeName = typeObject[lang] || '-'
								}
								return <td key={cell.id}>{typeName}</td>
							}
							case 'bin':
								return <td key={cell.id}>{row.bin || '-'}</td>
							case 'union_name':
								return <td key={cell.id}>{row.unionName || '-'}</td>
							case 'count_manager':
								return <td key={cell.id}>{row?.personCount || 0}</td>
							case 'status':
								let rowStatus = ""
								if (row.status === "APPROVED" && row.statusUnion === "DRAFT") {
									rowStatus = "profUnions.not_approved_prof"
								} else if (row.status === "REJECTED" && row.statusUnion === "APPROVED") {
									rowStatus = "profUnions.rejected_prof_unions"
								} else if (row.status === "APPROVED" && row.statusUnion === "REJECTED") {
									rowStatus = "profUnions.rejected_prof"
								} else if (row.status === "DRAFT" && row.statusUnion === "APPROVED") {
									rowStatus = "profUnions.not_approved_prof_unions"
								} else if (row.status === "APPROVED" && row.statusUnion === "APPROVED") {
									rowStatus = "profUnions.approved"
								} else if (row.status === "EXCLUDED" || row.statusUnion === "EXCLUDED") {
									rowStatus = "profUnions.excluded"
								}
								return (
									<td key={cell.id}>
										<span style={{ color: colors[rowStatus === "profUnions.approved" ? "APPROVED" : "DRAFT"] }}>{rowStatus.length !== 0 ? t(rowStatus) : '-'}</span>
									</td>
								)
							case 'actions':
								return (
									<td key={cell.id} className={styles.actionsWrap} onClick={(e) => onActionMenuClick(row, e, setActionMenuItems, t, isEmployee)}>
										<DropDownMenu
											title={t('select_pls')}
											items={actionMenuItems}
											onItemClick={(mode) => onActionMenuChoose(mode, row)}
										/>
									</td>
								)
							default:
								return null
						}
					})}
				</tr>
			)
		},
		[lang, t, unionType, fastView, colors, actionMenuItems, onActionMenuChoose]
	)

	const tableBody = useMemo(
		() => profUnionsList?.map((row, index) => getTableRow(row, index)),
		[profUnionsList, getTableRow]
	)

	return (
		<table className={styles.table}>
			<thead>
				<tr>{tableHeader}</tr>
			</thead>
			<tbody>{tableBody}</tbody>
		</table>
	)
}
