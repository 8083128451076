import React, { useMemo, useRef, useState, useCallback } from 'react'
import { Arrow } from '@app/icons'
import { useToggle, useClickOutside, useDebounce } from '@/shared'
import { Options } from '@/ui/atoms'
import { InputWithIcon } from '@/ui'
import styles from './SelectSearch.module.scss'
import { getCurrentLanguage } from '@/i18next'
import { Close8 } from '@app/images'

export const SelectSearch = ({
	value,
	onChange,
	onCustomChange,
	options = [],
	optionObjectKey = null,
	showItems = 5,
	placeholder,
	onBlur,
	className = '',
	hideNotFoundError = false,
	...props
}) => {
	const [isOpenedOptions, toggleOpenedOptions] = useToggle(false)
	const [searchValue, setSearchValue] = useState('')

	// Используем useDebounce для значения с задержкой
	const debouncedValue = useDebounce(searchValue, 1000) // 1 секунда
	const fieldRef = useRef()
	const currentLang = getCurrentLanguage()

	const onSelect = useCallback(
		(id) => {
			if (onCustomChange) onCustomChange(id)
			onChange(id)
			setSearchValue('')
			toggleOpenedOptions()
		},
		[onChange, toggleOpenedOptions]
	)

	const onClickOutside = useCallback(() => {
		toggleOpenedOptions(false)
	}, [toggleOpenedOptions])

	const handleValueType = useCallback(
		(inputValue) => {
			setSearchValue(inputValue) // Устанавливаем новое значение
			if (onCustomChange) onCustomChange(null) // Сбрасываем onCustomChange
			onChange(null) // Сбрасываем onChange
			if (!isOpenedOptions) {
				toggleOpenedOptions(true)
			}
		},
		[onChange, toggleOpenedOptions, isOpenedOptions]
	)

	const selectedValue = useMemo(() => {
		const option = options.find(({ code }) => code === value)
		const optionLang = currentLang === 'rus' ? 'nameRu' : 'nameKz'
		return searchValue || option?.[currentLang] || option?.[optionLang] || value
	}, [currentLang, options, searchValue, value])

	const filteredOptions = useMemo(() => {
		if (!debouncedValue) return options // Если значение с задержкой пустое, возвращаем все опции

		const searchLower = debouncedValue.toLowerCase() // Приводим запрос к нижнему регистру
		const searchWords = searchLower.trim().split(/\s+/) // Массив слов из запроса
		const prefix = searchWords[0]?.substring(0, 3) // Первые три символа первого слова

		// Группы для сортировки
		const multiWordMatch = [] // Элементы, содержащие все слова запроса, если их > 2
		const wordMatch = [] // Совпадения по первому слову
		const exactMatch = [] // Полностью совпадающие элементы
		const startsWithMatch = [] // Элементы, начинающиеся с `prefix`
		const includesMatch = [] // Элементы, содержащие `prefix`

		options.forEach((option) => {
			const text = optionObjectKey
				? option[optionObjectKey]
				: currentLang === 'rus'
				? option.nameRu || option.rus
				: option.kz || option.nameKz

			if (text) {
				const textLower = text.toLowerCase()

				// Проверяем наличие всех слов поискового запроса
				const containsAllWords = searchWords.every((word) => textLower.includes(word))

				if (searchWords.length > 2 && containsAllWords) {
					multiWordMatch.push(option) // Если все слова запроса содержатся в тексте
				} else if (textLower.startsWith(searchWords[0])) {
					wordMatch.push(option) // Совпадение по первому слову
				} else if (textLower === searchLower) {
					exactMatch.push(option) // Полное совпадение
				} else if (textLower.startsWith(prefix)) {
					startsWithMatch.push(option) // Начало с первых 3 символов
				} else if (textLower.includes(prefix)) {
					includesMatch.push(option) // Содержит `prefix`
				}
			}
		})

		// Приоритетная сортировка
		return [...multiWordMatch, ...wordMatch, ...exactMatch, ...startsWithMatch, ...includesMatch]
	}, [currentLang, options, debouncedValue, optionObjectKey]) // Используем debouncedValue в зависимостях

	useClickOutside(fieldRef, isOpenedOptions, onClickOutside)

	const selectedOption = useMemo(() => options.find(({ code }) => value === code), [value, options])

	const onClear = () => {
		onChange('')
		setSearchValue('')
	}

	return (
		<div className={`${styles.wrapper} ${className}`} ref={fieldRef}>
			<InputWithIcon
				onChange={handleValueType}
				placeholder={placeholder}
				onClick={() => toggleOpenedOptions()}
				value={selectedValue}
				iconSide={'right'}
				icon={
					!selectedOption ? (
						<Arrow
							styles={{ fill: '#AAB8D1', transform: `rotate(${isOpenedOptions ? '90deg' : '-90deg'})`, width: '10px' }}
						/>
					) : (
						<img onClick={onClear} src={Close8} className={styles.closeIcon} alt="close_icon" />
					)
				}
				autoComplete="off"
				{...props}
			/>
			{isOpenedOptions && (
				<Options
					optionObjectKey={optionObjectKey}
					hideNotFoundError={hideNotFoundError}
					onSelect={onSelect}
					options={filteredOptions}
					showItems={showItems}
					onBlur={onBlur}
				/>
			)}
		</div>
	)
}
