import React from 'react'
import { useTranslation } from 'react-i18next'
import { DataGridTable } from '@/ui'
import styles from './PositionInformation.module.scss'
import { getCurrentLanguage } from '@/i18next'
import { formatNumber } from '@/utils'

export const PositionInformation = ({ position }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const optionLang = lang === 'rus' ? 'nameRu' : 'nameKz'

	const isFreelance = position.freelance

	let errors = position.errors
	const errorInformation = () => {
		const errorDescriptions = errors.map((error) => error.code + ': ' + error[optionLang])
		return [
			{
				title: t('staff.errors'),
				data: errorDescriptions.join(',\n'),
			},
		]
	}

	const positionInformation = () => {
		return [
			{ title: t('staffing_table.group_code'), data: position.codeGroup },
			{ title: t('staffing_table.workplace_code_label'), data: position.code },
			{ title: t('staff_modal.position_kz'), data: position.establishedPost.nameKz },
			{ title: t('staff_modal.position_ru'), data: position.establishedPost.nameRu },
			{ title: t('contract.info.nkz_position'), data: position.dposition?.[optionLang] || '' },
			...(position.dstaffCategory
				? [{ title: t('staff_modal.position_category'), data: position.dstaffCategory?.[optionLang] }]
				: []),
			{ title: t('staff_modal.freelancer'), data: isFreelance ? t('yes') : t('no') },
			{ title: t('workplace.staff_unit'), data: position.staffUnitsCount },
			{ title: t('staffing_table.salary'), data: formatNumber(position.grossSalary || '') },
			{
				title: t('staffing_table.supplement_surcharge'),
				data: formatNumber(position.salarySupplement || ''),
			},
		]
	}

	return (
		<>
			<DataGridTable
				hideSubTitle
				titleClass={styles.tableTitleCell}
				title={t('staff_modal.info_position')}
				information={positionInformation()}
			/>
			{position.errors?.length > 0 && (
				<DataGridTable
					hideSubTitle
					titleClass={styles.tableTitleCell}
					dataClass={styles.dataClassCell}
					information={errorInformation()}
				/>
			)}
		</>
	)
}
