import * as types from './types'

const data = {
	loading: false,
	success: false,
	failed: false,
	data: null,
}

const initialState = {
	data: data,
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.GET_DATA:
			return { ...state, data: { ...data, loading: true } }

		case types.GET_DATA_DONE:
			return { ...state, data: { ...data, data: payload, success: true } }

		case types.GET_DATA_FAIL:
			return { ...state, data: { ...data, failed: true } }


		default:
			return state
	}
}
