import { Button } from '@/ui'
import { EBook } from '@app/images'
import { useToggle } from '@/shared'
import { useTranslation } from 'react-i18next'
import { Form } from '@features/eCollectiveContracts/registration/Form/Form'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { eColContractsActions, eColContractsSelectors, eColContractsThunks } from '@/entity'
import { initialEColContract } from '@/entity/eCollectiveContracts/model/reducer'
import { contractActionsSelectors } from '@features/contracts/model'
import { Project } from '@features/eCollectiveContracts/registration/Form/Project'
import { InfoDevelopment } from '@/components/infoDevelopment'
import { NavLink } from 'react-router-dom'
import styles from './Registration.module.scss'
import { getCurrentLanguage } from '@/i18next'

export const Registration = ({ fetchData,handleEdit, isTradeUnionNotEmpty, tradeUnion, isTradeUnion, isEmployee }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [isCreate, toggleCreate] = useToggle(false)
	const isLoadingContract = useSelector(eColContractsSelectors.isLoadingContract)
	const isSuccessContract = useSelector(eColContractsSelectors.isSuccessContract)
	
	const options = useSelector(contractActionsSelectors.options)
	const [openEForm, setOpenEForm] = useState(false)
	const [colContractData, setColContractData] = useState(null)
	const [tempTransformedColContract, setTempTransformedColContract] = useState(null)
	const lang = getCurrentLanguage()
	const onClear = () => {
		dispatch(eColContractsActions.setEColContractSuccess(false))
	}
	const [isAttachProjectFile, setIsAttachProjectFile] = useState(false)
	const formRef = useRef(null)
	const validateOnFindWorker = async (form) => {
		const { iin } = await form.setFieldTouched(formFieldNames.iin)
		if (!iin) {
			const { iin } = form.values
			onFindWorker(iin)
		}
	}

	const eColContractProjectFile = useMemo(() => {
		if (formRef?.current && formRef?.current?.values?.files && formRef?.current?.values?.files.length !== 0) {
			return formRef?.current?.values.files?.[0]
		}
		return {}
	}, [formRef?.current, formRef?.current?.values?.files])

	const openECollectiveContractFile = (data) => {
		const form = formRef.current
		if (form) {
			form?.setFieldValue("language", data.language)
			form?.setFieldValue("files", [data.file])
		}
		setIsAttachProjectFile(false)
		setOpenEForm(true)
	}

	const Close = () => {
		toggleCreate(false)
	}

	const onCancel = () => {
		setOpenEForm(false)
	}

	let eColContract
	if (isTradeUnionNotEmpty && isTradeUnion) {
		let localUnion = tradeUnion.typeCode === 'local'
		eColContract = {
			...initialEColContract,
			bin: tradeUnion?.employers?.length > 1 ? "" : tradeUnion.employers[0].employerBin,
			enterpriseName: tradeUnion?.employers?.length > 1 ? "" : tradeUnion.employers[0].employerName,
			enterpriseNameKaz: tradeUnion?.employers?.length > 1 ? "" : tradeUnion.employers[0].employerName,
			unions: [
				{
					...initialEColContract.unions[0],
					unionTypeCode: localUnion ? '2' : '1',
					unionBin: localUnion ? tradeUnion.bin : '',
					unionName: tradeUnion.unionName,
					unionNameKaz: tradeUnion.unionName,
					representativeDtos: [
						{
							...initialEColContract.unions[0].representativeDtos[0],
							representativeFIO: tradeUnion.chiefFio,
							representativeIin: tradeUnion.chiefIin,
						},
					],
				},
			],
			externalId: null,
			fileProtocolDisagreement: {},
			files: []
		}
	}

	const getTransformedColContract = (data) => {
		let transformedColContract = {
			clang: data.language,
			contractDTO: {
				bin: data.bin,
				collectiveContractDetails: {
					fundingAmount: data.fundingAmount,
					relaxTime: data.relaxTime,
					securityFundingVolume: data.securityFundingVolume,
					workTime: data.workTime,
					dcolContractEnterpriseTypeCode: data.dcolContractEnterpriseTypeCode,
					dcolContractOwnershipTypeCode: data.dcolContractOwnershipTypeCode,
				},
				collectiveContractNumber: data.collectiveContractNumber,
				companyName: data.enterpriseName,
				companyNameKaz: data.enterpriseNameKaz,
				dateFrom: data.dateFrom,
				dateTo: data.dateTo,
				dcontractDateCode: data.dcontractDateCode,
				electronic: true,
				unions: data.unions,
				extraGuarantee: data.extraGuarantee,
				files: data?.files,
				positions: data.positions,
				industryCodes: data.industryCodes,
				fileProtocolDisagreement: data.fileProtocolDisagreement[0],
			},
			externalId: data.externalId,
			fileId: data?.files?.[0]?.id
		}
		// Добавляем overtimePay только если overtimePayIs равно true
		if (data.overtimePayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.overtimePay = data.overtimePay
		}
		if (data.holidayPayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.holidayPay = data.holidayPay
		}
		if (data.nightTimePayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.nightTimePay = data.nightTimePay
		}
		if (data.combinationPositionPayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.combinationPositionPay = data.combinationPositionPay
		}
		if (data.termCompensationPayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.termCompensationPay = data.termCompensationPay
		}
		if (data.wayCompensationPayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.wayCompensationPay = data.wayCompensationPay
		}
		if (data.wageIndexProcedureIs) {
			transformedColContract.contractDTO.collectiveContractDetails.wageIndexProcedure = data.wageIndexProcedure
		}
		return transformedColContract
	}

	const onSubmit = (data) => {
		const transformedColContract = getTransformedColContract(data)
		setColContractData(transformedColContract)
		// setIsAttachProjectFile(true)
		setOpenEForm(true)
	}

	const InfoLink =
		lang === 'rus' ? (
			<p>
				{t('col_contract.info_for_create')}
				<NavLink to="/prof" className={styles.item} activeClassName={styles.navLinkActive}>
					{t('profUnions.generalInformation')}
				</NavLink>
			</p>
		) : (
			<p>
				{t('col_contract.info_for_create')}
				<NavLink to="/prof" className={styles.item} activeClassName={styles.navLinkActive}>
					{t('profUnions.generalInformation')}
				</NavLink>
				{t('col_contract.info_for_create_2')}
			</p>
		)

	useEffect(() => {
		if (isSuccessContract) {
			fetchData()
			setOpenEForm(false)
			Close()
			setTimeout(() => onClear())
		}
	}, [isSuccessContract])

	const handleSendToEmployer = (isSendToEmployer) => {
		const combinedData = {
			...colContractData,
		}
		dispatch(eColContractsThunks.save(combinedData, isSendToEmployer, isEmployee))
	}

	return (
		<div>
			{isTradeUnion ? (
				<Button icon={EBook} iconSize={'big'} disabled={!isTradeUnionNotEmpty} onClick={toggleCreate}>
					{t('contracts_tab.collective_agreement_conclude')}
				</Button>
			) : null}
			{!isTradeUnionNotEmpty ? <InfoDevelopment big width48={true} child={InfoLink} /> : null}
			{isCreate && isTradeUnionNotEmpty ? (
				<Form formRef={formRef} tradeUnion={tradeUnion} initialValues={eColContract} isEmployee={isEmployee} onCancel={Close} options={options} onSubmit={onSubmit}></Form>
			) : null}
			{!isLoadingContract && openEForm ? (
				<Project
					onCancel={onCancel}
					attachProjectFileData={eColContractProjectFile}
					handleSendToEmployer={handleSendToEmployer}
					isTradeUnion={isTradeUnion}
					handleEdit={handleEdit}
				></Project>
			) : null}
		</div>
	)
}
