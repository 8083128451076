import { useTranslation } from 'react-i18next'
import CloseButton from '@/components/CloseButton/CloseButton'
import { Button as DialogButton } from '@/ui'
import { Box, Button, Dialog, DialogContent, DialogTitle } from '@mui/material'
import cn from 'classnames'
import React, { useState } from 'react'
import styles from './ConfirmDialog.module.scss'

export const useConfirmDialog = () => {
    const { t } = useTranslation()
    const [isOpen, setIsOpen] = useState(false)
    const [confirmationData, setConfirmationData] = useState({
        onConfirm: null,
        args: [],
        title: "",
        buttonText: ""
    })

    const onClose = () => setIsOpen(false)
    const onOpen = () => setIsOpen(true)


    const showConfirm = ({
        onConfirm, args, title, buttonText
    }) => {
        setConfirmationData({ onConfirm, args, title, buttonText })
        onOpen()
    }

    const handleConfirm = () => {
        if (confirmationData.onConfirm) {
            confirmationData.onConfirm(...confirmationData.args)
        }
        onClose()
    }

    const ConfirmDialog = (
        <Dialog classes={{ paper: styles.sign }} onClose={onClose} open={isOpen}>
            <Box>{onClose ? <CloseButton onClose={onClose}></CloseButton> : null}</Box>
            <DialogTitle classes={{ root: styles.sign__title }}>{confirmationData.title}</DialogTitle>
            <DialogContent sx={{ paddingBottom: '40px' }}>
                <div className={styles.dialog__buttons__wrapper}>
                    <div className={styles.flex_button}>
                        <div className={styles.margin_button}>
                            <DialogButton type="button" onClick={handleConfirm}>
                                {confirmationData.buttonText}
                            </DialogButton>
                        </div>
                    </div>
                    <Button
                        type="button"
                        variant="text"
                        classes={{ root: cn(styles.button, styles.reset__button) }}
                        onClick={onClose}
                    >
                        {t('cancel')}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )

    return {
        ConfirmDialog,
        showConfirm
    }
}
