import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalTemplate, Title } from '@/ui'
import styles from './PositionForm.module.scss'
import { Formik } from 'formik'
import { WorkPlaceForm } from './WorkPlaceForm'
import { useDispatch, useSelector } from 'react-redux'
import { staffSelectors, staffThunks } from '@/entity/staffingProject'
import { ValidationSchema } from './validationSchema'
import { contractActionsSelectors } from '@features/contracts/model'
import { LoadingBlocker } from '@/components/Loader'
import { filterWorkPlaceByExternalId } from './utils'
import { staffingSelectors, staffingThunks } from '@/entity'
import { DigitalSign } from '@/utils/digitalSign'
import { message } from 'antd'

export const WorkPlaceModalForm = ({
	onClose,
	activeStructId,
	staff,
	positionRowSelectMenu,
	setPositionRowSelectMenu,
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isSuccess = useSelector(staffSelectors.isSuccess)
	const isLoading = useSelector(staffingSelectors.isLoading)
	const { positions, positionsByKnz, laborMobilityCenterCategory } = useSelector(contractActionsSelectors.options)

	const digitalSign = new DigitalSign()

	useEffect(() => {
		if (isSuccess) {
			let workPlace = filterWorkPlaceByExternalId(staff.childs[0], editPosition.externalId)
			setPositionRowSelectMenu(workPlace)
			onClose()
		}
	}, [isSuccess])

	let establishedPostNames = { ...positionRowSelectMenu.establishedPost }
	let otherProps = { ...positionRowSelectMenu }

	// добавляем поле isDicPosition в объект editPosition
	let editPosition = {
		...(establishedPostNames || {}),
		...(otherProps || {}),
		isDicPosition: !establishedPostNames.code ? '2' : '3',
		position: establishedPostNames.code ? establishedPostNames.code : null,
		staffCategory: positionRowSelectMenu.dstaffCategory?.code,
		positionCode: positionRowSelectMenu.dposition?.code,
	}

	const onSubmit = (data) => {
		const workPlaceData = {
			orgExternalId: staff.externalId,
			positionGroupExternalId: positionRowSelectMenu.groupId,
			row: {
				factSalary: data.factSalary,
				freelance: data.freelance,
				grossSalary: data.grossSalary,
				salarySupplement: data.salarySupplement,
				staffFreeCount: data.staffFreeCount,
				staffUnitsCount: data.staffUnitsCount,
				code: data.code,
				externalId: editPosition.externalId,
			},
		}
		if (staff.actual) {
			let newWorkPlace = {
				externalId: editPosition.externalId,
				factSalary: data.factSalary,
				freelance: data.freelance,
				grossSalary: data.grossSalary,
				posGroupExternalId: positionRowSelectMenu.groupId,
				salarySupplement: data.salarySupplement,
				staffUnitsCount: data.staffUnitsCount,
			}
			const type = 'workPlace'
			let body = {
				newWorkPlace,
				type,
				activeStructId,
				onSuccess: async (xml) => {
					const res = await digitalSign.handleSend(xml)
					let signedBody = {
						data: { data: res },

						onSuccess: () => {
							message.success('Рабочее место успешно изменено!')
							onClose()
						},
						type,
						activeStructId,
						positionGroupExternalId: positionRowSelectMenu.groupId,
					}
					dispatch(staffingThunks.sendSignActualWorkPlace(signedBody))
				},
			}

			dispatch(staffingThunks.setActualWorkPlace(body))
		} else {
			dispatch(staffThunks.editWorkPlace(workPlaceData, activeStructId))
		}
	}

	return (
		<>
			<ModalTemplate onClose={onClose} priority={2}>
				<div className={`scroll ${styles.wrapper}`}>
					<div className={styles.mt10}>
						<Title color="black">{t('staff_modal.edit_position')}</Title>
						<Formik
							validationSchema={ValidationSchema}
							initialValues={editPosition}
							onSubmit={onSubmit}
							enableReinitialize
						>
							{positions?.length > 0 && positionsByKnz.length > 0 && laborMobilityCenterCategory.length > 0 ? (
								<WorkPlaceForm
									onClose={onClose}
									positions={positions}
									positionsByKnz={positionsByKnz}
									laborMobilityCenterCategory={laborMobilityCenterCategory}
								></WorkPlaceForm>
							) : (
								<LoadingBlocker localStyles={{ width: '65vw', height: '50vw' }} />
							)}
						</Formik>
					</div>
				</div>
			</ModalTemplate>

			{isLoading ? <LoadingBlocker /> : null}
		</>
	)
}
