import { request } from '@/shared/api/request'

export const editStructure = ({ data, params }) => {
	return request({
		method: 'POST',
		url: 'staff/structure/edit',
		data,
		params,
	})
}
