import { StyledTable } from './Table.styled'
import { Arrow } from '@app/icons'
import cl from 'classnames'
import styles from '@pages/RequirementLaborResources/RequirementLaborResources.module.scss'
import { useState } from 'react'

export const Table = (props) => {
	const [expanded, setExpanded] = useState([])

	return (
		<StyledTable
			{...props}
			expandIcon={({ expandable, expanded, onExpand, record }) => {
				return expandable && (
					<Arrow className={cl(styles.icon, { [styles.icon__expanded]: expanded })}
								 onClick={e => onExpand(record, e)} />
				)
			}}
			expandable={{
				expandedRowKeys: expanded,
			}}
			onRow={(record, rowIndex) => {
				return {
					onClick: (event) => {
						if (record.children) {
							let find = expanded.includes(record.key)
								? expanded.filter((key) => key !== record.key)
								: [...expanded, record.key]

							setExpanded(find)
						}
					},
				}
			}}
		/>
	)
}