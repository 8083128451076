import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, ModalTemplate, Title } from '@/ui'
import styles from './View.module.scss'
import { LoadingBlocker } from '@/components/Loader'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { contractActionsSelectors } from '@features/contracts/model'
import { getCurrentLanguage } from '@/i18next'
import { eColContractsActions, eColContractsSelectors, eColContractsThunks } from '@/entity'
import { Project } from '@features/eCollectiveContracts/registration/Form/Project'
import { TitleContractInfo } from '@features/eCollectiveContracts/registration/component'
import { isEmpty } from 'lodash'
import { ViewConfirmDocument } from '@/components/ViewDocuments/ViewConfirmDocument'
import { Form } from '@features/eCollectiveContracts/registration/Form/Form'

export const View = ({ contract, onClose, isEmployee,isTradeUnionNotEmpty, fetchData, isTradeUnion }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const dispatch = useDispatch()
	const currentEcd = useSelector(eColContractsSelectors.currentEcd)
	const isLoadingContract = useSelector(eColContractsSelectors.isLoadingContract)
	const isSendSuccessContract = useSelector(eColContractsSelectors.isSendSuccessContract)
	const lang = getCurrentLanguage()
	const {
		workTimeCollectiveContract,
		relaxTimeCollectiveContract,
		unionTypeCollectiveContract,
		extraGuaranteesCollectiveContract,
	} = useSelector(contractActionsSelectors.options)
	const options = useSelector(contractActionsSelectors.options)
	const formRef = useRef(null)
	const [openEForm, setOpenEForm] = useState(false)
	const [isEdit, setIsEdit] = useState(false)
	useEffect(() => {
		if (currentEcd) {
			setOpenEForm(true)
		}
	}, [currentEcd])

	useEffect(() => {
		if (isSendSuccessContract) {
			fetchData()
			setOpenEForm(false)
			setTimeout(() => onClose())
		}
	}, [isSendSuccessContract])

	const badge = (items = [], ids = []) =>
		items
			.filter((item) => ids.includes(item.code.toString()))
			.map((item, index) => (
				<div key={index} className={styles.badge}>
					<div>{item[language]}</div>
				</div>
			))
	const showUnion = (code) => {
		const union = unionTypeCollectiveContract.find((item) => item.code === code)
		return union ? union[lang] : null
	}
	const guaranteeName = (code) => {
		const guarantee = extraGuaranteesCollectiveContract.find((item) => item.code === code)
		return guarantee ? guarantee[lang] : null
	}

	const openEcd = (externalId) => {
		dispatch(eColContractsThunks.getEcd(externalId))
	}

	const onCancel = () => {
		dispatch(eColContractsActions.resetCurrentEcd())
		setOpenEForm(false)
	}

	const handleSendToEmployer = (isSendToEmployer) => {
		if (isSendToEmployer) {
			dispatch(eColContractsThunks.sendTo(isEmployee, contract.externalId))
		} else {
			dispatch(eColContractsThunks.save(contract, isSendToEmployer, isEmployee))
		}
	}

	const documentState =
		contract?.state?.code === '1'
			? {
				code: '1',
				nameRu: 'Действующий',
				nameKz: 'Қолданыстағы',
			}
			: {
				code: '5',
				nameRu: 'Проект ЭКД',
				nameKz: 'ЭҰШ жобасы',
			}

	const { ccEnterpriseType, ccOwnershipType, industryAgreement, contractTerms } = useSelector(contractActionsSelectors.options)
	const { positionsByKnz, workingCondition } = useSelector((state) => ({
		positionsByKnz: state.handbook.positionsByKnz,
		workingCondition: state.handbook.workCondition,
	}))

	const getValue = (data, val) => {
		if (isEmpty(data)) return val
		return data?.find(x => x.code === val)?.[lang] ?? val
	}

	const getValues = (data, values) => {
		if (isEmpty(data)) return val
		return values.map((el, i) => `${getValue(data, el)} ${values.length - 1 === i ? "" : ","}`)
	}

	const informationTariffRatesData = useMemo(() => {
		if (contract && contract?.positions && contract?.positions?.length !== 0) {
			let data = []
			contract.positions.forEach(el => {
				el.gradeList.forEach((item, index) => {
					data.push({
						positionCode: getValue(positionsByKnz, el.positionCode),
						workConditionsCode: getValue(workingCondition, el.workConditionsCode),
						...item,
						index: index + 1
					})
				})
			})
			return data
		} else {
			return []
		}
	}, [contract, lang, workingCondition, positionsByKnz])

	const getTransformedColContract = (data) => {
		let transformedColContract = {
			clang: data.language,
			contractDTO: {
				bin: data.bin,
				collectiveContractDetails: {
					fundingAmount: data.fundingAmount,
					relaxTime: data.relaxTime,
					securityFundingVolume: data.securityFundingVolume,
					workTime: data.workTime,
					dcolContractEnterpriseTypeCode: data.dcolContractEnterpriseTypeCode,
					dcolContractOwnershipTypeCode: data.dcolContractOwnershipTypeCode,
				},
				collectiveContractNumber: data.collectiveContractNumber,
				companyName: data.enterpriseName,
				companyNameKaz: data.enterpriseNameKaz,
				dateFrom: data.dateFrom,
				dateTo: data.dateTo,
				dcontractDateCode: data.dcontractDateCode,
				electronic: true,
				unions: data.unions,
				extraGuarantee: data.extraGuarantee,
				files: data?.files,
				positions: data.positions,
				industryCodes: data.industryCodes,
				fileProtocolDisagreement: data.fileProtocolDisagreement[0],
			},
			externalId: data.externalId,
			fileId: data?.files?.[0]?.id
		}
		// Добавляем overtimePay только если overtimePayIs равно true
		if (data.overtimePayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.overtimePay = data.overtimePay
		}
		if (data.holidayPayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.holidayPay = data.holidayPay
		}
		if (data.nightTimePayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.nightTimePay = data.nightTimePay
		}
		if (data.combinationPositionPayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.combinationPositionPay = data.combinationPositionPay
		}
		if (data.termCompensationPayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.termCompensationPay = data.termCompensationPay
		}
		if (data.wayCompensationPayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.wayCompensationPay = data.wayCompensationPay
		}
		if (data.wageIndexProcedureIs) {
			transformedColContract.contractDTO.collectiveContractDetails.wageIndexProcedure = data.wageIndexProcedure
		}
		return transformedColContract
	}


	const handleEdit = () => {
		setIsEdit(!isEdit)
	}

	const onSubmit = (data) => {
		const transformedColContract = getTransformedColContract(data)
		dispatch(eColContractsThunks.save(transformedColContract, false, isEmployee, true))
		handleEdit()
	}

	const eColContract = useMemo(() => {
		let newData = {...contract}
		if(contract?.collectiveContractDetails) {
			for(let key in contract?.collectiveContractDetails) {
				newData[key] = contract?.collectiveContractDetails[key]
			}
		}
		newData.enterpriseName = contract?.companyName ?? ""
		newData.enterpriseNameKaz = contract?.companyNameKaz ?? ""
		return newData
	}, [contract])


	return (
		<div>
			<ModalTemplate onClose={onClose}>
				{!isLoadingContract && openEForm && !!currentEcd ? (
					<Project
						contract={contract}
						documentState={documentState}
						currentEcd={currentEcd}
						onCancel={onCancel}
						handleSendToEmployer={handleSendToEmployer}
						isTradeUnion={isTradeUnion}
						onClose={onClose}
						handleEdit={handleEdit}
						isEmployee={isEmployee}
					></Project>
				) : null}
				<div className={`scroll ${styles.wrapper}`}>
					<TitleContractInfo contract={contract} documentState={documentState} t={t} />
					<Button onClick={() => openEcd(contract.externalId)}>{t('open_ecd')}</Button>
					<div className={styles.informationSections}>
						<section>
							<Title>{t('parties_requisites')}</Title>
							<div className={styles.container}>
								<div className="flex-1">
									<p className={styles.subTitle}>{t('employer')}:</p>
									<div className={styles.tableContainer}>
										<div className={styles.row}>
											<div className={styles.boldCell}>{t('bin')}:</div>
											<div className={styles.cell6}>{contract.bin}</div>
										</div>
										<div className={styles.row}>
											<div className={styles.boldCell}>{t('name')}:</div>
											<div className={styles.cell6}>{contract.companyName}</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						{contract.unions.map((union, unionIndex) => (
							<div key={unionIndex}>
								{(union.unionBin || union.unionName) && (
									<section>
										<div className={styles.container}>
											<div className="flex-1">
												{unionIndex === 0 && <p className={styles.subTitle}>{t('col_contract.trade_union_data')}</p>}
												<p className={styles.subTitle}>
													{t('col_contract.type_union_data')}: {showUnion(union.unionTypeCode)}
												</p>
												<div className={styles.tableContainer}>
													{union.unionBin && (
														<div className={styles.row}>
															<div className={styles.boldCell}>{t('bin')}:</div>
															<div className={styles.cell6}>{union.unionBin}</div>
														</div>
													)}
													{union.unionName && (
														<div className={styles.row}>
															<div className={styles.boldCell}>{t('name')}:</div>
															<div className={styles.cell6}>
																{lang === 'rus' ? union.unionName : union.unionNameKaz}
															</div>
														</div>
													)}
													{contract?.collectiveContractDetails?.dcolContractEnterpriseTypeCode && (
														<div className={styles.row}>
															<div className={styles.boldCell}>{t('col_contract.typeEnterprise')}:</div>
															<div className={styles.cell6}>
																{getValue(ccEnterpriseType, contract?.collectiveContractDetails?.dcolContractEnterpriseTypeCode)}
															</div>
														</div>
													)}
													{contract?.collectiveContractDetails?.dcolContractOwnershipTypeCode && (
														<div className={styles.row}>
															<div className={styles.boldCell}>{t('col_contract.typeOwnership')}:</div>
															<div className={styles.cell6}>
																{getValue(ccOwnershipType, contract?.collectiveContractDetails?.dcolContractOwnershipTypeCode)}
															</div>
														</div>
													)}
													{contract?.industryCodes && (
														<div className={styles.row}>
															<div className={styles.boldCell}>{t('profUnions.industry')}:</div>
															<div className={styles.cell6}>
																{getValues(industryAgreement, contract?.industryCodes)}
															</div>
														</div>
													)}
												</div>
											</div>
										</div>
									</section>
								)}
								{union.representativeDtos.map((representative, repIndex) => (
									<section key={repIndex}>
										<div className={styles.container}>
											<div className="flex-1">
												{repIndex === 0 && (
													<>
														{!union.unionBin && !union.unionName && (
															<p className={styles.subTitle}>
																{t('col_contract.type_union_data')}: {showUnion(union.unionTypeCode)}
															</p>
														)}
														<p className={styles.subTitle}>{t('col_contract.representativeTradeUnion')}</p>
													</>
												)}
												<div className={styles.tableContainer}>
													<div className={styles.row}>
														<div className={styles.boldCell}>{t('iin')}:</div>
														<div className={styles.cell6}>{representative?.representativeIin || '-'}</div>
													</div>
													<div className={styles.row}>
														<div className={styles.boldCell}>{t('fio')}:</div>
														<div className={styles.cell6}>{representative.representativeFIO}</div>
													</div>
												</div>
											</div>
										</div>
									</section>
								))}
							</div>
						))}
						<section>
							<Title>{t('col_contract.info')}</Title>
							<div className={styles.tableContainer}>
								<div className={styles.row}>
									<div className={styles.boldCell}>{t('col_contract.number')}:</div>
									<div className={styles.cell}>{contract.collectiveContractNumber}</div>
								</div>
								<div className={styles.row}>
									<div className={styles.boldCell}>{t('col_contract.date_end_col_contract')}:</div>
									<div className={styles.cell}>{getValue(contractTerms, contract.dcontractDateCode)}</div>
								</div>
								<div className={styles.row}>
									<div className={styles.boldCell}>{t('col_contract.dateFrom')}:</div>
									<div className={styles.cell}>{contract.dateFrom}</div>
								</div>
								<div className={styles.row}>
									<div className={styles.boldCell}>{t('col_contract.dateTo')}:</div>
									<div className={styles.cell}>{contract.dateTo}</div>
								</div>
							</div>
						</section>
						<section>
							<Title>Протокол разногласий</Title>
							{
								contract && contract?.fileProtocolDisagreement && !isEmpty(contract?.fileProtocolDisagreement) &&
								<ViewConfirmDocument data={[contract?.fileProtocolDisagreement]} local={true}></ViewConfirmDocument>
							}
						</section>
						{contract.collectiveContractDetails && (
							<>
								<section>
									<Title>{t('col_contract.pay_info')}</Title>
									<div className={styles.table}>
										{contract.collectiveContractDetails.overtimePay && (
											<div className={styles.row}>
												<div className={styles.boldCell}>{t('col_contract.pay')}:</div>
												<div className={styles.cell}>{contract.collectiveContractDetails.overtimePay}</div>
											</div>
										)}
										{contract.collectiveContractDetails.holidayPay && (
											<div className={styles.row}>
												<div className={styles.boldCell}>{t('col_contract.holiday_pay')}:</div>
												<div className={styles.cell}>{contract.collectiveContractDetails.holidayPay}</div>
											</div>
										)}
										{contract.collectiveContractDetails.nightTimePay && (
											<div className={styles.row}>
												<div className={styles.boldCell}>{t('col_contract.night_time_pay')}:</div>
												<div className={styles.cell}>{contract.collectiveContractDetails.nightTimePay}</div>
											</div>
										)}
										{contract.collectiveContractDetails.combinationPositionPay && (
											<div className={styles.row}>
												<div className={styles.boldCell}>{t('col_contract.combination_position_pay')}:</div>
												<div className={styles.cell}>{contract.collectiveContractDetails.combinationPositionPay}</div>
											</div>
										)}
										{contract.collectiveContractDetails.termCompensationPay && (
											<div className={styles.row}>
												<div className={styles.boldCell}>{t('col_contract.term_compensation_pay')}:</div>
												<div className={styles.cell}>{contract.collectiveContractDetails.termCompensationPay}</div>
											</div>
										)}
										{contract.collectiveContractDetails.wayCompensationPay && (
											<div className={styles.row}>
												<div className={styles.boldCell}>{t('col_contract.way_compensation_pay')}:</div>
												<div className={styles.cell}>{contract.collectiveContractDetails.wayCompensationPay}</div>
											</div>
										)}
										{contract.collectiveContractDetails.wageIndexProcedure && (
											<div className={styles.row}>
												<div className={styles.boldCell}>{t('col_contract.wage_index_procedure')}:</div>
												<div className={styles.cell}>{contract.collectiveContractDetails.wageIndexProcedure}</div>
											</div>
										)}
									</div>
								</section>
								<section>
									<Title>{t('col_contract.information_tariff_rates')}</Title>
									<div className={`${styles.tableRateWrap} scroll`} >
										<table className={styles.tableRate}>
											<thead>
												<tr>
													<th>{t('personal_affairs.nkz_position')}</th>
													<th>{t('col_contract.rank_by_count', { count: "" })}</th>
													<th>{t('contract.info.working_condition')}</th>
													<th>{t('col_contract.tariff_rate_salary')}</th>
													<th>{t('staffing_table.supplement_surcharge')}</th>
													<th>{t('col_contract.inter_digit_coefficient')}</th>
												</tr>
											</thead>
											<tbody>
												{
													informationTariffRatesData && informationTariffRatesData.length !== 0 &&
													informationTariffRatesData.map((el, i) => (
														<tr key={i}>
															<td>{el.positionCode || '-'}</td>
															<td>{el.index || '-'}</td>
															<td>{el.workConditionsCode || '-'}</td>
															<td>{el.tariffRate || '-'}</td>
															<td>{el.salarySupplement || '-'}</td>
															<td>{el.gradeCoeff || '-'}</td>
														</tr>
													))
												}
											</tbody>
										</table>
									</div>

								</section>
								<section>
									<Title>{t('col_contract.safe_working_protection')}</Title>
									<div className={styles.table}>
										<div className={styles.row}>
											<div className={styles.boldCell}>{t('col_contract.amount_funding')}:</div>
											<div className={styles.cell}>{contract.collectiveContractDetails.securityFundingVolume}</div>
										</div>
										<div className={styles.row}>
											<div className={styles.boldCell}>{t('col_contract.funding_amount')}:</div>
											<div className={styles.cell}>{contract.collectiveContractDetails.fundingAmount}</div>
										</div>
									</div>
								</section>
								<section>
									<Title>{t('col_contract.duration_working_hours_and_rest_time_holidays')}</Title>
									<div className={styles.tableContainer}>
										<div className={styles.row}>
											<div className={styles.boldCell}>{t('col_contract.working_time')}:</div>
										</div>
										<div>{badge(workTimeCollectiveContract, contract.collectiveContractDetails.workTime)}</div>
									</div>
									<div className={styles.tableContainer}>
										<div className={styles.row}>
											<div className={styles.boldCell}>{t('col_contract.relax_time')}:</div>
										</div>
										<div>{badge(relaxTimeCollectiveContract, contract.collectiveContractDetails.relaxTime)}</div>
									</div>
								</section>

								<section>
									<Title>{t('col_contract.additional_information')}</Title>
									<div className={styles.tableContainer}>
										{contract.extraGuarantee.map((guarantee) => (
											<div key={guarantee.code} className={styles.row}>
												<div className={styles.boldCell}>{guaranteeName(guarantee.code)}:</div>
												<div className={styles.cell}>{guarantee.value}</div>
											</div>
										))}
									</div>
								</section>
							</>
						)}
					</div>
					{contract ? (
						<div className={styles.buttonEnd}>
							<Button onClick={onClose}>{t('close')}</Button>
						</div>
					) : (
						<LoadingBlocker localStyles={{ width: '65vw', height: '50vw' }} />
					)}
				</div>
				{isEdit && isTradeUnionNotEmpty ? (
					<Form isEdit={true} formRef={formRef} initialValues={eColContract} isEmployee={isEmployee} onCancel={handleEdit} options={options} onSubmit={onSubmit}></Form>
				) : null}
			</ModalTemplate>
		</div>
	)
}
