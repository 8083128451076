export const GET_AGREEMENT_DATA = 'referenceInformation/GET_AGREEMENT_DATA'
export const GET_AGREEMENT_DATA_DONE = 'referenceInformation/GET_AGREEMENT_DATA_DONE'
export const GET_AGREEMENT_DATA_FAIL = 'referenceInformation/GET_AGREEMENT_DATA_FAIL'

export const GET_ONE_AGREEMENT = 'referenceInformation/GET_ONE_AGREEMENT'
export const GET_ONE_AGREEMENT_DONE = 'referenceInformation/GET_ONE_AGREEMENT_DONE'
export const GET_ONE_AGREEMENT_FAIL = 'referenceInformation/GET_ONE_AGREEMENT_FAIL'

export const GET_FAQ_DATA = 'referenceInformation/GET_FAQ_DATA'
export const GET_FAQ_DATA_DONE = 'referenceInformation/GET_FAQ_DATA_DONE'
export const GET_FAQ_DATA_FAIL = 'referenceInformation/GET_FAQ_DATA_FAIL'

export const GET_FAQ_DICT = 'referenceInformation/GET_FAQ_DICT'
export const GET_FAQ_DICT_DONE = 'referenceInformation/GET_FAQ_DICT_DONE'
export const GET_FAQ_DICT_FAIL = 'referenceInformation/GET_FAQ_DICT_FAIL'