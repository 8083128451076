export function updateEditChildWorkPlaces(childs, structureExternalId, positionGroupExternalId, newWorkPlace) {
	let updatedChilds = [...childs] // Создаем копию массива, чтобы работать с ней, не изменяя оригинальный массив.

	for (let i = 0; i < updatedChilds.length; i++) {
		let currentChild = updatedChilds[i]

		// Ищем соответствующего ребенка (child).
		if (currentChild.externalId === structureExternalId) {
			// Перебираем группы positionGroups.
			let group = currentChild.positionGroups?.find((group) => group.externalId === positionGroupExternalId)

			if (group) {
				let index = group.workPlaces.findIndex((workPlace) => workPlace.externalId === newWorkPlace.externalId)

				if (index !== -1) {
					// Соответствующее рабочее место (workplace) найдено.
					group.workPlaces[index] = newWorkPlace // Обновляем его.

					// Пересчет staffUnitsCount группы.
					group.staffUnitsCount = group.workPlaces.reduce((total, workPlace) => {
						// Приводим к числу значение staffUnitsCount (и рабочих мест, и суммы).
						return total + Number(workPlace.staffUnitsCount || 0)
					}, 0)

					// Пересчет staffFreeCount группы.
					group.staffFreeCount = group.workPlaces.reduce((total, workPlace) => {
						// Приводим к числу значение staffFreeCount (и рабочих мест, и суммы).
						return total + Number(workPlace.staffFreeCount || 0)
					}, 0)

					return updatedChilds // Возвращаем результат, так как обновление уже завершено.
				}
			}
		}

		// Если у нашего дочернего элемента (child) есть свои дочерние элементы (childs), то ищем в них рекурсивно.
		if (currentChild.childs) {
			let result = updateEditChildWorkPlaces(
				currentChild.childs,
				structureExternalId,
				positionGroupExternalId,
				newWorkPlace
			)
			if (result !== null) {
				// Обновление проведено в дочернем элементе.
				return updatedChilds // Возвращаем результат, так как обновление уже завершено.
			}
		}
	}

	// Если выполнение дошло до этого момента, это значит, что рабочее место (workplace) не было обновлено.
	// Возвращаем null, чтобы указать это.
	return null
}
