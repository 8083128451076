export const TOGGLE_LOADING = 'staffing/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'staffing/TOGGLE_SUCCESS'
export const TOGGLE_ERROR = 'staffing/TOGGLE_ERROR'
export const SET_STAFFING = 'staffing/SET_STAFFING'
export const ADD_OPENED_CHILD_ID = 'staffing/ADD_OPENED_CHILD_ID'
export const REMOVE_OPENED_CHILD_ID = 'staffing/REMOVE_OPENED_CHILD_ID'
export const SET_STAFF_XML = 'staffing/SET_STAFF_XML'
export const UPDATE_WPLACE_EMPLOYEE = 'staffing/UPDATE_WPLACE_EMPLOYEE'
export const SET_WPLACE_EMPLOYEE = 'staffing/SET_WPLACE_EMPLOYEE'
export const SET_WPLACE_EMPLOYEE_STAT_INFO = 'staffing/SET_WPLACE_EMPLOYEE_STAT_INFO'
export const DELETE_WPLACE_EMPLOYEE = 'staffing/DELETE_WPLACE_EMPLOYEE'
export const RESET_WPLACE_EMPLOYEE = 'staffing/RESET_WPLACE_EMPLOYEE'
export const SET_EMPLOYEE_XML = 'staffing/SET_EMPLOYEE_XML'
export const SET_DEL_EMPLOYEE_XML = 'staffing/SET_DEL_EMPLOYEE_XML'
export const SET_EDIT_EMPLOYEE_XML = 'staffing/SET_EDIT_EMPLOYEE_XML'
export const SET_EDIT_ACTUAL_STRUCTURE_XML = 'staffing/SET_EDIT_ACTUAL_STRUCTURE_XML'
export const SEND_SIGNED_ACTUAL_STRUCTURE_XML = 'staffing/SEND_SIGNED_ACTUAL_STRUCTURE_XML'
export const SET_REMOVE_ACTUAL_STRUCTURE_XML = 'staffing/SET_REMOVE_ACTUAL_STRUCTURE_XML'
export const SEND_REMOVE_SIGNED_ACTUAL_STRUCTURE_XML = 'staffing/SEND_REMOVE_SIGNED_ACTUAL_STRUCTURE_XML'
export const SET_EDIT_ACTUAL_GROUP_XML = 'staffing/SET_EDIT_ACTUAL_GROUP_XML'
export const SEND_SIGNED_ACTUAL_GROUP_XML = 'staffing/SEND_SIGNED_ACTUAL_GROUP_XML'
export const SET_REMOVE_ACTUAL_GROUP_XML = 'staffing/SET_REMOVE_ACTUAL_GROUP_XML'
export const SEND_REMOVE_ACTUAL_GROUP_XML = 'staffing/SEND_REMOVE_ACTUAL_GROUP_XML'
export const SET_EDIT_ACTUAL_WORK_PLACE_XML = 'staffing/SET_EDIT_ACTUAL_WORK_PLACE_XML'
export const SEND_SIGNED_ACTUAL_WORK_PLACE_XML = 'staffing/SEND_SIGNED_ACTUAL_WORK_PLACE_XML'
export const SET_REMOVE_ACTUAL_WORK_PLACE_XML = 'staffing/SET_REMOVE_ACTUAL_WORK_PLACE_XML'
export const SEND_REMOVE_ACTUAL_WORK_PLACE_XML = 'staffing/SEND_REMOVE_ACTUAL_WORK_PLACE_XML'
export const GET_STAT_INFO = 'staffing/GET_STAT_INFO'
