import React, { useEffect } from 'react'
import { ModalTemplate, Title } from '@/ui'
import styles from './StaffingFirstStructure.module.scss'
import { Formik } from 'formik'
import { Form } from './Form'
import { staffSelectors, staffThunks } from '@/entity/staffingProject'
import { staffingSelectors, staffingThunks } from '@/entity/staffing'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentLanguage } from '@/i18next'
import { ValidationStructSchema } from './validationStructSchema'
import { LoadingBlocker } from '@/components/Loader'
import { DigitalSign } from '@/utils/digitalSign'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'

function filterStructByExternalId(staffProject, externalId) {
	if (staffProject.externalId === externalId) return staffProject

	if (Array.isArray(staffProject.childs)) {
		for (let child of staffProject.childs) {
			let res = filterStructByExternalId(child, externalId)
			if (res) return res
		}
	}

	return null
}

export const StaffingStructure = ({
	initialValues,
	staffType,
	rowSelectMenu,
	setRowSelectMenu,
	onClose,
	actions,
	staffProject,
	parentStructureId,
}) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const dispatch = useDispatch()
	const units = [rowSelectMenu]
	const isSuccess = useSelector(staffSelectors.isSuccess)
	const isLoading = useSelector(staffSelectors.isLoading)
	const isLoadingStaffing = useSelector(staffingSelectors.isLoading)

	const digitalSign = new DigitalSign()

	useEffect(() => {
		if (isSuccess) {
			if (actions.edit) {
				let structure = filterStructByExternalId(staffProject, rowSelectMenu.externalId)
				setRowSelectMenu(structure)
			}
			onClose()
		}
	}, [isSuccess])

	const showDeptType = (code) => {
		const deptType = staffType.find((item) => item.code === code)
		return deptType ? deptType[lang] : null
	}

	const deptTypeName = showDeptType(rowSelectMenu.deptTypeCode)

	const onSubmit = (data) => {
		const transformedStructure = createTransformedStructure(data, actions, staffProject, rowSelectMenu)
		if (actions.edit && staffProject.actual) {
			const type = 'structure'
			let body = {
				transformedStructure,
				type,
				onSuccess: async (xml) => {
					const res = await digitalSign.handleSend(xml)

					let signedBody = {
						data: { data: res },

						onSuccess: () => {
							message.success('Структура успешно изменена!')
							let structure = filterStructByExternalId(staffProject, rowSelectMenu.externalId)
							setRowSelectMenu(structure)
							onClose()
						},
						type,
					}

					dispatch(staffingThunks.sendSignActualStructure(signedBody))
				},
			}
			dispatch(staffingThunks.setActualEditStructure(body))
		} else if (actions.edit && !staffProject.actual) {
			dispatch(staffThunks.editStructure(transformedStructure))
		} else {
			dispatch(staffThunks.addStructure(transformedStructure))
		}
	}

	const createTransformedStructure = (data, actions, staffProject, rowSelectMenu) => {
		const createUnitStructure = (unit) => ({
			binBranch: unit.binBranch,
			dept: {
				code: unit.dept.code,
				nameKz: unit.dept.nameKz,
				nameRu: unit.dept.nameRu,
			},
			deptTypeCode: unit.deptTypeCode,
			...(unit.ddistrictCode ? { ddistrictCode: unit.ddistrictCode } : {}),
			...(unit.dregionCode ? { dregionCode: unit.dregionCode } : {}),
		})

		if (actions.edit) {
			const unitStructure = createUnitStructure(data.units[0])

			if (staffProject.actual) {
				// Если проект актуальный
				return {
					orgExternalId: staffProject.externalId,
					...unitStructure,
					externalId: rowSelectMenu.externalId,
					parentExternalId: parentStructureId,
				}
			} else {
				// Не актуальный проект
				return {
					orgExternalId: staffProject.externalId,
					row: {
						...unitStructure,
						externalId: rowSelectMenu.externalId,
					},
				}
			}
		} else {
			// Новый проект
			return {
				list: data.units.map(createUnitStructure),
				orgExternalId: staffProject.externalId,
				parentExternalId: rowSelectMenu.externalId,
			}
		}
	}
	return (
		<>
			{isLoading || isLoadingStaffing ? <LoadingBlocker /> : null}
			<ModalTemplate onClose={onClose} priority={actions.edit ? 2 : 1}>
				<div className={`scroll ${styles.wrapper}`}>
					<Title color={'black'}>{actions.edit ? `${t('edit')}: ${deptTypeName}` : deptTypeName}</Title>
					<Formik
						validationSchema={ValidationStructSchema}
						initialValues={actions.edit ? { units } : initialValues}
						staffType={staffType}
						rowSelectMenu={rowSelectMenu}
						onSubmit={onSubmit}
					>
						<Form onClose={onClose} staffType={staffType} actions={actions}></Form>
					</Formik>
				</div>
			</ModalTemplate>
		</>
	)
}
