import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './PositionForm.module.scss'
import { Field as FormikField, Form as FormikForm, useFormikContext } from 'formik'
import { Button, Field } from '@/ui'
import { formFieldNames } from './formFieldNames'
import { WrappedCheckbox } from '@features/contracts/registrationEmployerContract/form/fields'
import { formatNumber } from '@/utils'
import RadioStaff from '@/components/RadioStaff/RadioStaff'

export const WorkPlaceForm = ({ onClose, positions, positionsByKnz, laborMobilityCenterCategory }) => {
	const { t } = useTranslation()
	const { setFieldValue, values } = useFormikContext()

	const [inputValues, setInputValues] = useState({
		grossSalary: '',
		salarySupplement: '',
	})

	const handleInputChange = (fieldName) => (event) => {
		let value = event.target.value
		if (value && !inputValues[fieldName]) {
			setInputValues({ ...inputValues, [fieldName]: value })
		} else {
			const onlyNumbers = value.replace(/\D/g, '')
			const numberWithSpaces = formatNumber(onlyNumbers)
			setInputValues({ ...inputValues, [fieldName]: numberWithSpaces })
			setFieldValue(fieldName, onlyNumbers)
		}
	}

	return (
		<FormikForm className={styles.form}>
			<section>
				<div className={styles.item}>
					<div className={`${styles.fields} ${styles.fields_verticalCenter}`}>
						<div className={styles.label}>
							<FormikField name={formFieldNames.freelance}>
								{({ field }) => <WrappedCheckbox {...field} label={t('staff_modal.freelancer_position')} />}
							</FormikField>
						</div>
					</div>
					<FormikField name={formFieldNames.isDicPosition}>
						{({ field }) => (
							<div className={'flex'}>
								<RadioStaff label={t('workplace.manual_input')} value="2" id="2" checked={field.value === '2'} />
								<RadioStaff label={t('workplace.directory')} value="3" id="3" checked={field.value === '3'} />
							</div>
						)}
					</FormikField>
					{values[formFieldNames.isDicPosition] === '2' ? (
						<div className={styles.twoFields}>
							<FormikField name={formFieldNames.nameKz}>
								{({ field, meta: { touched, error } }) => (
									<Field
										{...field}
										type="text"
										name={t('staff_modal.position_kz')}
										label={t('staff_modal.position_kz')}
										placeholder={t('staff_modal.position_placeholder')}
										isRequired
										error={touched && error}
										disabled={true}
									/>
								)}
							</FormikField>
							<FormikField name={formFieldNames.nameRu}>
								{({ field, meta: { touched, error } }) => (
									<Field
										{...field}
										type="text"
										label={t('staff_modal.position_ru')}
										placeholder={t('staff_modal.position_placeholder')}
										isRequired
										error={touched && error}
										disabled={true}
									/>
								)}
							</FormikField>
						</div>
					) : (
						<FormikField name="position">
							{({ field, meta: { touched, error } }) => (
								<Field
									{...field}
									fieldType="selectSearch"
									label={t('contract.info.position')}
									placeholder={t('position_placeholder')}
									options={positions}
									error={touched && error}
									disabled={true}
								/>
							)}
						</FormikField>
					)}
					<FormikField name={formFieldNames.positionCode}>
						{({ field, meta: { touched, error } }) => (
							<Field
								{...field}
								fieldType="selectSearch"
								label={t('contract.info.nkz_position')}
								placeholder={t('choose_from_directory')}
								options={positionsByKnz}
								error={touched && error}
								disabled={true}
							/>
						)}
					</FormikField>
					<FormikField name={formFieldNames.staffCategory}>
						{({ field, meta: { touched, error } }) => (
							<Field
								{...field}
								fieldType="select"
								label={t('staff_modal.position_category')}
								placeholder={t('choose_from_directory')}
								options={laborMobilityCenterCategory}
								error={touched && error}
								disabled={true}
							/>
						)}
					</FormikField>
					<div className={`${styles.fields} ${styles.threeFields}`}>
						<div>
							<FormikField name={formFieldNames.staffUnitsCount}>
								{({ field, meta: { touched, error } }) => (
									<Field
										{...field}
										type="number"
										hideNumberArrows
										placeholder={t('staff.staff_units_placeholder')}
										error={touched && error}
										label={t('workplace.staff_unit')}
										isRequired
									/>
								)}
							</FormikField>
						</div>
						<FormikField name={formFieldNames.grossSalary}>
							{({ field, meta: { touched, error } }) => (
								<Field
									type="text"
									hideNumberArrows
									fieldType={'inputNumber'}
									isRequired
									label={t('staffing_table.salary')}
									placeholder={t('staff_modal.salary_placeholder')}
									error={touched && error}
									value={inputValues.grossSalary || field.value}
									onChange={handleInputChange(formFieldNames.grossSalary)}
									autoComplete="off"
									{...field}
								/>
							)}
						</FormikField>
						<FormikField name={formFieldNames.salarySupplement}>
							{({ field, meta: { touched, error } }) => (
								<Field
									type="text"
									fieldType={'inputNumber'}
									hideNumberArrows
									label={t('staffing_table.supplement_surcharge')}
									placeholder={t('staff_modal.supplement_surcharge_placeholder')}
									error={touched && error}
									value={inputValues.salarySupplement || field.value}
									onChange={handleInputChange(formFieldNames.salarySupplement)}
									autoComplete="off"
									{...field}
								/>
							)}
						</FormikField>
					</div>
				</div>
			</section>
			<div className={styles.actions}>
				<Button type="bare" onClick={onClose}>
					{t('cancel')}
				</Button>
				<Button textColor="green" borderColor="green" transparent border type="submit">
					{t('save')}
				</Button>
			</div>
		</FormikForm>
	)
}
