import React from 'react'
import styles from './styles.module.scss'
import { Trans, useTranslation } from 'react-i18next'
import { Title } from '@Root/components/Typography'
import Header from '@/components/Header'
import PreHeader from '@/components/home/components/PreHeader'
import Footer from '@features/home/Footer'
export const AboutProjectPage = () => {
	const { t } = useTranslation()
	return (
		<>
			{/*раскомментировать в случае траура*/}
			{/*<PreHeader />*/}
			<Header active="about_project" dark />
			<div className={`${styles.page} container`}>
				<Title level={1}>{t('about_projects.title_1')}</Title>
				<div className={styles.main_content}>
					<Trans
						i18nKey={'about_projects.text_1'}
						components={{ strong: <strong />, p: <p /> }}
					/>
				</div>

				<Title level={2}>{t('about_projects.title_2')}</Title>
				<div className={styles.main_content}>
					<Trans
						i18nKey={'about_projects.text_2'}
						components={{ strong: <strong />, p: <p />, ul: <ul />, li: <li /> }}
					/>
				</div>

				<Title level={2}>{t('about_projects.title_3')}</Title>
				<div className={styles.main_content}>
					<Trans
						i18nKey={'about_projects.text_3'}
						components={{ strong: <strong />, p: <p />, ul: <ul />, li: <li /> }}
					/>
				</div>

				<div className={styles.main_content}>
					<Trans
						i18nKey={'about_projects.text_4'}
						components={{ strong: <strong />, p: <p />, ul: <ul />, li: <li /> }}
					/>
				</div>
			</div>
			<Footer />
		</>
	)
}
