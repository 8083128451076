import styled from 'styled-components'
import { Table } from 'antd'


export const StyledTable = styled(Table)`
    .ant-table {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        border: 1px solid #D3DFF0;

        overflow: hidden;


        .ant-table-thead {
            .ant-table-cell {
                background: #5b6880;
                color: #fff;
                font-size: 12px;
                font-weight: 700;
                line-height: 14px;

                &:before {
                    display: none;
                }
            }
        }

        .ant-table-tbody {
            & > tr > td {
                border-color: #D3DFF0;
                padding: 12px;
            }

            .ant-table-cell{
								padding: 10px 12px;
						}
						
						.ant-table-cell-with-append{
								position: relative;
								
                .ant-table-row-indent {
                    padding-left: 0 !important;
                }
						}

            

            tr {
                &.ant-table-row-level-0 {
                    .ant-table-cell-with-append {
                        &.ant-table-cell:first-child {
                            padding-left: 2rem;
														
														svg{
                                position: absolute;
                                left: 10px;
                                top: 14px;
														}
                        }
                    }
                }

                &.ant-table-row-level-1 {
                    .ant-table-cell-with-append {
                        &.ant-table-cell:first-child {
                            padding-left: 4rem;

                            svg{
                                position: absolute;
                                left: 40px;
                                top: 14px;
                            }
                        }
                    }
                }

                &.ant-table-row-level-2 {
                    .ant-table-cell-with-append {
                        &.ant-table-cell:first-child {
                            padding-left: 6rem;

                            svg{
                                position: absolute;
                                left: 70px;
                                top: 14px;
                            }
                        }
                    }
                }

                &.ant-table-row-level-3 {
                    .ant-table-cell-with-append {
                        &.ant-table-cell:first-child {
                            padding-left: 8rem;

                            svg{
                                position: absolute;
                                left: 100px;
                                top: 14px;
                            }
                        }
                    }
                }
            }
        }
				
				.ant-table-summary{
            .ant-table-cell{
                padding: 10px 12px;
								font-weight: 700;
            }
        }
    }
`