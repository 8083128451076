import * as types from './types'

export const initialStaffingPosition = {
	code: '',
	externalId: '',
	factSalary: '',
	freelancer: false,
	grossSalary: '',
	salarySupplement: '',
	staffFreeCount: '',
	staffUnitsCount: '',
	errors: [],
	isReplacement: false,
	isCombining: false,
	isDicPosition: '2',
}

export const initialStaffingGroup = {
	destablishedPost: '',
	dposition: '',
	dstaffCategory: '',
	factSalary: '',
	grossSalary: '',
	orgId: '',
	salarySupplement: '',
	workplaceCount: '',
	structureId: '',
	isDicPosition: '2',
	errors: [],
	workPlaces: [initialStaffingPosition],
}

export const initialStaffing = {
	id: 1,
	parentId: null,
	orderNumber: '',
	orderDate: '',
	cause: '',
	tariffRate: null,
	units: [
		{
			subsidiary: false,
			binBranch: '',
			deptTypeCode: '',
			dept: {
				nameKz: '',
				nameRu: '',
				code: '',
			},
		},
	],
}

export const initialStaff = {
	actual: false,
	beginDate: '',
	bin: '',
	childs: [
		{
			binBranch: '',
			childs: [{}],
			dept: {
				code: '',
				nameKz: '',
				nameRu: '',
			},
			deptTypeCode: '',
			externalId: '',
			parentExternalId: '',
			positionGroups: [initialStaffingGroup],
			errors: [],
		},
	],
	currVersion: 0,
	externalId: '',
	note: '',
	orderDate: '',
	orderNumber: '',
	parentBin: '',
	statusCode: '',
	errors: [],
}

const initialState = {
	isLoading: false,
	isSuccess: false,
	error: null,
	staff: {
		data: initialStaff,
		isLoading: false,
		isSuccess: false,
	},
	openedChildsIds: [],
	staffXML: null,
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.TOGGLE_LOADING:
			return { ...state, isLoading: payload }
		case types.TOGGLE_SUCCESS:
			return { ...state, isSuccess: payload }
		case types.TOGGLE_ERROR:
			return { ...state, error: payload }
		case types.SET_STAFF_PROJECT:
			return {
				...state,
				staff: {
					...state.staff, // сохраняем все остальные свойства staff без изменений
					data: payload, // обновляем data новыми данными
				},
			}
		case types.ADD_OPENED_CHILD_ID:
			return {
				...state,
				openedChildsIds: [...state.openedChildsIds, payload],
			}
		case types.REMOVE_OPENED_CHILD_ID:
			return {
				...state,
				openedChildsIds: state.openedChildsIds.filter((id) => id !== payload),
			}
		case types.SET_STAFF_XML:
			return { ...state, staffXML: payload }
		case types.SET_STATUS:
			return {
				...state,
				staff: {
					...state.staff,
					data: {
						...state.staff.data,
						statusCode: payload,
					},
				},
			}
		default:
			return state
	}
}
