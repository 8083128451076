import axios from 'axios'
import { clearUserData } from '@/shared'

const getAdditionalHeaders = () => {
	const langs = {
		rus: 'ru',
		kz: 'kz',
	}

	let lang = 'ru'
	let activeRole = ''
	try {
		activeRole = JSON.parse(localStorage.getItem('activeRole')) || ''
		lang = localStorage.getItem('lang')
	} catch (error) {}
	return { 'Accept-Language': langs[lang], role: activeRole }
}

export const request = (config) => {
	const additionalHeaders = getAdditionalHeaders()
	const instance = axios.create({
		baseURL: process.env.REACT_APP_API_URL,
		withCredentials: true,
		crossOriginIsolated: false,
		headers: additionalHeaders,
	})

	instance.defaults.withCredentials = true
	instance.interceptors.request.use(
		function (config) {
			const additionalHeaders = getAdditionalHeaders()

			return {
				...config,
				headers: { ...config.headers, ...additionalHeaders },
			}
		},
		(error) => Promise.reject(error)
	)
	instance.interceptors.response.use(
		function (response) {
			return response
		},
		function (error) {
			if (401 === error.response?.status || 403 === error.response?.status) {
				// window.location.href = process.env.REACT_APP_LOGOUT_REDIRECT
				clearUserData()
			} else {
				return Promise.reject(error)
			}
		}
	)
	return instance(config).then((response) => response?.data)
}
