import { Sign as SignIcon, View as ViewIcon, CancelCircle } from '@app/images'

export default function onActionMenuClick(row, e, setActionMenuItems, t, isEmployee) {
	const status = row.status
	const statusUnion = row.statusUnion
	if (e) {
		e.preventDefault()
		e.stopPropagation()
	}
	let actions = [{ icon: ViewIcon, title: t('actions.view'), action: 'view' }]
	if (!isEmployee) {
		if (status === "DRAFT" && statusUnion === "APPROVED") {
			actions = [...actions, { icon: SignIcon, title: t('actions.approve'), action: 'approve' }]
			actions = [...actions, { icon: CancelCircle, title: t('actions.reject'), action: 'reject' }]
		}
		if (status === "APPROVED" && statusUnion === "APPROVED") {
			actions = [...actions, { icon: CancelCircle, title: t('actions.exclude'), action: 'exclude' }]
		}
	}

	setActionMenuItems(actions)
}
