import { useTranslation } from 'react-i18next'
import { connect, Field as FormikField, Form as FormikForm, useFormikContext } from 'formik'
import { Button, Field, Title } from '@/ui'
import React, { useEffect, Fragment, useMemo, useState } from 'react'
import { formFieldNames } from '@/entity/collectiveContracts'
import styles from './ECollectiveForm.module.scss'
import { PayFields, WorkingHolidaysFields } from './fields'
import { getCurrentLanguage } from '@/i18next'
import { FormControlLabel, RadioGroup } from '@mui/material'
import { Radio } from '@/entity/contracts/ui/form/components'
import { GuaranteesSection } from '@/components/GuaranteesSection/GuaranteesSection'
import { useDispatch } from 'react-redux'
import { ConfirmDocument } from '@/components/AddDocuments/ConfirmDocument'
import { eColContractsThunks } from '../../model/index'
import cl from 'classnames'
import { TariffRatesFields } from '@/components/TariffRatesFields/TariffRatesFields'
import { Uploader } from '@/components/Uploader/Uploader'

export const UnionNameField = connect(({ fieldName, disabled = false, handleChange, unionIndex }) => {
	const { t } = useTranslation()
	return (
		<FormikField name={fieldName}>
			{({ field, meta: { touched, error } }) => (
				<Field
					{...field}
					type="text"
					wrapperClassName={styles.field__input}
					label={t('col_contract.union_name')}
					placeholder={t('filled_automate')}
					error={touched && error ? t(error) : ''}
					isRequired
					onChange={(event) => handleChange(event, unionIndex)}
					disabled={disabled}
				/>
			)}
		</FormikField>
	)
})

let UPLOAD_FORM_KEY = {
	fileProtocolDisagreement: 'fileProtocolDisagreement',
	eColContractProjectFile: 'eColContractProjectFile',
}

let errorInitialState = {
	[UPLOAD_FORM_KEY.files]: false,
	[UPLOAD_FORM_KEY.eColContractProjectFile]: false,
}

export const ECollectiveForm = ({ options = {}, tradeUnion, files, isEdit, onCancel, isEmployee }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const { values, setFieldValue, isValid } = useFormikContext()
	const dispatch = useDispatch()
	const {
		workTimeCollectiveContract,
		relaxTimeCollectiveContract,
		unionTypeCollectiveContract,
		extraGuaranteesCollectiveContract,
		ccEnterpriseType,
		ccOwnershipType,
		industryAgreement,
		contractTerms,
		colContractTerms,
		dColContractDate
	} = options

	console.log({options})
	const [isDisableError, setIsDisableError] = useState(errorInitialState)

	useEffect(() => {
		setFieldValue('union', '1')
	}, [])

	const addGuarantee = () => {
		setFieldValue(`extraGuarantee`, [
			...values.extraGuarantee,
			{
				code: '',
				value: '',
			},
		])
	}

	const contractTermsOptions = useMemo(() => {
		return contractTerms?.filter(x => ["1", "10"].includes(x.code)) || []
	}, [contractTerms])


	const removeGuarantee = (index) => {
		const updatedGuarantee = [...values.extraGuarantee]
		updatedGuarantee.splice(index, 1)
		setFieldValue('extraGuarantee', updatedGuarantee)
	}

	const getGuaranteeLabel = (id) => {
		const guarantee = extraGuaranteesCollectiveContract.find((item) => item.code === id.toString())
		return guarantee[lang]
	}

	const getDocument = (key, values) => {
		let res = (values || []).map((item) => item)
		setFieldValue(key, res)
	}

	const setUploadedFile = (file) => {
		setFieldValue("files", file)
	}

	const getErrorSize = (key, error) => {
		setIsDisableError((prevState) => ({
			...prevState,
			[key]: error,
		}))
	}

	const isDisableSubmit = useMemo(() => {
		return !isDisableError[UPLOAD_FORM_KEY.files] && !isDisableError[UPLOAD_FORM_KEY.fileProtocolDisagreement]
	}, [isDisableError])

	const getTransformedColContract = (data) => {
		let transformedColContract = {
			clang: data.language,
			contractDTO: {
				bin: data.bin,
				collectiveContractDetails: {
					fundingAmount: data.fundingAmount,
					relaxTime: data.relaxTime,
					securityFundingVolume: data.securityFundingVolume,
					workTime: data.workTime,
					dcolContractEnterpriseTypeCode: data.dcolContractEnterpriseTypeCode,
					dcolContractOwnershipTypeCode: data.dcolContractOwnershipTypeCode,
				},
				collectiveContractNumber: data.collectiveContractNumber,
				companyName: data.enterpriseName,
				companyNameKaz: data.enterpriseNameKaz,
				dateFrom: data.dateFrom,
				dateTo: data.dateTo,
				dcontractDateCode: data.dcontractDateCode,
				electronic: true,
				unions: data.unions,
				extraGuarantee: data.extraGuarantee,
				files: data?.files,
				positions: data.positions,
				industryCodes: data.industryCodes,
				fileProtocolDisagreement: data.fileProtocolDisagreement[0],
			},
			externalId: data.externalId,
			fileId: data?.files?.[0]?.id
		}
		// Добавляем overtimePay только если overtimePayIs равно true
		if (data.overtimePayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.overtimePay = data.overtimePay
		}
		if (data.holidayPayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.holidayPay = data.holidayPay
		}
		if (data.nightTimePayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.nightTimePay = data.nightTimePay
		}
		if (data.combinationPositionPayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.combinationPositionPay = data.combinationPositionPay
		}
		if (data.termCompensationPayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.termCompensationPay = data.termCompensationPay
		}
		if (data.wayCompensationPayIs) {
			transformedColContract.contractDTO.collectiveContractDetails.wayCompensationPay = data.wayCompensationPay
		}
		if (data.wageIndexProcedureIs) {
			transformedColContract.contractDTO.collectiveContractDetails.wageIndexProcedure = data.wageIndexProcedure
		}
		return transformedColContract
	}

	const saveColContract = () => {
		const transformedColContract = getTransformedColContract(values)
		dispatch(eColContractsThunks.save(transformedColContract, false, isEmployee))
		onCancel()
	}

	const validityTermIdsWhenEndContractDateIsRequired = [2]

	const employers = useMemo(() => {
		if (tradeUnion && tradeUnion?.employers) {
			return tradeUnion?.employers.map(el => ({
				code: el.employerName,
				rus: el.employerName,
				kz: el.employerName,
			}))
		} else {
			return []
		}
	}, [tradeUnion])

	const onChangeEmployer = (value) => {
		const selected = tradeUnion?.employers.find(x => x.employerName === value) 
		if(selected) {
			setFieldValue("bin", selected.employerBin)
			setFieldValue("enterpriseName", selected.employerName)
			setFieldValue("enterpriseNameKaz", selected.employerName)
		}
	}


	return (
		<FormikForm className={styles.form}>
			<section>
				<Title>{t('employer_data')}</Title>
				<div className={styles.fields__wrapper}>
					<div className={styles.fields__item__no__button__margin_right}>
						{
							employers?.length > 1 ?
								<FormikField name={formFieldNames.enterpriseName}>
									{({ field, meta: { touched, error } }) => (
										<Field
											fieldType={'select'}
											isRequired
											label={t('col_contract.name')}
											placeholder={t('select_from_list')}
											options={employers}
											error={touched && error}
											{...field}
											onChange={onChangeEmployer}
										/>
									)}
								</FormikField> :
								<FormikField name={formFieldNames.enterpriseName}>
									{({ field, meta: { touched, error } }) => (
										<Field
											type="text"
											disabled
											wrapperClassName={styles.field__input}
											label={t('col_contract.name')}
											placeholder={t('filled_automate')}
											{...field}
											error={touched && error ? t(error) : ''}
											isRequired
										/>
									)}
								</FormikField>
						}

					</div>
					<div className={cl(styles.fieldAndButton, styles.width25)}>
						<FormikField name={formFieldNames.bin}>
							{({ field, meta: { touched, error } }) => (
								<Field
									disabled
									wrapperClassName={styles.field__input}
									hideNumberArrows
									label={t('col_contract.bin')}
									placeholder={t('filled_automate')}
									{...field}
									error={touched && error ? t(error) : ''}
									isRequired
									maxLength={12}
									pattern="[0-9]{12}"
									onInput={(e) => {
										e.target.value = e.target.value.replace(/[^0-9]/g, '')
									}}
								/>
							)}
						</FormikField>
					</div>
				</div>
				<div className={`${styles.fields} ${styles.twoFields} mt-4`}>
					<FormikField name={formFieldNames.dcolContractEnterpriseTypeCode}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType={'select'}
								isRequired
								label={t('col_contract.typeEnterprise')}
								placeholder={t('select_from_list')}
								options={ccEnterpriseType}
								error={touched && error}
								{...field}
							/>
						)}
					</FormikField>
					<FormikField name={formFieldNames.dcolContractOwnershipTypeCode}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType={'select'}
								isRequired
								label={t('col_contract.typeOwnership')}
								placeholder={t('select_from_list')}
								options={ccOwnershipType}
								error={touched && error}
								{...field}
							/>
						)}
					</FormikField>
				</div>
				<div className={`${styles.fields__item__no__button__left} mt-4`}>
					<FormikField name={formFieldNames.industryCodes}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType="multipleSelect"
								isRequired
								label={t('profUnions.industry')}
								placeholder={t('select_from_list')}
								options={industryAgreement}
								error={touched && error}
								{...field}
							/>
						)}
					</FormikField>
				</div>
			</section>
			<section>
				<Title>{t('union_data')}</Title>
				{values.unions.map((union, unionIndex) => (
					<Fragment key={unionIndex}>
						<div className={unionIndex ? styles.fieldAndRemoveButton : null}>
							<FormikField name={`unions.${unionIndex}.unionTypeCode`}>
								{({ field, meta: { touched, error } }) => (
									<Field
										disabled
										fieldType={'select'}
										isRequired
										label={t('col_contract.col_union')}
										placeholder={t('choose_from_directory')}
										options={unionTypeCollectiveContract}
										error={touched && error}
										{...field}
									/>
								)}
							</FormikField>
						</div>
						{union?.unionTypeCode === '2' ? (
							<div className={styles.fields__iin__wrapper}>
								<div className={styles.fieldAndButton}>
									<FormikField name={`unions.${unionIndex}.unionBin`}>
										{({ field, meta: { touched, error } }) => (
											<Field
												disabled
												{...field}
												placeholder={t('enter_count_digitals', { count: 12 })}
												error={touched && error ? t(error) : ''}
												wrapperClassName={styles.field__input}
												label={t('col_contract.union_bin')}
												maxLength={12}
												pattern="[0-9]{12}"
												onInput={(e) => {
													e.target.value = e.target.value.replace(/[^0-9]/g, '')
												}}
											/>
										)}
									</FormikField>
								</div>
								{lang === 'rus' ? (
									<div className={styles.fields__item__no__button}>
										<UnionNameField
											unionIndex={unionIndex}
											fieldName={`unions.${unionIndex}.unionName`}
											disabled={true}
										/>
									</div>
								) : (
									<div className={styles.fields__item__no__button}>
										<UnionNameField
											unionIndex={unionIndex}
											fieldName={`unions.${unionIndex}.unionNameKaz`}
											disabled={true}
										/>
									</div>
								)}
							</div>
						) : (
							union?.unionTypeCode === '1' &&
							(lang === 'rus' ? (
								<div className={styles.fields_union_name}>
									<UnionNameField
										unionIndex={unionIndex}
										fieldName={`unions.${unionIndex}.unionName`}
										disabled={true}
									/>
								</div>
							) : (
								<div className={styles.fields_union_name}>
									<UnionNameField
										unionIndex={unionIndex}
										fieldName={`unions.${unionIndex}.unionNameKaz`}
										disabled={true}
									/>
								</div>
							))
						)}
						{union.representativeDtos.map((representative, repIndex) => (
							<div key={repIndex} className={styles.fields__iin__wrapper}>
								<div className={styles.fieldAndButton}>
									<FormikField name={`unions.${unionIndex}.representativeDtos.${repIndex}.representativeIin`}>
										{({ field, meta: { touched, error } }) => (
											<Field
												disabled
												{...field}
												placeholder={t('enter_count_digitals', { count: 12 })}
												error={touched && error ? t(error) : ''}
												wrapperClassName={styles.field__input}
												label={t('col_contract.representativeIin')}
												maxLength={12}
												pattern="[0-9]{12}"
												onInput={(e) => {
													e.target.value = e.target.value.replace(/[^0-9]/g, '')
												}}
											/>
										)}
									</FormikField>
								</div>
								<div className={repIndex ? styles.fieldAndRemoveButtonIIN : styles.fields__item__no__button}>
									<FormikField name={`unions.${unionIndex}.representativeDtos.${repIndex}.representativeFIO`}>
										{({ field, meta: { touched, error } }) => (
											<Field
												disabled
												type="text"
												wrapperClassName={styles.field__input}
												label={t('col_contract.representativeFIO')}
												placeholder={t('filled_automate')}
												{...field}
												error={touched && error ? t(error) : ''}
												isRequired
											/>
										)}
									</FormikField>
								</div>
							</div>
						))}
					</Fragment>
				))}
			</section>
			<section>
				<Title>{t('col_contract.info')}</Title>
				<div className={cl(styles.fields, styles.twoFields, 'mt-4')}>
					<FormikField name={formFieldNames.collectiveContractNumber}>
						{({ field, meta: { touched, error, errors } }) => (
							<>
								<Field
									{...field}
									type="text"
									label={t('col_contract.number')}
									isRequired
									placeholder={t('col_contract.placeholder_number')}
									error={touched && error}
								/>
							</>
						)}
					</FormikField>
					<FormikField name={formFieldNames.dcontractDateCode}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType={'select'}
								isRequired
								label={t('col_contract.date_end_col_contract')}
								placeholder={t('select_from_list')}
								options={dColContractDate || []}
								error={touched && error}
								{...field}
							/>
						)}
					</FormikField>
				</div>
				<div className={cl(styles.fields, styles.twoFields, 'mt-4')}>
					<FormikField name={formFieldNames.dateFrom}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType="datePicker"
								label={t('col_contract.dateFrom')}
								isRequired
								placeholder={t('default_date_value')}
								minDate={new Date(1900, 0, 1)}
								error={touched && error}
								{...field}
							/>
						)}
					</FormikField>
					<FormikField name={formFieldNames.dateTo}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType="datePicker"
								isRequired={validityTermIdsWhenEndContractDateIsRequired.includes(Number(values.dcontractDateCode))}
								disabled={!validityTermIdsWhenEndContractDateIsRequired.includes(Number(values.dcontractDateCode))}
								label={t('col_contract.dateTo')}
								placeholder={t('default_date_value')}
								minDate={new Date(1900, 0, 1)}
								maxDate={new Date(Date.now() + 1000 * 60 * 60 * 24 * (365 * 3 + 2))}
								error={touched && error}
								{...field}
							/>
						)}
					</FormikField>
				</div>
			</section>
			<section>
				<Title>{t('col_contract.pay_info')}</Title>
				<PayFields />
			</section>
			<section>
				<Title>{t('col_contract.information_tariff_rates')}</Title>
				<TariffRatesFields />
			</section>
			<section>
				<Title>{t('col_contract.duration_working_hours_and_rest_time_holidays')}</Title>
				<WorkingHolidaysFields
					workTimeCollectiveContract={workTimeCollectiveContract}
					relaxTimeCollectiveContract={relaxTimeCollectiveContract}
				/>
			</section>
			<section>
				<Title>{t('col_contract.safe_working_protection')}</Title>
				<div className={styles.mbFields}>
					<FormikField name={formFieldNames.securityFundingVolume}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType={'textarea'}
								isRequired
								label={t('col_contract.amount_funding')}
								placeholder={t('col_contract.placeholder_amount_text')}
								error={touched && error ? t(error) : ''}
								{...field}
								showCount
								maxLength={4000}
								rows={6}
								{...field}
							/>
						)}
					</FormikField>
				</div>
				<div className={styles.mbFields}>
					<FormikField name={formFieldNames.fundingAmount}>
						{({ field, meta: { touched, error } }) => (
							<Field
								type="number"
								min={0}
								hideNumberArrows
								enablecheckfloat
								label={t('col_contract.funding_amount')}
								placeholder={t('col_contract.amount')}
								error={touched && error ? t(error) : ''}
								{...field}
								wrapperClassName={styles.securityFundingVolume}
							/>
						)}
					</FormikField>
				</div>
			</section>
			<GuaranteesSection
				values={values}
				extraGuaranteesCollectiveContract={extraGuaranteesCollectiveContract}
				removeGuarantee={removeGuarantee}
				addGuarantee={addGuarantee}
				getGuaranteeLabel={getGuaranteeLabel}
				t={t}
				title={t('col_contract.guarantee_title')}
			/>
			<section>
				<Title>{t('col_contract.protocolDisagreement')}</Title>
				<ConfirmDocument
					getDocument={(values) => getDocument(UPLOAD_FORM_KEY.fileProtocolDisagreement, values)}
					getErrorSize={(error) => getErrorSize(UPLOAD_FORM_KEY.fileProtocolDisagreement, error)}
					fileType={'COLLECTIVECONTRACT'}
					fileList={isEdit ? [values.fileProtocolDisagreement] : files}
					maxFiles={1}
					wrapStyle={styles.confirmDocumentWrap}
				/>
			</section>
			<section>
				<Title>{t('contract_text')} *</Title>
				<p>{t('col_contract.language')}</p>
				<div className={styles.mb24}>
					<FormikField name="language" isRequired>
						{({ field, form: { errors }, name, options, children, ...props }) => {
							const fieldName = name || field?.name
							return (
								<RadioGroup {...field} {...props} name={fieldName}>
									<FormControlLabel
										control={<Radio />}
										label={t('col_contract.kz')}
										value="kz"
										classes={{ root: styles.label__root }}
									/>
									<FormControlLabel
										control={<Radio />}
										label={t('col_contract.rus')}
										value="ru"
										classes={{ root: styles.label__root }}
									/>
									{errors?.[fieldName] && <div style={{ color: 'red' }}>{errors?.[fieldName]}</div>}
								</RadioGroup>
							)
						}}
					</FormikField>
				</div>
				<div>
					<Uploader
						value={values.files}
						maxFiles={1}
						onSuccess={setUploadedFile}
						acceptedFileTypes={['.doc', '.docx']}
						isAlwaysAttachButtonShow={true}
						isBigSize
						isEcFileUpload
						buttonTitle={values?.files.length !== 0 ? t("col_contract.update_ecd_doc") : t("col_contract.set_ecd_doc")}
					/>
				</div>
				<div className={styles.concludeButton}>
					<Button onClick={saveColContract} disabled={!isDisableSubmit || !isValid} buttonClassName={styles.firstButton}>
						{t('save')}
					</Button>
					<Button disabled={!isValid} type="submit">{t('continue')}</Button>
				</div>
			</section>
		</FormikForm>
	)
}
