import { api } from '@/shared'
import { message } from 'antd'
import { requirementLaborResourcesActions } from '@/entity/requirementLaborResources/index'

export const getData = () => {
	return async (dispatch) => {
		try {
			dispatch(requirementLaborResourcesActions.getDataAction())

			const res = await api.requirementLaborResources.getData()

			dispatch(requirementLaborResourcesActions.getDataActionDone(res))
		} catch (error){
			message.error(error.response?.data?.message)

			dispatch(requirementLaborResourcesActions.getDataActionFail())
		}
	}
}