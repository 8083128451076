import { request } from '@/shared/api/request'

export const editActual = (data, type) => {
	return request({
		method: 'POST',
		url: `staff/editActual/${type}/edit/xml`,
		data: data,
	})
}

export const sendSignedActualXml = (data, type) => {
	return request({
		method: 'POST',
		url: `staff/editActual/${type}/edit/sign`,
		data: data,
	})
}

export const removeActual = (externalId, type) => {
	let params = { externalId: externalId }
	return request({
		method: 'POST',
		url: `staff/editActual/${type}/del/xml`,
		params: params,
	})
}

export const sendSignedActualXmlRemove = (data, type) => {
	let payload = { data: data }
	return request({
		method: 'POST',
		url: `staff/editActual/${type}/del/sign`,
		data: payload,
	})
}
