import doc from '@/assets/home/doc.svg'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styles from './AgreementItem.module.scss'

export const AGREEMENT_KEY = {
	nameRu: 'nameRu',
	nameKz: 'nameKz',
}

export const AgreementItem = ({
																langKey,
																item,
																onClick,
															}) => {
	const { t } = useTranslation()

	const currentLang = {
		[AGREEMENT_KEY.nameRu]: t('faq.labelRu'),
		[AGREEMENT_KEY.nameKz]: t('faq.labelKz'),
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<img src={doc} alt="doc" />
				<span>{item[langKey]}</span>
			</div>

			<div className={styles.container}>
				<span>
					{currentLang[langKey]}
				</span>

				<div role='button' onClick={onClick}>
					{t('faq.export')}
				</div>
			</div>
		</div>
	)
}