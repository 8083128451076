import React from 'react'

export const StaffUnitsCount = ({ t, totalStaffUnits, totalFreeStaffUnits }) => {
	return (
		<p>
			{t('count_staff_units_1')}
			<span>{totalStaffUnits}</span>
			{t('count_staff_units_2')}
			<span>{totalFreeStaffUnits}</span>
		</p>
	)
}
