import React, { useMemo, useState } from 'react'
import {
	Add as AddIcon,
	Edit as EditIcon,
	Remove as RemoveIcon,
	StaffChevronDown,
	StaffChevronUp,
	View as ViewIcon,
} from '@app/images'
import { headCells } from './tableGroupTreeView.headings'
import { useTranslation } from 'react-i18next'
import styles from './TableGroupTreeView.module.scss'
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu'
import { useDispatch, useSelector } from 'react-redux'
import { staffActions, staffSelectors } from '@/entity/staffingProject'
import { LoadingScreen } from '@/components/Loader'
import { Title } from '@/ui'
import { getCurrentLanguage } from '@/i18next'
import renderIcon from '@pages/PersonalAffairs/components/Icons'
import { checkProjectErrors, formatNumber } from '@/utils'

const TableGroupTreeView = ({
	node,
	setActionsGroup,
	setGroupRow,
	setActionsPositions,
	setPositionRowSelectMenu,
	isProject,
	establishedPost,
	dposition,
	dstaffCategory,
	code,
	groupId,
}) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const [actionMenuItems, setActionMenuItems] = useState([])
	const isOpen = useSelector((state) => state.staff.openedChildsIds.includes(node.externalId))
	const { staffingGrants } = useSelector((state) => ({
		staffingGrants: state.administration.staffingGrants,
	}))

	const isGroup = node.level === 0

	const total = useMemo(() => {
		const initialSums = {
			grossSalary: 0,
			salarySupplement: 0,
		}

		return (node.workPlaces ?? []).reduce((acc, workplace) => {
			acc.grossSalary += Number(workplace.grossSalary) || 0
			acc.salarySupplement += Number(workplace.salarySupplement) || 0
			return acc
		}, initialSums)
	}, [node])

	const handleRowClick = (e) => {
		if (node.workPlaces?.length > 0) {
			if (isOpen) {
				dispatch(staffActions.removeOpenedChildId(node.externalId))
			} else {
				dispatch(staffActions.addOpenedChildId(node.externalId))
			}
		}
		if (isGroup) {
			setGroupRow(node)
		} else {
			setActionsPositions((prevState) => {
				return { ...prevState, view: true }
			})
			const workPlace = {
				...node,
				establishedPost: establishedPost,
				dposition: dposition,
				dstaffCategory: dstaffCategory,
				codeGroup: code,
				groupId: groupId,
			}
			setPositionRowSelectMenu(workPlace)
		}
		e.stopPropagation()
	}

	const onToggleAction = (action, value, row) => {
		if (row.level === 0) {
			setGroupRow(row)
			setActionsGroup((prevState) => {
				return { ...prevState, [action]: value }
			})
		} else {
			const workPlace = {
				...row,
				establishedPost: establishedPost,
				dposition: dposition,
				dstaffCategory: dstaffCategory,
				codeGroup: code,
				groupId: groupId,
			}
			setPositionRowSelectMenu(workPlace)
			setActionsPositions((prevState) => {
				return { ...prevState, [action]: value }
			})
		}
	}
	const onActionMenuChoose = (mode, row) => {
		onToggleAction(mode, true, row)
	}

	const onProjectGroupActionMenuClick = (row, e, setActionMenuItems) => {
		if (e) {
			e.preventDefault()
			e.stopPropagation()
		}
		let actions = [
			{ icon: ViewIcon, title: t('actions.view'), action: 'view' },
			...(staffingGrants?.data?.stRegistration
				? [
						{ icon: EditIcon, title: t('actions.edit'), action: 'edit' },
						{ icon: RemoveIcon, title: t('actions.remove'), action: 'remove' },
						...(!isProject && !isGroup ? [{ icon: AddIcon, title: t('actions.appoint'), action: 'create' }] : []),
				  ]
				: []),
		]
		setActionMenuItems(actions)
	}

	return (
		<>
			<tr onClick={handleRowClick}>
				<td
					style={{
						paddingLeft: `${node.level * 10}px`,
					}}
					className={styles['td-cursor-pointer']}
				>
					{node.workPlaces?.length > 0 ? (
						isOpen ? (
							<img className={styles.treeView} src={StaffChevronUp} alt="minus" />
						) : (
							<img className={styles.treeView} src={StaffChevronDown} alt="plus" />
						)
					) : null}
				</td>
				<td
					style={{
						paddingLeft: `${node.level * 10 + 10}px`, // всегда добавляем дополнительный отступ
					}}
					className={`${styles['td-cursor-pointer']} ${styles['td-padding-left']}`}
				>
					{node.code}
				</td>
				<td>{lang === 'rus' ? establishedPost.nameRu : establishedPost.nameKz}</td>
				<td>{isProject ? node.staffUnitsCount : node.staffUnitsCount + '/' + node.staffFreeCount || '-'}</td>
				<td>{isGroup ? formatNumber(total.grossSalary) : node.grossSalary || '-'}</td>
				<td>{isGroup ? formatNumber(total.salarySupplement) : node.salarySupplement || '-'}</td>
				<td onClick={(e) => onProjectGroupActionMenuClick(node, e, setActionMenuItems)}>
					<DropDownMenu
						title={t('select_pls')}
						items={actionMenuItems}
						onItemClick={(mode) => onActionMenuChoose(mode, node)}
					/>
				</td>
				<td>{checkProjectErrors(node) ? renderIcon('error') : null}</td>
			</tr>
			{isOpen &&
				node.workPlaces?.length > 0 &&
				node.workPlaces.map((child) => (
					<TableGroupTreeView
						key={child.externalId}
						node={{ ...child, level: node.level + 1 }}
						setActionsGroup={setActionsGroup}
						setGroupRow={setGroupRow}
						setActionsPositions={setActionsPositions}
						setPositionRowSelectMenu={setPositionRowSelectMenu}
						isProject={isProject}
						establishedPost={node.establishedPost}
						dposition={node.dposition}
						dstaffCategory={node.dstaffCategory}
						code={node.code}
						groupId={node.externalId}
					/>
				))}
		</>
	)
}

export const TreeViewGroup = ({
	groupWorkPlace,
	setActionsGroup,
	setGroupRow,
	setActionsPositions,
	setPositionRowSelectMenu,
	isProject = true,
}) => {
	const isLoading = useSelector(staffSelectors.isLoading)
	const { t } = useTranslation()

	const dynamicHeadCells = useMemo(() => {
		return headCells.map((head) => {
			if (head.id === 'tariff_rate') {
				return {
					...head,
					label: isProject ? 'workplace.staff_unit' : 'staff.count_tariff_rate_all',
				}
			}
			return head
		})
	}, [isProject])

	const tableHeader = useMemo(
		() => (
			<>
				{dynamicHeadCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[dynamicHeadCells, t]
	)

	return (
		<table className={styles.table}>
			<thead>
				<tr>{tableHeader}</tr>
			</thead>
			<tbody>
				{!!groupWorkPlace && !isLoading ? (
					groupWorkPlace.length > 0 &&
					groupWorkPlace.map((child) => (
						<TableGroupTreeView
							key={child.externalId}
							node={{ ...child, level: 0 }}
							setActionsGroup={setActionsGroup}
							setGroupRow={setGroupRow}
							setActionsPositions={setActionsPositions}
							setPositionRowSelectMenu={setPositionRowSelectMenu}
							isProject={isProject}
							establishedPost={child.establishedPost}
							dposition={child.dposition}
							dstaffCategory={child.dstaffCategory}
							code={child.code}
							groupID={child.externalId}
						/>
					))
				) : (
					<tr>
						<td colSpan={8} className={styles.messageCol}>
							{isLoading && (
								<LoadingScreen>
									<Title>
										{t('staff.stop')} <br />
										{t('labor_records.loading')}
									</Title>
								</LoadingScreen>
							)}
							{!isLoading && !groupWorkPlace && <div className={styles.tableLoading}>{t('staff.info_no_group')}</div>}
						</td>
					</tr>
				)}
			</tbody>
		</table>
	)
}
