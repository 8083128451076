import { Loader, LoadingBlocker, LoadingScreen } from '@/components/Loader'
import { Modal, ModalBackButton } from '@/components/Modal'
import { contractsActions, contractsSelectors, contractsThunks, userSelectors } from '@/entity'
import { generateLocalEditXML } from '@/entity/contracts/model/thunks'
import { useDigitalSign } from '@/shared'
import { Button, Title } from '@/ui'
import { Add, ArrowRotate, Edit, Pencil, Prohibit, Trash } from '@app/icons'
import { contractActionsSelectors } from '@features/contracts/model'
import { Box } from '@mui/system'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DocumentState, SignStatus } from '../../badges'
import {
	AdditionalContracts,
	ContractAdditionalInfo,
	ContractInformation,
	DetailsOfPartiesInformation,
	ProlongationTable,
	SocialLeavesTable,
} from '../../info-table'
import styles from './ContractCard.module.scss'
import { ContractProject } from './ContractProject'
import TerminateButton from '@features/contracts/actions/terminate/TerminateButton'
import { ViewConfirmDocument } from '@/components/ViewDocuments/ViewConfirmDocument'
import { message } from 'antd'
import { getPaymentState } from '@/utils'
import { handbookSelectors, handbookThunks } from '@/entity/handbook'
import { ContractReviewsItem, ReviewInfoWarning } from '@/features'
import { reviewSelectors } from '@/entity/review'
import { getCurrentLanguage } from '@/i18next'

const SubTitleActionEmployeeIinUpdate = ({ onClick }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	return (
		<Box className={styles.sub__title__action} display="flex" alignItems="center">
			<ArrowRotate />
			<div onClick={onClick}>{t('contracts_tab.update_iin')}</div>
		</Box>
	)
}

export const ContractCard = ({
	onClose,
	onDelete,
	onTerminate,
	onEdit,
	onRecover,
	onProlong,
	contract,
	onAdditionalContractCreate,
	handleSocialLeavesClick,
	setSocialLeave,
	onToggleAction,
	onUpdateIin,
	forceSign,
	onEditTermination,
	onSignPackage,
	setForceSubmitEdit,
	updateList,
	onTermination,
}) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const lang = getCurrentLanguage()

	const dispatch = useDispatch()
	const { successPayload, failedPayload, onClearPayload } = useDigitalSign()
	const xml = useSelector(contractsSelectors.editXML)
	const isSuccess = useSelector(contractsSelectors.contractIsSuccess)
	const thunkIsLoading = useSelector(contractsSelectors.contractIsLoading)
	const contractListIsLoading = useSelector(contractsSelectors.isLoading)
	const error = useSelector(contractsSelectors.contractHasError)
	const activeRole = useSelector(userSelectors.activeRole)
	const isEmployee = useSelector(userSelectors.activeRole) === 'ROLE_EMPLOYEE'
	const [statusPayment, setStatusPayment] = useState('')
	const [loading, setLoading] = useState(false)
	const { data: currentEtd } = useSelector((state) => state.contracts.etd.currentEtd)
	const { data: currentEtdEdit } = useSelector((state) => state.contracts.etd.currentEtdEdit)
	const {
		data: saveProject,
		loading: saveProjectloading,
		error: saveProjectError,
	} = useSelector((state) => state.contracts.etd.saveProject)
	const {
		data: sendToEmployee,
		loading: sendToEmployeeloading,
		error: sendToEmployeeError,
	} = useSelector((state) => state.contracts.etd.sendToEmployee)
	const {
		data: declineEmployee,
		loading: declineEmployeeloading,
		error: declineEmployeeError,
	} = useSelector((state) => state.contracts.etd.declineEmployee)
	const {
		data: declineEmployer,
		loading: declineEmployerloading,
		error: declineEmployerError,
	} = useSelector((state) => state.contracts.etd.declineEmployer)
	const {
		data: sendForImprovementEmployee,
		loading: sendForImprovementEmployeeloading,
		error: sendForImprovementEmployeeError,
	} = useSelector((state) => state.contracts.etd.sendForImprovementEmployee)
	const {
		data: deleteEtdData,
		loading: deleteEtdloading,
		error: deleteEtdError,
	} = useSelector((state) => state.contracts.etd.deleteEtd)

	const reviewType = useSelector(handbookSelectors.reviewType)

	const options = useSelector(contractActionsSelectors.options)

	const { review } = useSelector(reviewSelectors.review)

	const { professionalSkills = [], personalQualities = [], contractTerms = [] } = options

	useEffect(() => {
		if (!reviewType.length) dispatch(handbookThunks.getReviewType())
	}, [])

	const onActionMenuChooseMiddleWare = (contract) => {
		let isSubmitAvaliable = true

		const specSkillsList = contract.specSkillsListFull

		for (let i = 0; i < specSkillsList.length; i++) {
			const element = specSkillsList[i]

			const fullElement = professionalSkills.find((item) => item.code == element)
			if (fullElement?.isActual == 0) {
				isSubmitAvaliable = false
				break
			}
		}

		const skillsList = contract.skillsListFull

		for (let i = 0; i < skillsList.length; i++) {
			const element = skillsList[i]

			const fullElement = personalQualities.find((item) => item.code == element)
			if (fullElement?.isActual == 0) {
				isSubmitAvaliable = false
				break
			}
		}

		if (isSubmitAvaliable) {
			setForceSubmitEdit(false)
			dispatch(generateLocalEditXML(contract.externalId))
		} else {
			setForceSubmitEdit(true)
			onToggleAction('edit', true)
		}
	}

	const signECP = useCallback(() => {
		!xml && onActionMenuChooseMiddleWare(contract)
	}, [contract.externalId, dispatch, xml])

	const onClear = () => {
		dispatch(contractsActions.resetContract())
		onClearPayload()
		onClose()
	}

	useEffect(() => forceSign && signECP(), [forceSign])
	useEffect(
		() => successPayload && dispatch(contractsThunks.sendSignedContract(successPayload, true)),
		[successPayload]
	)
	useEffect(() => failedPayload && onClear(), [failedPayload])
	useEffect(() => {
		;(isSuccess || error) && onClear()
		return () => onClear()
	}, [isSuccess, error])

	const esutdRegErrors = useSelector((state) => state.handbook.esutdRegErrors)

	const errorData = esutdRegErrors.find((item) => item.code == contract.desutdRegErrorCode)

	const unsignedAdditional = contract.additionalContracts?.filter(
		(item) => item.dsendStatusCode || item.desutdRegErrorCode
	)
	const unsignedLeaves = contract.socialLeaves?.filter((item) => item.dsendStatusCode || item.desutdRegErrorCode)

	const unsignedAdditionaOrLeaves = Boolean(unsignedAdditional?.length || unsignedLeaves?.length)

	const openEtd = async () => dispatch(contractsThunks.getEtd({ externalId: contract.externalId }))

	const handleSave = (parsedData, isSendToEmployee) =>
		dispatch(contractsThunks.saveProject(parsedData, contract, isSendToEmployee))

	const handleSendToEmployee = () => dispatch(contractsThunks.sendToEmployee({ externalId: contract.externalId }))

	const handleEditEtd = () => dispatch(contractsThunks.getEtdEdit({ externalId: contract.externalId }))

	const decline = (note = '') => dispatch(contractsThunks.declineEmployee({ externalId: contract.externalId, note }))

	const deleteEtd = () => onToggleAction('deleteEtd', true)

	const sendForImprovement = (note = '') =>
		dispatch(contractsThunks.sendForImprovementEmployee({ externalId: contract.externalId, note }))

	const handleDeclineEmployer = () => dispatch(contractsThunks.declineEmployer({ externalId: contract.externalId }))
	const handleRevertEmployer = () =>
		dispatch(contractsThunks.revertEmployer({ externalId: contract.externalId }, onClose, updateList))

	const handleAddReview = () => {
		onToggleAction('addReview', true)
	}

	useEffect(() => {}, [successPayload])

	const updateContract = (local) => {
		dispatch(contractsThunks.getContract(contract.externalId, local))
		updateList()
		openEtd()
	}

	const onContractProjectclose = () => {
		dispatch(
			contractsActions.setCurrentEtd({
				data: null,
				loading: false,
				error: null,
			})
		)
		dispatch(
			contractsActions.setCurrentEtdEdit({
				data: null,
				loading: false,
				error: null,
			})
		)
	}
	const shouldDisplayButton =
		(!contract?.econtractFound && ['1', '2', '3', '5'].includes(contract?.dcontractStatus?.code)) ||
		(contract?.econtractFound &&
			['1', '2', '3', '5'].includes(contract?.dcontractStatus?.code) &&
			!['1'].includes(contract?.dcontractState?.code))

	const getStatusPayment = async () => {
		try {
			setLoading(true)
			const response = await getPaymentState(contract.iin)
			setStatusPayment(response)
			setLoading(false)
		} catch (error) {
			setLoading(false)
			const err = error?.response?.data?.message || 'Попробуйте позже, технические проблемы на стороне сервиса'
			message.error(err ? err : 'Попробуйте позже, технические проблемы на стороне сервиса', 5)
		}
	}

	const isReviewVisibleButton = {
		ROLE_EMPLOYEE: !contract.review?.find((item) => item.reviewTypeCode === 'employeeReview'),
		ROLE_EMPLOYER: !contract.review?.find((item) => item.reviewTypeCode === 'employerReview'),
	}

	const reviewAlertType = {
		ROLE_EMPLOYEE: t('reviews.view_alert_text_employer'),
		ROLE_EMPLOYER: t('reviews.view_alert_text_employee'),
	}

	if (
		thunkIsLoading ||
		contractListIsLoading ||
		sendToEmployeeloading ||
		deleteEtdloading ||
		declineEmployeeloading ||
		sendForImprovementEmployeeloading ||
		saveProjectloading ||
		review.loading
	) {
		return <LoadingBlocker />
	}
	if (forceSign) return <></>
	return (
		<Modal show={true} windowClassName={styles.modal} onClose={onClose}>
			{currentEtd && (
				<ContractProject
					isOpen={true}
					onClose={onContractProjectclose}
					contract={contract}
					currentEtd={currentEtd}
					currentEtdEdit={currentEtdEdit}
					handleSave={handleSave}
					handleSendToEmployee={handleSendToEmployee}
					handleEditEtd={handleEditEtd}
					decline={decline}
					sendForImprovement={sendForImprovement}
					updateContract={updateContract}
					declineEmployer={handleDeclineEmployer}
					revertEmployer={handleRevertEmployer}
					deleteEtd={deleteEtd}
				/>
			)}
			<ModalBackButton onClick={onClose} />
			<div className={`${styles.wrapper} scroll`}>
				<div>
					<div className={styles.mainInformation}>
						<Title color="black">
							{t('contracts_tab.contract_short_information', {
								contractNumber: contract?.contractNumber || ' ',
								registerDate: contract?.registerDate || ' ',
							})}
						</Title>
						<div className={styles.detailInformation}>
							<DocumentState item={contract.dcontractStatus ? contract.dcontractStatus : { code: '1', nameRu: '' }} />
							<SignStatus item={contract.dcontractState ? contract.dcontractState : { code: '1', nameRu: '' }} />
						</div>
					</div>
					<p className={styles.identifier}>ID: {contract.externalId}</p>
					{!contract.desutdRegErrorCode && (
						<p className={styles.registrationDate}>
							{t('esutd_reg_date')}: {contract.entryDate}
						</p>
					)}
					{contract.desutdRegErrorCode && (
						<p className={styles.registrationDate}>
							{t('esutd_reg_date')}:{' '}
							<span className={styles.error}>
								{t('package_loading.contract_error_text', {
									errorText: errorData?.[language],
									errorNumber: errorData?.code,
								})}
							</span>
						</p>
					)}
				</div>
				{contract.externalId && contract.bin ? (
					<>
						{isEmployee && contract?.dcontractStatus?.code !== '4' && contract.econtractFound && (
							<div className={styles.actions}>
								<Button onClick={openEtd}>{t('open_etd')}</Button>
								{contract?.dcontractStatus?.code === '1' && (
									<Button
										onClick={() => onTermination(true)}
										buttonClassName={styles.button}
										textColor="grey"
										borderColor="grey"
										transparent
										border
									>
										<Prohibit className={styles.iconGrey} />
										{t('terminate_application')}
									</Button>
								)}
							</div>
						)}
						{!isEmployee && contract?.dcontractStatus?.code !== '4' && (
							<div className={styles.actions}>
								{contract.econtractFound && (
									<div className={styles.actions}>
										<Button onClick={openEtd}>{t('open_etd')}</Button>
									</div>
								)}
								{((contract.dcontractStatus && contract?.dcontractStatus?.code === '5') ||
									contract.desutdRegErrorCode) && (
									<Button onClick={signECP}>
										<Pencil className={styles.iconWhite} />
										{t('sign_button')}
									</Button>
								)}
								{unsignedAdditionaOrLeaves && !contract.econtractFound && (
									<Button onClick={onSignPackage}>
										<Pencil className={styles.iconWhite} />
										{t('sign_button_all')}
									</Button>
								)}
								{contract?.dcontractStatus?.code === '2' && (
									<Button
										onClick={onRecover}
										buttonClassName={styles.button}
										textColor="grey"
										borderColor="green"
										backgroundColor="green"
										transparent
										border
									>
										<Pencil className={styles.iconWhite} />
										<span className={styles.button__text__white}>{t('recover_button')}</span>
									</Button>
								)}

								{((contract?.econtractFound &&
									['1'].includes(contract?.dcontractState?.code) &&
									['1'].includes(contract?.dcontractStatus?.code)) ||
									((!contract?.econtractFound ||
										(contract?.econtractFound && !['1'].includes(contract?.dcontractState?.code))) &&
										['1', '5', '3'].includes(contract?.dcontractStatus?.code))) && (
									<Button
										onClick={onEdit}
										buttonClassName={styles.button}
										textColor="green"
										borderColor="green"
										transparent
										border
									>
										<Edit className={styles.iconGreen} />
										{t('edit_button')}
									</Button>
								)}
								{contract?.dcontractStatus?.code === '3' && (
									<Button
										onClick={onProlong}
										buttonClassName={styles.button}
										textColor="green"
										borderColor="green"
										transparent
										border
									>
										{t('actions.prolongation')}
									</Button>
								)}
								{['1', '3'].includes(contract?.dcontractStatus?.code) && (
									<Button
										buttonClassName={styles.button}
										textColor="green"
										borderColor="green"
										transparent
										border
										onClick={onAdditionalContractCreate}
									>
										<Add className={styles.iconGreen} />
										{contract?.econtractFound ? t('create_add_e_contract') : t('create_add_contract')}
									</Button>
								)}
								{contract?.dcontractStatus?.code === '1' && (
									<Button
										onClick={() => handleSocialLeavesClick('create')}
										buttonClassName={styles.button}
										textColor="green"
										borderColor="green"
										transparent
										border
									>
										<Add className={styles.iconGreen} />
										{t('create_social_leave')}
									</Button>
								)}
								{contract.dcontractStatus?.code !== '2' && <TerminateButton onTermination={onTerminate} />}
								{['2'].includes(contract?.dcontractStatus?.code) && (
									<Button
										onClick={onEditTermination}
										buttonClassName={styles.button}
										textColor="green"
										borderColor="green"
										transparent
										border
									>
										<Edit className={styles.iconGreen} />
										{t('actions.edit_termination')}
									</Button>
								)}
								{shouldDisplayButton && (
									<Button onClick={onDelete} buttonClassName={styles.button} type="bare">
										<Trash className={styles.iconGrey} />
										{t('remove_contract')}
									</Button>
								)}
							</div>
						)}
						{!isEmployee && (
							<>
								<div className={styles.wrapper__button}>
									<Button
										disabled={!contract?.iin}
										buttonClassName={styles.wrapper__buttonRight}
										onClick={getStatusPayment}
									>
										{t('personal_affairs.checking_social_payments')}
									</Button>
									<div className={styles.labelStatus}>
										{t('personal_affairs.status')}
										<label>{t(statusPayment)}</label>
									</div>
									{loading ? <Loader /> : null}
								</div>
							</>
						)}
						<div className={styles.informationSections}>
							<section>
								<Title>{t('parties_requisites')}</Title>
								<DetailsOfPartiesInformation
									contract={contract}
									subTitleAction={
										!isEmployee && contract?.dcontractStatus?.code === '1' ? (
											<SubTitleActionEmployeeIinUpdate onClick={onUpdateIin} />
										) : null
									}
								/>
							</section>
							<section>
								<ContractInformation contract={contract} />
							</section>
							{contract?.files?.length > 0 ? (
								<section>
									<div>
										<Title>{t('labor_records.confirming_document')}</Title>
									</div>
									<ViewConfirmDocument data={contract?.files}></ViewConfirmDocument>
								</section>
							) : null}
							{!contract?.isLocalContract ? (
								<section>
									<div className={styles.review_wrapper}>
										<div className={'mb-4'}>
											<ReviewInfoWarning title={t('attention')} text={reviewAlertType[activeRole]} />
										</div>
										<div className={styles.review__header}>
											<Title>{t('reviews.title')}</Title>
											{isReviewVisibleButton[activeRole] && (
												<Button type="bare" buttonClassName={styles.review_edit_button} onClick={handleAddReview}>
													<Edit className="me-2" />
													<span>
														{isEmployee ? t('reviews.set_review_employer') : t('reviews.set_review_employee')}
													</span>
												</Button>
											)}
										</div>

										<div className={styles.review_list}>
											{contract.review?.map((item, index) => {
												const title = {
													employerReview: lang === 'rus' ? item?.review?.orgNameRu : item?.review?.orgNameKz,
													employeeReview: item?.review?.employeeFio,
												}

												return (
													<ContractReviewsItem
														key={`${item.contractExternalId}-${index}`}
														item={item}
														onToggleAction={onToggleAction}
														title={title[item?.reviewTypeCode]}
													/>
												)
											})}
										</div>
									</div>
								</section>
							) : null}

							<section>
								<ContractAdditionalInfo contract={contract} />
							</section>
							<section>
								<Title>{t('additional_contracts')}</Title>
								<AdditionalContracts
									contracts={contract?.additionalContracts}
									onViewButtonClick={(e) => onToggleAction(e, true)}
								/>
								{!isEmployee &&
									contract?.dcontractStatus.code !== '2' &&
									contract?.dcontractState.code !== '3' &&
									contract?.dcontractState.code !== '4' &&
									contract?.dcontractState.code !== '5' &&
									contract?.dcontractState.code !== '7' &&
									contract?.dcontractState.code !== '9' &&
									!contract.econtractFound && (
										<Button
											buttonClassName={styles.buttonTable}
											textColor="green"
											borderColor="green"
											transparent
											border
											onClick={onAdditionalContractCreate}
										>
											<Add className={styles.iconGreen} />
											{t('create_add_contract')}
										</Button>
									)}
							</section>
							<section>
								<Title>{t('social_leaves')}</Title>
								<SocialLeavesTable
									data={contract?.socialLeaves}
									onViewButtonClick={handleSocialLeavesClick}
									setSocialLeave={setSocialLeave}
									parentContract={contract}
								/>
								{!isEmployee && contract?.dcontractStatus.code !== '2' && contract?.dcontractStatus.code !== '4' && (
									<Button
										onClick={() => handleSocialLeavesClick('create')}
										buttonClassName={styles.buttonTable}
										textColor="green"
										borderColor="green"
										transparent
										border
									>
										<Add className={styles.iconGreen} />
										{t('create_social_leave')}
									</Button>
								)}
							</section>
							<section>
								<Title>{t('auto_prolongation')}</Title>
								<ProlongationTable data={contract?.prolongations} contractTerms={contractTerms} />
								{!isEmployee ? (
									<Button
										onClick={onProlong}
										buttonClassName={styles.buttonTable}
										textColor="green"
										borderColor="green"
										transparent
										border
										disabled={contract?.dcontractStatus?.code !== '3'}
									>
										<Add className={styles.iconGreen} />
										{t('actions.prolongation')}
									</Button>
								) : null}
							</section>
							<div className={styles.footer}>
								<Button buttonClassName={styles.button} onClick={onClose}>
									{t('close_contract')}
								</Button>
							</div>
						</div>
					</>
				) : (
					<LoadingScreen>
						<Title>
							{t('wait')} <br />
							{t('loading_from_esutd')}
						</Title>
					</LoadingScreen>
				)}
			</div>
		</Modal>
	)
}
