import * as Yup from 'yup'
import { number, string } from 'yup'

export const ValidationSchema = (edit) =>
	Yup.object().shape({
		nameKz: Yup.string()
			.when('isDicPosition', {
				is: (isDicPosition) => isDicPosition === '2',
				then: Yup.string().required({ key: 'required' }),
				otherwise: Yup.string().notRequired().nullable(),
			})
			.nullable(),

		nameRu: Yup.string()
			.when('isDicPosition', {
				is: (isDicPosition) => isDicPosition === '2',
				then: Yup.string().required({ key: 'required' }),
				otherwise: Yup.string().notRequired().nullable(),
			})
			.nullable(),

		destablishedPost: Yup.string()
			.when('isDicPosition', {
				is: (isDicPosition) => isDicPosition === '3',
				then: Yup.string().required({ key: 'required' }).nullable(),
				otherwise: Yup.string().notRequired().nullable(),
			})
			.nullable(),
		dposition: string().required({ key: 'required' }).nullable(),
		dstaffCategory: string().required({ key: 'required' }).nullable(),
		workplaceCount: number().when([], {
			is: () => edit, // Условие проверки флага edit
			then: number().notRequired().nullable(),
			otherwise: number()
				.required({ key: 'required' })
				.positive()
				.integer({ key: 'staff.enter_integer' })
				.max(300, { key: 'staff.max_value' }) // Задаем ограничение на максимум
				.nullable(),
		}),
		grossSalary: number().when([], {
			is: () => edit, // Условие проверки флага edit
			then: number().notRequired().nullable(), // Необязательное поле при edit
			otherwise: number()
				.required({ key: 'required' }) // Обязательное поле
				.integer({ key: 'staff.enter_integer' })
				.min(1, { key: 'staff.min_value' })
				.nullable(),
		}),
	})
