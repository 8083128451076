import React from 'react'

export const CancelCircle = ({ styles, className = '', fill = "none" }) => {
	return (
		<svg width="12" height="13" viewBox="0 0 12 13" fill={fill} style={styles} className={className} xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_20389_15944)">
				<path d="M6 12.5C9.30823 12.5 12 9.80847 12 6.5C12 4.896 11.3762 3.38904 10.2435 2.25647C9.11072 1.12378 7.604 0.5 6 0.5C2.69153 0.5 0 3.19153 0 6.5C0 8.104 0.623779 9.61096 1.75647 10.7435C2.88904 11.8762 4.396 12.5 6 12.5ZM6 11.5C4.83154 11.5 3.72498 11.1028 2.83447 10.3726L9.87256 3.33447C10.6028 4.22498 11 5.33154 11 6.5C11 9.25696 8.75696 11.5 6 11.5ZM6 1.5C7.16821 1.5 8.27502 1.89722 9.16528 2.62744L2.12744 9.66553C1.39722 8.77502 1 7.66846 1 6.5C1 3.74304 3.24304 1.5 6 1.5Z" fill="#EB5757" />
			</g>
			<defs>
				<clipPath id="clip0_20389_15944">
					<rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
				</clipPath>
			</defs>
		</svg>

	)
}
