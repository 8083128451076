import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import styles from './TableSign.module.scss'
import { headCells } from './table.headings'
import { Dots, Success, CrossRounded } from '@app/icons'

const STATUS_ENUM = {
	PENDING: 'PENDING',
	APPROVED: 'APPROVED',
	REJECTED: 'REJECTED',
	SENDING: 'SENDING',
	ERROR: 'ERROR',
	PENDING_PROJECT: 'PENDING_PROJECT',
	COMPLETED: 'COMPLETED',
}

const StatusRender = ({
												status = STATUS_ENUM.PENDING,
												text
											}) => {
	const renderIcon = {
		[STATUS_ENUM.PENDING]: <Dots className={styles.iconClock} />,
		[STATUS_ENUM.APPROVED]: <Success className={styles.iconSuccess} />,
		[STATUS_ENUM.REJECTED]: <CrossRounded className={styles.iconReject} />,
	}

	const className = {
		[STATUS_ENUM.PENDING]: styles.textClock,
		[STATUS_ENUM.APPROVED]: styles.textSuccess,
		[STATUS_ENUM.REJECTED]: styles.textReject,
	}

	return (
		<>
			{renderIcon[status]}

			<span className={className[status] || styles.textClock}>
				{text}
			</span>
		</>
	)
}

const TableSign = ({ currentEcd }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()

	const statusUnion = currentEcd.signUnionDate ? <StatusRender status={STATUS_ENUM.APPROVED} text={t('signed_status')}/> : <StatusRender status={STATUS_ENUM.PENDING} text={t('pending_sign')}/>

	const statusEmployer = currentEcd.signEmployerDate ? <StatusRender status={STATUS_ENUM.APPROVED} text={t('signed_status')}/> : <StatusRender status={STATUS_ENUM.PENDING} text={t('pending_sign')}/>

	const statusOtib = {
		[STATUS_ENUM.PENDING]: <StatusRender status={STATUS_ENUM.PENDING} text={t('ecollective_contract.pending_status')}/> ,
		[STATUS_ENUM.COMPLETED]: <StatusRender status={STATUS_ENUM.APPROVED} text={t('ecollective_contract.approved_status')}/>,
		[STATUS_ENUM.APPROVED]: <StatusRender status={STATUS_ENUM.APPROVED} text={t('ecollective_contract.approved_status')}/>,
		[STATUS_ENUM.REJECTED]: <StatusRender status={STATUS_ENUM.REJECTED} text={t('ecollective_contract.rejected_status')}/>,
	}

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t],
	)

	const data = useMemo(() => ([
		{
			role: t('col_contract.trade_union_representative'),
			iin: currentEcd.unionSignIin,
			fio: currentEcd.unionSignFullName,
			signData: currentEcd.signUnionDate,
			statusSignEec: statusUnion,
		},
		{
			role: t('col_contract.employer_representative'),
			iin: currentEcd.employerSignIin,
			fio: currentEcd.employerSignFullName,
			signData: currentEcd.signEmployerDate,
			statusSignEec: statusEmployer,
		},
		{
			role: t('ecollective_contract.labor_inspectorate_officer'),
			iin: currentEcd?.otibStatus?.otibInspector?.inspectorIin,
			fio: currentEcd?.otibStatus?.otibInspector?.inspectorFio,
			signData: currentEcd?.otibStatus?.statusDate,
			statusSignEec: statusOtib[currentEcd?.otibStatus?.statusEnum] || (
				<StatusRender status={STATUS_ENUM.PENDING} text={t('ecollective_contract.pending_status')}/>
			),
		},
	]), [currentEcd, t])

	return (
		<>
			<div className={styles.status__title}>{t('col_contract.status_sign')}</div>
			<table className={styles.table}>
				<thead>
				<tr>{tableHeader}</tr>
				</thead>
				<tbody>
				{data.map(item => (
					<tr key={item.role}>
						{headCells.map(key => (
							<td key={key.id}>
								{item[key.id] || '-'}
							</td>
						))}
					</tr>
				))}
				</tbody>
			</table>
		</>
	)
}

export default TableSign
