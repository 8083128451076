import React, { useEffect, useState } from 'react'
import { Form as FormikForm, Field as FormikField, useFormikContext } from 'formik'
import styles from './GroupWorkPlaceForm.module.scss'
import { formFieldNames } from './formFieldNames'
import { WrappedCheckbox } from '@features/contracts/registrationEmployerContract/form/fields'
import { Button, Field } from '@/ui'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { contractActionsSelectors } from '@features/contracts/model'
import { formatNumber } from '@/utils'

export const GroupWorkPlaceLmcForm = ({ edit, onClose, actualStaffing }) => {
	const { t } = useTranslation()
	const { setFieldValue, values } = useFormikContext()
	const { positions, positionsByKnz, establishedPostPositionCategoryRelation, laborMobilityCenterCategory } =
		useSelector(contractActionsSelectors.options)
	const [filteredPositionsByKnz, setFilteredPositionsByKnz] = useState(positionsByKnz)
	const [filteredLaborMobilityCenterCategory, setFilteredLaborMobilityCenterCategory] =
		useState(laborMobilityCenterCategory)

	useEffect(() => {
		if (filteredPositionsByKnz.length === 1) {
			setFieldValue('dposition', filteredPositionsByKnz[0]?.code)
		}
		if (filteredLaborMobilityCenterCategory.length === 1) {
			setFieldValue('dstaffCategory', filteredLaborMobilityCenterCategory[0]?.code)
			setFieldValue('isDicPosition', '3')
		}
	}, [filteredPositionsByKnz, filteredLaborMobilityCenterCategory, setFieldValue])

	const [inputValues, setInputValues] = useState({
		grossSalary: '',
		salarySupplement: '',
	})

	const handleInputChange = (fieldName) => (event) => {
		let value = event.target.value
		if (value && !inputValues[fieldName]) {
			setInputValues({ ...inputValues, [fieldName]: value })
		} else {
			const onlyNumbers = value.replace(/\D/g, '')
			const numberWithSpaces = formatNumber(onlyNumbers)
			setInputValues({ ...inputValues, [fieldName]: numberWithSpaces })
			setFieldValue(fieldName, onlyNumbers)
		}
	}

	const onPositionChange = (code) => {
		setFieldValue('dposition', null)
		setFieldValue('dstaffCategory', null)
		if (!code) {
			setFieldValue('dposition', null)
			return
		}
		setFieldValue('destablishedPost', code)
		const eppcr = establishedPostPositionCategoryRelation.filter((rel) => rel.establishedPostCode === code)
		setFilteredPositionsByKnz(positionsByKnz.filter((item) => eppcr.some((x) => x.positionCode === item.code)))
		setFilteredLaborMobilityCenterCategory(
			laborMobilityCenterCategory.filter((item) => eppcr.some((x) => x.laborMobilityCenterCategoryCode === item.code))
		)
	}

	return (
		<FormikForm className={styles.form}>
			<section>
				<div className={styles.item}>
					<div className={`${styles.fields} ${styles.fields_verticalCenter}`}>
						<div className={styles.label}>
							<FormikField name={formFieldNames.freelance}>
								{({ field }) => (
									<WrappedCheckbox
										{...field}
										label={t('staff_modal.freelancer_position')}
										disabled={edit && !actualStaffing}
									/>
								)}
							</FormikField>
						</div>
					</div>
					<FormikField name={formFieldNames.destablishedPost}>
						{({ field, meta: { touched, error } }) => (
							<Field
								{...field}
								fieldType="selectSearch"
								label={t('contract.info.position')}
								hint={t('hint_position')}
								placeholder={t('position_placeholder')}
								options={positions.filter((item) => item.lmc)}
								error={touched && error}
								isRequired
								onChange={onPositionChange}
							/>
						)}
					</FormikField>
					<FormikField name={formFieldNames.dposition}>
						{({ field, meta: { touched, error } }) => (
							<Field
								{...field}
								fieldType="selectSearch"
								label={t('contract.info.nkz_position')}
								hint={t('hint_position_knz')}
								placeholder={t('choose_from_directory')}
								options={filteredPositionsByKnz}
								error={touched && error}
								isRequired
								disabled={edit || (!edit && filteredPositionsByKnz.length === 1) || (!edit && !values.destablishedPost)}
							/>
						)}
					</FormikField>
					<FormikField name={formFieldNames.dstaffCategory}>
						{({ field, meta: { touched, error } }) => (
							<Field
								{...field}
								fieldType="select"
								label={t('staff_modal.position_category')}
								placeholder={t('choose_from_directory')}
								options={filteredLaborMobilityCenterCategory}
								error={touched && error}
								isRequired
								disabled={
									edit ||
									(!edit && filteredLaborMobilityCenterCategory.length === 1) ||
									(!edit && !values.destablishedPost)
								}
							/>
						)}
					</FormikField>
					{!edit ? (
						<div className={`${styles.fields} ${styles.threeFields}`}>
							<FormikField name={formFieldNames.workplaceCount}>
								{({ field, meta: { touched, error } }) => (
									<Field
										{...field}
										type="number"
										hideNumberArrows
										placeholder={t('staff.staff_units_placeholder')}
										error={touched && error}
										label={t('workplace.tariff_rate')}
										disabled={edit}
										isRequired={!edit}
									/>
								)}
							</FormikField>
							<FormikField name={formFieldNames.grossSalary}>
								{({ field, meta: { touched, error } }) => (
									<Field
										type="text"
										hideNumberArrows
										fieldType={'inputNumber'}
										label={t('staffing_table.salary')}
										placeholder={t('staff_modal.salary_placeholder')}
										error={touched && error}
										value={inputValues.grossSalary || field.value}
										onChange={handleInputChange(formFieldNames.grossSalary)}
										autoComplete="off"
										disabled={edit}
										isRequired={!edit}
										{...field}
									/>
								)}
							</FormikField>
							<FormikField name={formFieldNames.salarySupplement}>
								{({ field, meta: { touched, error } }) => (
									<Field
										type="text"
										fieldType={'inputNumber'}
										hideNumberArrows
										label={t('staffing_table.supplement_surcharge')}
										placeholder={t('staff_modal.supplement_surcharge_placeholder')}
										error={touched && error}
										value={inputValues.salarySupplement || field.value}
										onChange={handleInputChange(formFieldNames.salarySupplement)}
										autoComplete="off"
										disabled={edit}
										{...field}
									/>
								)}
							</FormikField>
						</div>
					) : null}
					{edit ? null : (
						<p>
							{t('workplace.count_tariff_rate')}
							<i>{' - ' + t('staff.staff_units_count_hint')}</i>
						</p>
					)}
				</div>
			</section>
			<div className={styles.actions}>
				<Button type="bare" onClick={onClose}>
					{t('cancel')}
				</Button>
				<Button textColor="green" borderColor="green" transparent border type="submit">
					{t('save')}
				</Button>
			</div>
		</FormikForm>
	)
}
