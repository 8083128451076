import { transformStringDateFormatToDate } from '@/shared/lib/helpers/transformStringDateFormatToDate.js'
import { IINValidationScheme } from '@/shared/validations/shemes/iin.js'
import { countriesIds, formates, workingTimeMode } from '@app/config'
import { format, isAfter, subYears } from 'date-fns'
import { addMethod, date, object, string } from 'yup'
import * as Yup from 'yup'
import dayjs from 'dayjs'

const validityTermIdsWhenEndContractDateIsRequired = [2, 3, 6, 9]
const citiesInRegionsCodes = ['750000000', '710000000', '790000000']

addMethod(string, 'checkCountryRemoteWork', function () {
	return this.when(['dremoteWorkCode', 'dcountryCode'], {
		is: (remoteWork, country) => remoteWork !== '1' && +country === countriesIds.kz,
		then: (schema) => schema.required({ key: 'required' }),
	})
})

addMethod(date, 'minDateBefore', function (params) {
	const { error, yearsBefore } = params
	const currentDate = new Date()
	const dateBefore = subYears(currentDate, yearsBefore)
	return this.test('minDateBefore', error, function (value) {
		const { path, createError } = this
		return isAfter(dateBefore, value)
			? createError({ path, message: { key: error, value: format(dateBefore, formates.date), minDate: dateBefore } })
			: true
	})
})

const FormValidationSchema = object().shape({
	fullName: string().required({ key: 'required' }).nullable(),
	contractNumber: string().required({ key: 'required' }).nullable(),
	dcontractDateCode: string().required({ key: 'required' }).nullable(),
	dpositionCode: string().required({ key: 'required_nkz' }).nullable(),
	foreignLaborInfo: object({
		nonResidentPermitNumber: string()
			.nullable()
			.test('nonResidentPermitNumber', { key: 'required_nc' }, function (val, { options }) {
				return !(
					this.parent?.dnonResidentAttractingBasisCode &&
					this.parent?.dnonResidentAttractingBasisCode.includes('001-') &&
					!Boolean(options.originalValue)
				)
			}),
	}),
	dworkingConditionsCode: string().required({ key: 'required' }).nullable(),
	registerDate: transformStringDateFormatToDate()
		.nullable()
		.required({ key: 'required' })
		.minDateBefore({ yearsBefore: 50, error: 'labor.date.min' }),
	dateFrom: transformStringDateFormatToDate().nullable().minDateBefore({ yearsBefore: 50, error: 'labor.date.min' }),
	dateTo: transformStringDateFormatToDate()
		.nullable()
		.when('dateFrom', (startDate) => {
			if (startDate) {
				return transformStringDateFormatToDate()
					.min(new Date(startDate.getTime() + 86400000), { key: 'date.end_before_start' })
					.nullable()
			}
		})
		.when('dcontractDateCode', {
			is: (validityTerm) => validityTermIdsWhenEndContractDateIsRequired.includes(Number(validityTerm)),
			then: (schema) => schema.required({ key: 'required' }),
		}),
	dpositionCodeList1: string()
		.nullable()
		.when('requiredList1', {
			is: true, // выполнить проверку, если requiredList1 === true
			then: (schema) => schema.required({ key: 'required' }),
		}),

	dpositionCodeList2: string()
		.nullable()
		.when('requiredList2', {
			is: true, // выполнить проверку, если requiredList2 === true
			then: (schema) => schema.required({ key: 'required' }),
		}),
	dpositionCodeList3: string()
		.nullable()
		.when('requiredList3', {
			is: true, // выполнить проверку, если requiredList3 === true
			then: (schema) => schema.required({ key: 'required' }),
		}),
	establishedPost: string().required({ key: 'required' }).nullable(),
	dpartTimeCode: string().required({ key: 'required' }).nullable(),
	dworkingHoursCode: string().required({ key: 'required' }).nullable(),
	dcountryCode: string()
		.nullable()
		.when('dremoteWorkCode', {
			is: '1',
			otherwise: (schema) => schema.required({ key: 'required' }),
		}),
	ddistrictCode: string().nullable().checkCountryRemoteWork(),
	dregionCode: string().nullable().checkCountryRemoteWork(),
	dlocalityCode: string()
		.nullable()
		.when(['dremoteWorkCode', 'ddistrictCode', 'dcountryCode'], {
			is: (remoteWork, district, country) =>
				remoteWork === '1' || citiesInRegionsCodes.includes(district) || +country !== countriesIds.kz,
			otherwise: (schema) => schema.required({ key: 'required' }),
		}),
	workingPlace: string()
		.nullable()
		.when(['dremoteWorkCode', 'dcountryCode'], {
			is: (remoteWork, country) => (remoteWork === '1' && country) || (remoteWork && remoteWork !== '1'),
			then: (schema) => schema.required({ key: 'required' }),
		}),
	tariffRate: string()
		.nullable()
		.when('dworkingHoursCode', {
			is: (workinMode) => Number(workinMode) === workingTimeMode.partTime,
			then: (schema) => schema.required({ key: 'required' }),
		}),
	dremoteWorkCode: string().required({ key: 'required' }).nullable(),
})
export const ValidationSchema = FormValidationSchema.concat(IINValidationScheme)
