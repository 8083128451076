export function deleteFromChildWorkPlaces(
	childs,
	structureExternalId,
	positionGroupExternalId,
	deleteWorkPlaceExternalId
) {
	const updatedChilds = childs.map((child) => {
		// Если узел соответствует заданному structureExternalId
		if (child.externalId === structureExternalId) {
			// Обновляем группы должностей
			const updatedPositionGroups = child.positionGroups
				?.map((group) => {
					if (group.externalId === positionGroupExternalId) {
						// Удаляем рабочее место из группы
						const updatedWorkPlaces = group.workPlaces.filter(
							(workPlace) => workPlace.externalId !== deleteWorkPlaceExternalId
						)

						// Если после удаления рабочих мест не осталось, возвращаем null вместо группы
						if (updatedWorkPlaces.length === 0) {
							return null
						}

						// Уменьшаем staffFreeCount на единицу, если оно не равно нулю
						const updatedStaffFreeCount = group.staffFreeCount > 0 ? group.staffFreeCount - 1 : 0

						// Возвращаем обновлённую группу
						return {
							...group,
							workPlaces: updatedWorkPlaces,
							staffUnitsCount: updatedWorkPlaces.length, // Пересчитываем количество рабочих мест
							staffFreeCount: updatedStaffFreeCount, // Обновляем staffFreeCount
						}
					}
					return group // Остальные группы возвращаем без изменений
				})
				// Убираем группы, которые были помечены как null
				.filter((group) => group !== null)

			// Возвращаем обновлённый узел структуры
			return {
				...child,
				positionGroups: updatedPositionGroups,
			}
		}

		// Обрабатываем вложенные childs рекурсивно
		if (child.childs) {
			return {
				...child,
				childs: deleteFromChildWorkPlaces(
					child.childs,
					structureExternalId,
					positionGroupExternalId,
					deleteWorkPlaceExternalId
				),
			}
		}

		// Если узел не подходит, возвращаем его без изменений
		return child
	})

	return updatedChilds
}
