import styles from './StaffingInformation.module.scss'

export const StaffingInformation = ({ currVersion, orderNumber, orderDate, t }) => {
	if (!currVersion) return null

	return (
		<div className={styles.information}>
			<div className={styles.tableText}>
				{`${t('staff.version')}${currVersion}, ${t('staff_modal.order_number')}: ${orderNumber}, ${t(
					'staff_modal.order_date'
				)}: ${orderDate}`}
			</div>
		</div>
	)
}
