import { request } from '@/shared/api/request'

export const removeActualStructure = (externalId) => {
	let params = { externalId: externalId }
	return request({ method: 'POST', url: 'staff/editActual/structure/del/xml', params: params })
}

export const sendActualStructure = (data) => {
	let payload = { data: data }
	return request({ method: 'POST', url: 'staff/editActual/structure/del/sign', data: payload })
}
