import * as types from './types'

export const actions = {
	getDataAction: (payload) => ({
		type: types.GET_DATA,
	}),

	getDataActionDone: (payload) => ({
		type: types.GET_DATA_DONE,
		payload,
	}),

	getDataActionFail: (payload) => ({
		type: types.GET_DATA_FAIL,
	}),
}
