export function updateStructure(childs, targetId, newData) {
	return childs.map((child) => {
		if (child.externalId === targetId) {
			return { ...child, ...newData } // возвращаем обновленный объект
		} else if (child.childs) {
			return { ...child, childs: updateStructure(child.childs, targetId, newData) } // обновляем вложенные childs
		} else {
			return child // возвращаем оригинальный объект для всех остальных элементов
		}
	})
}
