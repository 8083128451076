import React from 'react'
import { Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import { ECollectiveForm } from './ECollectiveForm'
import { Formik } from 'formik'
import { EColContractValidationSchema } from '@/entity/eCollectiveContracts'


export const ECollectiveContractForm = ({ isEmployee,isEdit,tradeUnion, formRef,attachProjectFile, initialValues, onSubmit, options, onCancel }) => {
	const { t } = useTranslation()

	const onSubmitMiddleWare = (data) => {
		onSubmit(data)
	}

	return (
		<div>
			<Title color={'black'}>{t('contracts_tab.conclude_collective_contract')}</Title>
			<Formik
				validationSchema={EColContractValidationSchema(t)}
				initialValues={initialValues}
				onSubmit={onSubmitMiddleWare}
				innerRef={formRef}
			>
				<ECollectiveForm tradeUnion={tradeUnion} isEdit={isEdit} attachProjectFile={attachProjectFile} isEmployee={isEmployee} options={options} onCancel={onCancel}></ECollectiveForm>
			</Formik>
		</div>
	)
}
