export function updateGroupWorkPlaces(data, structureExternalId, posGroupExternalId, editGroupWorkPlaceResponse) {
	function recursiveUpdate(childs) {
		for (let i = 0; i < childs.length; i++) {
			const child = childs[i]
			const newChild = { ...child }

			// Проверяем соответствие идентификатора структуры
			if (newChild.externalId === structureExternalId && newChild.positionGroups) {
				// Ищем и обновляем группу по posGroupExternalId
				const groupIndex = newChild.positionGroups.findIndex((group) => group.externalId === posGroupExternalId)

				if (groupIndex !== -1) {
					// Обновление данных группы на основе editGroupWorkPlaceResponse
					newChild.positionGroups[groupIndex] = {
						...newChild.positionGroups[groupIndex],
						...editGroupWorkPlaceResponse,
					}

					// Возвращаем обновленный список childs
					return [...childs.slice(0, i), newChild, ...childs.slice(i + 1)]
				}
			}

			if (newChild.childs) {
				const updatedChilds = recursiveUpdate(newChild.childs)
				if (updatedChilds !== newChild.childs) {
					// Значит обновление произошло в поддереве
					newChild.childs = updatedChilds

					return [...childs.slice(0, i), newChild, ...childs.slice(i + 1)]
				}
			}
		}

		return childs
	}

	return recursiveUpdate(data)
}
