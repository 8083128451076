import { handbookThunks } from '@/entity/handbook'
import { staffActions } from '@/entity/staffingProject'
import { api, getFromLocalStorage } from '@/shared'
import { message } from 'antd'
import {
	updateStructure,
	updateGroupWorkPlaces,
	updateEditChildWorkPlaces,
	deleteFromChildWorkPlaces,
	removeGroupByExternalId,
} from '@/utils'

function createGroupWorkPlaces(childs, structureExternalId, newGroupWorkPlace) {
	let isUpdated = false // Флаг, чтобы отслеживать изменения

	const updatedChilds = childs.map((child) => {
		if (child.externalId === structureExternalId) {
			// Обновляем positionGroups, если нашли совпадение
			isUpdated = true
			const groupWorkPlacesUpdated = [...(child.positionGroups || []), newGroupWorkPlace]
			return { ...child, positionGroups: groupWorkPlacesUpdated }
		}
		if (child.childs) {
			// Рекурсивно обрабатываем дочерние элементы
			const updatedNestedChilds = createGroupWorkPlaces(child.childs, structureExternalId, newGroupWorkPlace)
			if (updatedNestedChilds !== child.childs) {
				isUpdated = true
				return { ...child, childs: updatedNestedChilds }
			}
		}
		return child
	})

	// Если изменений не произошло, возвращаем оригинальные childs
	return isUpdated ? updatedChilds : childs
}

export const getOptions = () => {
	return (dispatch, getState) => {
		const {
			staffType,
			staffStatus,
			staffEmpStatus,
			esutdRegErrors,
			positions,
			positionsByKnz,
			laborMobilityCenterCategory,
			establishedPostPositionCategoryRelation,
			districts,
		} = getState().handbook
		if (positions.length < 1) dispatch(handbookThunks.getPositions())
		if (laborMobilityCenterCategory.length < 1) dispatch(handbookThunks.getCenterCategory())
		if (establishedPostPositionCategoryRelation.length < 1) dispatch(handbookThunks.getCategoryRelation())
		if (staffType.length < 1) dispatch(handbookThunks.getStaffDeptType())
		if (staffStatus.length < 1) dispatch(handbookThunks.getStaffStatus())
		if (staffEmpStatus.length < 1) dispatch(handbookThunks.getStaffEmpStatus())
		if (esutdRegErrors.length < 1) dispatch(handbookThunks.esutdRegErrors())
		if (positionsByKnz.length < 1) dispatch(handbookThunks.getPositionsByKnz())
		if (districts.length < 1) dispatch(handbookThunks.getDistricts())
	}
}

export const getProject = () => {
	return async (dispatch) => {
		try {
			dispatch(staffActions.toggleLoading(true))
			let body = {}
			const staffProject = await api.staff.getProject(body)
			dispatch(staffActions.setStaffProject(staffProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка получения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const getStatusProject = (payload) => {
	return async (dispatch) => {
		try {
			let body = {
				params: {
					...payload,
				},
			}
			dispatch(staffActions.toggleLoading(true))
			const statusProject = await api.staff.getStatusProject(body)
			dispatch(staffActions.setStatus(statusProject.statusCode))
			if (statusProject.statusCode === '3') {
				dispatch(staffActions.setStaffProject(statusProject.staffOrgDto))
			}
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
		} catch (error) {
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка получения статуса:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const createProject = (project) => {
	return async (dispatch) => {
		try {
			let body = {
				data: project,
			}

			dispatch(staffActions.toggleLoading(true))
			const createdProject = await api.staff.createProject(body)
			dispatch(staffActions.setStaffProject(createdProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Проект штатного расписания успешно создан!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка сохранения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const editProject = (project) => {
	return async (dispatch, getState) => {
		try {
			let body = {
				data: project,
			}

			dispatch(staffActions.toggleLoading(true))

			const response = await api.staff.editProject(body)

			if (Object.keys(response).length !== 0) {
				throw new Error(response)
			}

			const { staff } = getState()

			const updatedProject = {
				...staff.staff.data,
				note: project.note,
				orderNumber: project.orderNumber,
				orderDate: project.orderDate,
				// Заменяем объект dept из project.dept в первом элементе childs
				childs:
					staff.staff.data.childs.length > 0
						? [
								{
									...staff.staff.data.childs[0],
									dept: project.dept,
								},
								...staff.staff.data.childs.slice(1),
						  ]
						: [],
			}

			// Используем updatedProject для обновления состояния Redux
			dispatch(staffActions.setStaffProject(updatedProject))

			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Проект штатного расписания успешно изменён!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка изменения данных: ' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const delProject = () => {
	return async (dispatch) => {
		try {
			let body = {}
			dispatch(staffActions.toggleLoading(true))
			const deletedProject = await api.staff.removeProject(body)
			dispatch(staffActions.setStaffProject(deletedProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Проект штатного расписания успешно удален!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка удаления данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const addStructure = (structure) => {
	return async (dispatch) => {
		try {
			let body = {
				data: structure,
			}
			dispatch(staffActions.toggleLoading(true))
			const addedStructure = await api.staff.addStructure(body)
			dispatch(staffActions.setStaffProject(addedStructure))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Структура успешно добавлена!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка добавления данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const editStructure = (structure) => {
	return async (dispatch, getState) => {
		try {
			let body = {
				data: structure,
			}
			dispatch(staffActions.toggleLoading(true))
			const editStructureResponse = await api.staff.editStructure(body)
			// Проверка на ошибку в editStructure
			if (Object.keys(editStructureResponse).length !== 0) {
				throw new Error(editStructureResponse)
			}
			const { staff } = getState()
			// функция обновления проекта
			const updatedProject = {
				...staff.staff.data,
				childs: staff.staff.data.childs.map((child) => ({
					...child,
					childs: updateStructure(child.childs, structure.row.externalId, structure.row),
				})),
			}
			dispatch(staffActions.setStaffProject(updatedProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Структура успешно изменена!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка изменения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const delStructure = (deleteStructure) => {
	return async (dispatch) => {
		try {
			let body = {
				data: deleteStructure,
			}
			dispatch(staffActions.toggleLoading(true))
			const staffProject = await api.staff.removeStructure(body)
			dispatch(staffActions.setStaffProject(staffProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Структура успешно удалена!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка удаления данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const createGroupWorkPlace = (groupWorkPlace) => {
	return async (dispatch, getState) => {
		try {
			let body = {
				data: groupWorkPlace,
			}
			dispatch(staffActions.toggleLoading(true))
			const addedGroupWorkPlace = await api.staff.createGroupWorkPlace(body)
			const { staff } = getState()
			const updatedProject = {
				...staff.staff.data,
				childs: createGroupWorkPlaces(staff.staff.data.childs, groupWorkPlace.structureExternalId, addedGroupWorkPlace),
			}
			dispatch(staffActions.setStaffProject(updatedProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Группа профессий успешно добавлена!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка добавления данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const editGroupWorkPlace = (groupWorkPlace, activeRowId) => {
	return async (dispatch, getState) => {
		try {
			let body = {
				data: groupWorkPlace,
			}
			dispatch(staffActions.toggleLoading(true))
			const editGroupWorkPlaceResponse = await api.staff.editGroupWorkPlace(body)
			const { staff } = getState()
			// функция обновления проекта
			const updatedProject = {
				...staff.staff.data,
				childs: updateGroupWorkPlaces(
					staff.staff.data.childs,
					activeRowId,
					groupWorkPlace.posGroupExternalId,
					editGroupWorkPlaceResponse
				),
			}
			dispatch(staffActions.setStaffProject(updatedProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Должность успешно изменена!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка изменения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const delGroupWorkPlace = (staffPositionGroupExternalId) => {
	return async (dispatch, getState) => {
		try {
			const activeStaffBranch = await getFromLocalStorage('activeStaffBranch')

			let params = {
				staffPositionGroupExternalId: staffPositionGroupExternalId,
				currentStaffBranchBin: activeStaffBranch,
			}

			dispatch(staffActions.toggleLoading(true))
			const response = await api.staff.deleteGroupWorkPlace(params)

			const { staff } = getState()

			const updatedProject = {
				...staff.staff.data,
				// Здесь мы используем нашу функцию удаления
				childs: removeGroupByExternalId(staff.staff.data.childs, staffPositionGroupExternalId),
			}

			dispatch(staffActions.setStaffProject(updatedProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Группа должностей успешно удалена!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка удаления данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const editWorkPlace = (workplace, structureExternalId) => {
	return async (dispatch, getState) => {
		try {
			let body = {
				data: workplace,
			}
			dispatch(staffActions.toggleLoading(true))
			const editWorkplaceResponse = await api.staff.editWorkPlace(body)
			// Проверка на ошибку в editWorkPlace
			if (Object.keys(editWorkplaceResponse).length !== 0) {
				throw new Error(editWorkplaceResponse)
			}
			const { staff } = getState()

			// функция обновления проекта
			const updatedProject = {
				...staff.staff.data,
				childs: updateEditChildWorkPlaces(
					staff.staff.data.childs,
					structureExternalId,
					workplace.positionGroupExternalId,
					workplace.row
				),
			}
			dispatch(staffActions.setStaffProject(updatedProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Рабочее место успешно изменено!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка изменения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const delWorkPlace = (deleteWorkPlace, structureExternalId) => {
	return async (dispatch, getState) => {
		try {
			const body = {
				data: deleteWorkPlace,
			}

			dispatch(staffActions.toggleLoading(true))
			const response = await api.staff.removeWorkPlace(body)

			if (Object.keys(response).length !== 0) {
				throw new Error(response)
			}

			const { staff } = getState()

			// Пересчёт структуры после удаления рабочего места
			const updatedChilds = deleteFromChildWorkPlaces(
				staff.staff.data.childs,
				structureExternalId,
				deleteWorkPlace.positionGroupExternalId,
				deleteWorkPlace.delExternalIds[0]
			)

			// Создаём обновлённый объект данных
			const updatedProject = {
				...staff.staff.data,
				childs: updatedChilds, // Структура с пересчитанными значениями
			}

			dispatch(staffActions.setStaffProject(updatedProject))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Рабочее место успешно удалено!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error('Ошибка при удалении. ' + (error?.response?.data?.message || 'Неизвестная ошибка'), 10)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const getXML = () => {
	return async (dispatch) => {
		try {
			dispatch(staffActions.toggleLoading(true))
			let body = {}
			const xmlData = await api.staff.getXml(body)
			dispatch(staffActions.setStaffXml(xmlData))
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка получения данных для подписи:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
		}
	}
}

export const sendSignedStaff = (data) => {
	return async (dispatch) => {
		try {
			dispatch(staffActions.toggleLoading(true))
			let body = {
				data: { data },
			}
			const signedStaff = await api.staff.sendSignedStaff(body)
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleSuccess(true))
			message.success('Подписанное штатное расписания успешно отправлено!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			message.error(
				'Ошибка отправки данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffActions.toggleSuccess(false))
			dispatch(staffActions.setStaffXml(null))
		}
	}
}
