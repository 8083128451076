import React, { useEffect, useMemo, useState } from 'react'
import { SendPeople } from '@app/images'
import { Button, Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import { getFromLocalStorage, useDigitalSign, useToggle } from '@/shared'
import styles from './Staffing.module.scss'
import { StaffingPositionForm } from './positionForm'
import { initialStaffingPosition } from '@/entity/staffingProject/reducer'
import { useDispatch, useSelector } from 'react-redux'
import { staffingActions, staffingSelectors, staffingThunks } from '@/entity/staffing'
import { View } from '@features/staffing/positionForm/view/View'
import { contractActionsSelectors } from '@features/contracts/model'
import {
	calculateGroupStaffUnits,
	calculateStaffUnits,
	getDeptAndDeptTypeCodeByExternalId,
	getGroupWorkPlaces,
} from '@/utils'
import ModalGetNewVersion from './ModalGetNewVersion/ModalGetNewVersion'
import { InfoWarning } from '@/components/infoWarning'
import { getCurrentLanguage } from '@/i18next'
import {
	TreeViewGroup,
	StaffUnitsCount,
	TreeView,
	StaffingInformation,
	GroupWorkPlaceModalForm,
	RemoveModal,
} from './components'
import { Arrow } from '@app/icons'
import { StaffingStructure } from '@features/staffing/createStaffing/structureForm/StaffingStructure'
import { RemoveStruct } from 'features/staffing/components/Remove'
import { DigitalSign } from '@/utils/digitalSign'
import { message } from 'antd'
import { View as ViewGroupWorkPlace } from '@features/staffing/components/groupWorkPlace/view'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { WorkPlaceModalForm } from '@features/staffing/components/workPlace/WorkPlaceModalForm'
import { LoadingBlocker } from '@/components/Loader'

const Staffing = () => {
	const { t } = useTranslation()
	const isLoading = useSelector(staffingSelectors.isLoading)
	const staffing = useSelector(staffingSelectors.staffing)
	const esutdInfo = useSelector(staffingSelectors.esutdInfo)
	const staffXML = useSelector(staffingSelectors.staffXML)
	const employeeListXML = useSelector(staffingSelectors.employeeListXML)
	const innerStatInfo = useSelector(staffingSelectors.innerStatInfo)
	const { staffType } = useSelector(contractActionsSelectors.options)
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)

	const lang = getCurrentLanguage()
	const dispatch = useDispatch()

	const [openModalSign, setOpenModalSign] = useToggle(false)

	const [activeStructId, setActiveStructId] = useState(null)
	const [groupRow, setGroupRow] = useState(null)
	const [workPlaceRowSelect, setWorkPlaceRowSelect] = useState(null)
	const [deactivateStaff, setDeactivateStaff] = useState(false)
	const [rowStruct, setRowStruct] = useState(null)
	const [parentStructureId, setParentStructureId] = useState(null)

	const { successPayload, onSign, onSignMultiple, failedPayload, onClearPayload } = useDigitalSign()
	const digitalSign = new DigitalSign()

	const optionLang = useMemo(() => (lang === 'rus' ? 'nameRu' : 'nameKz'), [lang])
	const { lmc } = activeCompany || {}

	const [actions, setActions] = useState({
		create: false,
		view: false,
		edit: false,
		remove: false,
	})

	const [actionsGroup, setActionsGroup] = useState({
		view: false,
		edit: false,
		remove: false,
	})

	const [actionsStruct, setActionsStruct] = useState({
		view: false,
		edit: false,
		remove: false,
	})

	const activeStaffBranch = getFromLocalStorage('activeStaffBranch')

	const { orgsList, staffingGrants } = useSelector((state) => ({
		orgsList: state.administration.staffingBranchesList,
		staffingGrants: state.administration.staffingGrants,
	}))

	const title = useMemo(() => {
		let res = orgsList.data?.find((item) => item.bin == activeStaffBranch)

		let byLang = {
			rus: res?.orgNameRu,
			kz: res?.orgNameKz,
		}

		return byLang?.[lang]
	}, [orgsList.data, activeStaffBranch, lang])

	const staffingPosition = {
		...initialStaffingPosition,
	}

	const activeGroupWorkPlace = useMemo(() => {
		let filteredGroupWorkPlace
		if (staffing && staffing?.externalId.length > 0) {
			filteredGroupWorkPlace = getGroupWorkPlaces(staffing, activeStructId)
		}
		return filteredGroupWorkPlace
	}, [staffing, activeStructId])

	const structure = useMemo(
		() =>
			staffing && staffing?.externalId.length > 0 && activeStructId !== null
				? getDeptAndDeptTypeCodeByExternalId(staffing, activeStructId)
				: '',
		[staffing, activeStructId]
	)

	const showFullNameStruct = (code) => {
		const deptType = staffType.find((item) => item.code === code)
		return deptType ? deptType[lang] + ': ' + structure.dept[optionLang] : null
	}

	useEffect(() => {
		if (esutdInfo?.preparedStatus === 'PREPARED') {
			setOpenModalSign(true)
			setDeactivateStaff(true)
		}
	}, [staffing, structure, actionsStruct])

	useEffect(() => staffXML && onSign(staffXML), [staffXML])

	useEffect(() => employeeListXML && onSignMultiple(employeeListXML), [employeeListXML])

	useEffect(() => {
		failedPayload && onClear()
	}, [failedPayload])

	useEffect(() => {
		if (successPayload) {
			if (!employeeListXML) {
				dispatch(staffingThunks.sendActualSignStaffing(successPayload))
			} else {
				dispatch(staffingThunks.sendEmployeeList(successPayload))
			}
			onClearPayload()
		}
	}, [successPayload])

	const onClose = () => {
		let actionObj = {
			create: false,
			view: false,
			edit: false,
			remove: false,
		}

		if (actions.view && actions.create) {
			actionObj.create = false
			actionObj.view = true
		}
		if (!actions.view) {
			dispatch(staffingActions.resetWPlaceEmployee())
		}

		setActions((prevActions) => ({ ...prevActions, ...actionObj }))
		setOpenModalSign(false)
	}

	const onCloseStruct = () => {
		let actionObj = {
			view: false,
			edit: false,
			remove: false,
		}

		setActionsStruct((prevActionsStruct) => ({ ...prevActionsStruct, ...actionObj }))
	}

	const onCloseGroup = () => {
		let actionObj = {
			create: false,
			view: false,
			edit: false,
			remove: false,
		}

		if (actionsGroup.view && (actionsGroup.edit || actionsGroup.create || actionsGroup.remove)) {
			actionObj.view = true
		}
		setActionsGroup((prevActionsGroup) => ({ ...prevActionsGroup, ...actionObj }))
	}

	const onToggleAction = (action, value) => {
		setActions((prevState) => {
			return { ...prevState, [action]: value }
		})
	}

	const onToggleActionStruct = (action, value) => {
		setActionsStruct((prevState) => {
			return { ...prevState, [action]: value }
		})
	}

	const onToggleActionGroup = (action, value) => {
		setActionsGroup((prevState) => {
			return { ...prevState, [action]: value }
		})
	}

	const signAndSendActualStaff = () => {
		dispatch(staffingThunks.getActualXml(staffing.externalId))
		onClose()
	}

	const signAndSendEmployee = () => {
		dispatch(staffingThunks.getEmployeeListXml())
	}

	const onClear = () => {
		onClearPayload()
		dispatch(staffingActions.setStaffXml(null))
		dispatch(staffingActions.setEmployeeXml(null))
	}

	const onSubmit = () => {
		let body = {
			externalId: rowStruct.externalId,
			onSuccess: async (xml) => {
				const res = await digitalSign.handleSend(xml)

				let signedBody = {
					data: res,
					removeStructId: rowStruct.externalId,

					onSuccess: () => {
						message.success('Структура успешно удалена!')
						onCloseStruct()
					},
				}

				dispatch(staffingThunks.removeSignActualStructure(signedBody))
			},
		}
		dispatch(staffingThunks.setActualRemoveStructure(body))
	}

	const removeGroupProcess = () => {
		const type = 'posGroup'
		let body = {
			type,
			externalId: groupRow.externalId,
			onSuccess: async (xml) => {
				const res = await digitalSign.handleSend(xml)

				let signedBody = {
					data: res,
					type,
					groupId: groupRow.externalId,

					onSuccess: () => {
						message.success('Группа успешно удалена!')
						onCloseGroup()
					},
				}

				dispatch(staffingThunks.removeSignActualGroup(signedBody))
			},
		}
		dispatch(staffingThunks.setActualRemoveGroup(body))
	}

	const removeWorkPlaceProcess = () => {
		const type = 'workPlace'
		let body = {
			type,
			externalId: workPlaceRowSelect.externalId,
			onSuccess: async (xml) => {
				const res = await digitalSign.handleSend(xml)

				let signedBody = {
					data: res,
					type,
					removeStructId: rowStruct.externalId,
					positionGroupExternalId: workPlaceRowSelect.groupId,
					externalId: workPlaceRowSelect.externalId,

					onSuccess: () => {
						message.success('Рабочее место успешно удалено!')
						onClose()
					},
				}

				dispatch(staffingThunks.removeSignActualWorkPlace(signedBody))
			},
		}
		dispatch(staffingThunks.setActualRemoveWorkPlace(body))
	}

	return (
		<>
			<>
				{openModalSign ? (
					<ModalGetNewVersion onSubmit={signAndSendActualStaff} onClose={onClose}></ModalGetNewVersion>
				) : null}
				{deactivateStaff ? (
					<div className={styles.mb}>
						<InfoWarning />
					</div>
				) : null}
				<div className={styles.buttonSearchPanel}>
					{!!staffing && !deactivateStaff && staffingGrants?.data?.stRegistration ? (
						<Button
							icon={SendPeople}
							iconSize={'big'}
							onClick={() => {
								signAndSendEmployee()
							}}
						>
							{t('send_appointment')}
						</Button>
					) : null}
				</div>
				{actionsStruct.view ? (
					<>
						<div className={styles.linePreTable}>
							<div onClick={() => onToggleActionStruct('view', false)} className={styles.back}>
								<Arrow styles={{ fill: '#17B67C', width: '5px' }} />
								{t('back')}
							</div>
						</div>
						<div className={styles.countStaff}>
							<Title>{showFullNameStruct(structure.deptTypeCode)}</Title>
							<StaffUnitsCount t={t} totalStaffUnits={innerStatInfo?.workplaceTariffRateSum || 0} totalFreeStaffUnits={innerStatInfo?.freeWorkplaceTariffRateSum || 0} />
						</div>
						<TreeViewGroup
							groupWorkPlace={activeGroupWorkPlace}
							setActionsGroup={setActionsGroup}
							setGroupRow={setGroupRow}
							setActionsPositions={setActions}
							setPositionRowSelectMenu={setWorkPlaceRowSelect}
							isProject={false}
						></TreeViewGroup>
						{actions.create ? (
							<StaffingPositionForm
								initialValues={staffingPosition}
								onClose={onClose}
								actions={actions}
								setActions={setActions}
								workPlaceRowSelect={workPlaceRowSelect}
							></StaffingPositionForm>
						) : null}
						{actionsGroup.view && !!groupRow ? (
							<ViewGroupWorkPlace
								onToggleAction={onToggleActionGroup}
								onClose={onCloseGroup}
								groupRow={groupRow}
							></ViewGroupWorkPlace>
						) : null}
						{actionsGroup.edit ? (
							<GroupWorkPlaceModalForm
								initialValues={groupRow}
								onClose={onCloseGroup}
								actions={actionsGroup}
								activeStructId={activeStructId}
								staff={staffing}
								lmc={lmc}
								groupRow={groupRow}
							></GroupWorkPlaceModalForm>
						) : null}
						{actionsGroup.remove ? (
							<RemoveModal onSubmit={removeGroupProcess} onClose={onCloseGroup} type={'group'}></RemoveModal>
						) : null}
						{actions.view && !!workPlaceRowSelect ? (
							<View
								onClose={onClose}
								onToggleAction={onToggleAction}
								workPlaceRowSelect={workPlaceRowSelect}
								deactivateStaff={deactivateStaff}
							></View>
						) : null}
						{actions.edit && !!workPlaceRowSelect ? (
							<WorkPlaceModalForm
								onClose={onClose}
								activeStructId={activeStructId}
								staff={staffing}
								positionRowSelectMenu={workPlaceRowSelect}
								setPositionRowSelectMenu={setWorkPlaceRowSelect}
							></WorkPlaceModalForm>
						) : null}
						{actions.remove ? (
							<RemoveModal onSubmit={removeWorkPlaceProcess} onClose={onClose} type={'workplace'}></RemoveModal>
						) : null}
					</>
				) : (
					<div className={styles.mt}>
						<div className={styles.countStaff}>
							<Title>{title || ''}</Title>
							<StaffUnitsCount t={t} totalStaffUnits={(staffing.workplaceTariffRateSum || 0)} totalFreeStaffUnits={(staffing.freeWorkplaceTariffRateSum || 0)} />
						</div>
						{staffing?.currVersion ? (
							<StaffingInformation
								currVersion={staffing?.currVersion}
								orderNumber={staffing?.orderNumber}
								orderDate={staffing?.orderDate}
								t={t}
							/>
						) : null}
						<TreeView
							staffing={staffing}
							staffType={staffType}
							setActiveStructId={setActiveStructId}
							setActions={setActionsStruct}
							setRowStruct={setRowStruct}
							setParentStructureId={setParentStructureId}
						/>
						{structure?.level !== 0 && actionsStruct.edit ? (
							<StaffingStructure
								onClose={onCloseStruct}
								initialValues={staffing}
								actions={actionsStruct}
								staffType={staffType}
								rowSelectMenu={rowStruct}
								setRowSelectMenu={setRowStruct}
								staffProject={staffing}
								parentStructureId={parentStructureId}
							></StaffingStructure>
						) : null}
						{actionsStruct.remove ? (
							<RemoveStruct onSubmit={onSubmit} onClose={onCloseStruct} rowSelectMenu={rowStruct}></RemoveStruct>
						) : null}
					</div>
				)}
			</>

			{isLoading ? <LoadingBlocker /> : null}
		</>
	)
}

export default Staffing
