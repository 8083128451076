import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo } from 'react';
import { getCurrentLanguage } from '@/i18next';
import { headCells } from './table.headings';
import styles from './TableHistory.module.scss';
import { downloadFileByUid } from '@/shared/api/rest/pension/downloadFileByUid';
import { formatDate } from '@/utils'

export const TableHistory = ({ data }) => {
	const { t } = useTranslation();
	const lang = getCurrentLanguage();

	const downloadFile = async (file) => {
		const response = await downloadFileByUid(file.id);
		const blob = URL.createObjectURL(response);
		const a = document.createElement('a');
		a.href = blob;
		a.download = file?.fileName;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	const getTableRow = useCallback(
		(row, index) => (
			<tr key={index}>
				<td>{row.updateDateTime || '-'}</td>
				<td>{lang === 'rus' ? row.updateTextRu : row.updateTextKz}</td>
				<td>
					{row.files?.map((file, fileIndex) => (
						<div key={fileIndex}>
							<a onClick={() => downloadFile(file)}>{file?.fileName}</a>
							{file?.fileSize > 1e6
								? ' (' + (file?.fileSize / 1e6).toFixed(1) + ' МБ)'
								: ' (' + (file?.fileSize / 1000).toFixed() + ' КБ)'}
						</div>
					)) || '-'}
				</td>
			</tr>
		),
		[lang]
	);

	const tableBody = useMemo(() => data.map((row, index) => getTableRow(row, index)), [data, getTableRow]);
	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	);

	return (
		<>
			<table className={styles.table}>
				<thead>
				<tr>{tableHeader}</tr>
				</thead>
				<tbody>{data?.length ? tableBody : null}</tbody>
			</table>
		</>
	);
};