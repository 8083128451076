import React, { useState } from 'react'
import { Form as FormikForm, Field as FormikField, useFormikContext } from 'formik'
import styles from './GroupWorkPlaceForm.module.scss'
import { formFieldNames } from './formFieldNames'
import RadioStaff from '@/components/RadioStaff/RadioStaff'
import { Button, Field } from '@/ui'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { contractActionsSelectors } from '@features/contracts/model'
import { formatNumber } from '@/utils'
import { WrappedCheckbox } from '@features/contracts/registrationEmployerContract/form/fields'

export const GroupWorkPlaceForm = ({ edit, onClose, actualStaffing }) => {
	const { t } = useTranslation()
	const { setFieldValue, values } = useFormikContext()
	const { positions, positionsByKnz, laborMobilityCenterCategory } = useSelector(contractActionsSelectors.options)

	const [inputValues, setInputValues] = useState({
		grossSalary: '',
		salarySupplement: '',
	})

	const handleInputChange = (fieldName) => (event) => {
		let value = event.target.value
		if (value && !inputValues[fieldName]) {
			setInputValues({ ...inputValues, [fieldName]: value })
		} else {
			const onlyNumbers = value.replace(/\D/g, '')
			const numberWithSpaces = formatNumber(onlyNumbers)
			setInputValues({ ...inputValues, [fieldName]: numberWithSpaces })
			setFieldValue(fieldName, onlyNumbers)
		}
	}

	return (
		<FormikForm className={styles.form}>
			<section>
				<div className={styles.item}>
					<div className={`${styles.fields} ${styles.fields_verticalCenter}`}>
						<div className={styles.label}>
							<FormikField name={formFieldNames.freelance}>
								{({ field }) => (
									<WrappedCheckbox
										{...field}
										label={t('staff_modal.freelancer_position')}
										disabled={edit && !actualStaffing}
									/>
								)}
							</FormikField>
						</div>
					</div>
					<FormikField name={formFieldNames.isDicPosition}>
						{({ field }) => (
							<div className={'flex'}>
								<RadioStaff
									label={t('workplace.manual_input')}
									value="2"
									id="2"
									onChange={(e) => setFieldValue(field.name, e.target.value)}
									checked={field.value === '2'}
								/>
								<RadioStaff
									label={t('workplace.directory')}
									value="3"
									id="3"
									onChange={(e) => setFieldValue(field.name, e.target.value)}
									checked={field.value === '3'}
								/>
							</div>
						)}
					</FormikField>
					{values[formFieldNames.isDicPosition] === '2' ? (
						<div className={styles.twoFields}>
							<FormikField name={formFieldNames.nameKz}>
								{({ field, meta: { touched, error } }) => (
									<Field
										{...field}
										type="text"
										name={t('staff_modal.position_kz')}
										label={t('staff_modal.position_kz')}
										placeholder={t('staff_modal.position_placeholder')}
										isRequired
										error={touched && error}
									/>
								)}
							</FormikField>
							<FormikField name={formFieldNames.nameRu}>
								{({ field, meta: { touched, error } }) => (
									<Field
										{...field}
										type="text"
										label={t('staff_modal.position_ru')}
										placeholder={t('staff_modal.position_placeholder')}
										isRequired
										error={touched && error}
									/>
								)}
							</FormikField>
						</div>
					) : (
						<FormikField name="destablishedPost">
							{({ field, meta: { touched, error } }) => (
								<Field
									{...field}
									fieldType="selectSearch"
									label={t('contract.info.position')}
									hint={t('hint_position')}
									placeholder={t('position_placeholder')}
									options={positions.filter((item) => item.isLast)}
									error={touched && error}
									isRequired
								/>
							)}
						</FormikField>
					)}
					<FormikField name={formFieldNames.dposition}>
						{({ field, meta: { touched, error } }) => (
							<Field
								{...field}
								fieldType="selectSearch"
								label={t('contract.info.nkz_position')}
								hint={t('hint_position_knz')}
								placeholder={t('choose_from_directory')}
								options={positionsByKnz}
								error={touched && error}
								isRequired
							/>
						)}
					</FormikField>
					<FormikField name={formFieldNames.dstaffCategory}>
						{({ field, meta: { touched, error } }) => (
							<Field
								{...field}
								fieldType="select"
								label={t('staff_modal.position_category')}
								placeholder={t('choose_from_directory')}
								options={laborMobilityCenterCategory}
								error={touched && error}
								isRequired
							/>
						)}
					</FormikField>
					{!edit ? (
						<div className={`${styles.fields} ${styles.threeFields}`}>
							<FormikField name={formFieldNames.workplaceCount}>
								{({ field, meta: { touched, error } }) => (
									<Field
										{...field}
										type="number"
										hideNumberArrows
										placeholder={t('staff.staff_units_placeholder')}
										error={touched && error}
										label={t('workplace.tariff_rate')}
										disabled={edit}
										isRequired={!edit}
									/>
								)}
							</FormikField>
							<FormikField name={formFieldNames.grossSalary}>
								{({ field, meta: { touched, error } }) => (
									<Field
										type="text"
										hideNumberArrows
										fieldType={'inputNumber'}
										label={t('staffing_table.salary')}
										placeholder={t('staff_modal.salary_placeholder')}
										error={touched && error}
										value={inputValues.grossSalary || field.value}
										onChange={handleInputChange(formFieldNames.grossSalary)}
										autoComplete="off"
										disabled={edit}
										isRequired={!edit}
										{...field}
									/>
								)}
							</FormikField>
							<FormikField name={formFieldNames.salarySupplement}>
								{({ field, meta: { touched, error } }) => (
									<Field
										type="text"
										fieldType={'inputNumber'}
										hideNumberArrows
										label={t('staffing_table.supplement_surcharge')}
										placeholder={t('staff_modal.supplement_surcharge_placeholder')}
										error={touched && error}
										value={inputValues.salarySupplement || field.value}
										onChange={handleInputChange(formFieldNames.salarySupplement)}
										autoComplete="off"
										disabled={edit}
										{...field}
									/>
								)}
							</FormikField>
						</div>
					) : null}
					{edit ? null : (
						<p>
							{t('workplace.count_tariff_rate')}
							<i>{' - ' + t('staff.staff_units_count_hint')}</i>
						</p>
					)}
				</div>
			</section>
			<div className={styles.actions}>
				<Button type="bare" onClick={onClose}>
					{t('cancel')}
				</Button>
				<Button textColor="green" borderColor="green" transparent border type="submit">
					{t('save')}
				</Button>
			</div>
		</FormikForm>
	)
}
