export function removeStaffingStructure(childs, removeStructId) {
	if (!Array.isArray(childs)) return childs

	// Возвращаем childs, исключая объект с заданным externalId
	return childs
		.filter((child) => child.externalId !== removeStructId)
		.map((child) => ({
			...child,
			// Рекурсивно обрабатываем вложенные childs
			childs: removeStaffingStructure(child.childs, removeStructId),
		}))
}
