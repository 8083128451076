import { object, string } from 'yup'
import * as yup from 'yup'
import { transformStringDateFormatToDate } from '@/shared/lib/helpers/transformStringDateFormatToDate.js'

const validityTermIdsWhenEndContractDateIsRequired = [2]

export const EColContractValidationSchema = (t) =>
	object().shape({
		collectiveContractNumber: string().required({ key: 'required' }),
		bin: string()
			.required('required')
			.matches(/^[0-9]+$/, 'contracts_tab.not_correct_bin')
			.min(12, 'contracts_tab.min_12_length'),
		enterpriseName: string().required('required'),
		enterpriseNameKaz: string().required('required'),
		unions: yup.array().of(
			yup.object().shape({
				representativeDtos: yup.array().of(
					yup.object().shape({
						representativeIin: string()
							.matches(/^[0-9]+$/, 'contracts_tab.not_correct_iin')
							.min(12, 'contracts_tab.min_12_length'),
						representativeFIO: string().required('required'),
					})
				),
				unionBin: yup.string().when('unionTypeCode', {
					is: '2',
					then: yup
						.string()
						.required('required')
						.matches(/^[0-9]+$/, 'contracts_tab.not_correct_bin')
						.min(12, 'contracts_tab.min_12_length'),
					otherwise: yup.string().notRequired(),
				}),
				unionName: yup.string().required('required'),
				unionNameKaz: yup.string().required('required'),
				unionTypeCode: yup.string().required('required'),
			})
		),
		positions: yup
			.array()
			.min(1, 'required')
			.of(
				yup.object().shape({
					positionCode: yup.string().required({ key: 'required' }),
					workConditionsCode: yup.string().required({ key: 'required' }),
					gradeList: yup.array().of(
						yup.object().shape({
							gradeCoeff: yup.number()
								.test("minZeroValue", t("validation.staff.greater"), value => value > 0)
								.required({ key: 'required' }),
							salarySupplement: yup.number(),
							tariffRate: yup.number().required({ key: 'required' }),
						})
					),
				})
			),
		extraGuarantee: yup.array().of(
			yup.object().shape({
				code: yup.string().required('required'),
				value: yup.string().required('required'),
			})
		),
		dateFrom: transformStringDateFormatToDate().required({ key: 'required' }),
		dcontractDateCode: string().required({ key: 'required' }),
		dateTo: transformStringDateFormatToDate()
			.nullable()
			.when('dateFrom', (startDate) => {
				if (startDate) {
					return transformStringDateFormatToDate()
						.min(new Date(startDate.getTime() + 86400000), { key: 'date.end_before_start' })
						.nullable()
				}
			})
			.when('dcontractDateCode', {
				is: (validityTerm) => validityTermIdsWhenEndContractDateIsRequired.includes(Number(validityTerm)),
				then: (schema) => schema.required({ key: 'required' }),
			}),
		overtimePay: yup
			.number()
			.transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
			.when('overtimePayIs', {
				is: (overtimePayIs) => !!overtimePayIs === true,
				then: yup.number().required({ key: 'required' }),
			}),
		holidayPay: yup
			.number()
			.transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
			.when('holidayPayIs', {
				is: (holidayPayIs) => !!holidayPayIs === true,
				then: yup.number().required({ key: 'required' }),
			}),
		nightTimePay: yup
			.number()
			.transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
			.when('nightTimePayIs', {
				is: (nightTimePayIs) => !!nightTimePayIs === true,
				then: yup.number().required({ key: 'required' }),
			}),
		combinationPositionPay: yup
			.number()
			.transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
			.when('combinationPositionPayIs', {
				is: (combinationPositionPayIs) => !!combinationPositionPayIs === true,
				then: yup.number().required({ key: 'required' }),
			}),
		termCompensationPay: yup
			.number()
			.transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
			.when('termCompensationPayIs', {
				is: (termCompensationPayIs) => !!termCompensationPayIs === true,
				then: yup.number().required({ key: 'required' }),
			}),
		wayCompensationPay: yup
			.number()
			.transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
			.when('wayCompensationPayIs', {
				is: (wayCompensationPayIs) => !!wayCompensationPayIs === true,
				then: yup.number().required({ key: 'required' }),
			}),
		wageIndexProcedure: yup
			.number()
			.transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
			.when('wageIndexProcedureIs', {
				is: (wageIndexProcedureIs) => !!wageIndexProcedureIs === true,
				then: yup.number().required({ key: 'required' }),
			}),
		workTime: yup.array().min(1, 'required'),
		relaxTime: yup.array().min(1, 'required'),
		securityFundingVolume: yup.string().required('required'),
		dcolContractEnterpriseTypeCode: yup.string().nullable().required({ key: 'required' }),
		dcolContractOwnershipTypeCode: yup.string().nullable().required({ key: 'required' }),
		language: yup.string().required(t('errors.required')),
	})
