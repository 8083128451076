import * as types from './types'
import { SET_WPLACE_EMPLOYEE_STAT_INFO } from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	toggleError: (payload) => ({
		type: types.TOGGLE_ERROR,
		payload,
	}),
	setStaffing: (payload) => ({
		type: types.SET_STAFFING,
		payload,
	}),
	addOpenedChildId: (payload) => ({
		type: types.ADD_OPENED_CHILD_ID,
		payload,
	}),
	removeOpenedChildId: (payload) => ({
		type: types.REMOVE_OPENED_CHILD_ID,
		payload,
	}),
	setStaffXml: (payload) => ({
		type: types.SET_STAFF_XML,
		payload,
	}),
	setWPlaceEmployee: (payload) => ({
		type: types.SET_WPLACE_EMPLOYEE,
		payload,
	}),
	setWPlaceEmployeeStatInfo: (payload) => ({
		type: types.SET_WPLACE_EMPLOYEE_STAT_INFO,
		payload,
	}),
	update_WPlace_Employee: (payload) => ({
		type: types.UPDATE_WPLACE_EMPLOYEE,
		payload,
	}),
	delete_WPlace_Employee: (payload) => ({
		type: types.DELETE_WPLACE_EMPLOYEE,
		payload,
	}),
	resetWPlaceEmployee: () => ({
		type: types.RESET_WPLACE_EMPLOYEE,
	}),
	setEmployeeXml: (payload) => ({
		type: types.SET_EMPLOYEE_XML,
		payload,
	}),
	setDelEmployeeXml: (payload) => ({
		type: types.SET_DEL_EMPLOYEE_XML,
		payload,
	}),
	setEditEmployeeXml: (payload) => ({
		type: types.SET_EDIT_EMPLOYEE_XML,
		payload,
	}),
	setEditActualStructureXml: (payload) => ({
		type: types.SET_EDIT_ACTUAL_STRUCTURE_XML,
		payload,
	}),
	sendSignedActualStructureXml: (payload) => ({
		type: types.SEND_SIGNED_ACTUAL_STRUCTURE_XML,
		payload,
	}),
	setRemoveActualStructureXml: (payload) => ({
		type: types.SET_REMOVE_ACTUAL_STRUCTURE_XML,
		payload,
	}),
	setEditActualGroupXml: (payload) => ({
		type: types.SET_EDIT_ACTUAL_GROUP_XML,
		payload,
	}),
	sendSignedActualGroupXml: (payload) => ({
		type: types.SEND_SIGNED_ACTUAL_GROUP_XML,
		payload,
	}),
	setRemoveActualGroupXml: (payload) => ({
		type: types.SET_REMOVE_ACTUAL_GROUP_XML,
		payload,
	}),
	sendRemoveActualGroupXml: (payload) => ({
		type: types.SEND_REMOVE_ACTUAL_GROUP_XML,
	}),
	setEditActualWorkPlaceXml: (payload) => ({
		type: types.SET_EDIT_ACTUAL_WORK_PLACE_XML,
		payload,
	}),
	sendSignedActualWorkPlaceXml: (payload) => ({
		type: types.SEND_SIGNED_ACTUAL_WORK_PLACE_XML,
		payload,
	}),
	setRemoveActualWorkPlaceXml: (payload) => ({
		type: types.SET_REMOVE_ACTUAL_WORK_PLACE_XML,
		payload,
	}),
	sendRemoveActualWorkPlaceXml: (payload) => ({
		type: types.SEND_REMOVE_ACTUAL_WORK_PLACE_XML,
	}),
	getStatInfo: (payload) => ({
		type: types.GET_STAT_INFO,
		payload,
	}),
}
