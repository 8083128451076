import styles from '@/components/AddDocuments/ConfirmDocument.module.scss'
import doc from '@/assets/home/doc.svg'
import trash from '@/assets/home/trash.svg'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import paper from '@/assets/home/PaperClip.svg'
import { useDropzone } from 'react-dropzone'
import { getSha256 } from '@/utils'
import { uploadFile } from '@/shared/api/rest/pension'
import { BACKEND_FILE_TYPE, FILE_FORMAT } from '@app/config'
import { Tooltip } from 'antd'
import { Info } from '@app/icons'
import { LoadingBlocker } from '@/components/Loader'
import cl from 'classnames'
import { getBase64 } from '@features/laborRecords/components/document/utils'
import { uploadFileEc } from '@/shared/api/rest/pension/uploadFileEc'

export const UPLOAD_TYPE = {
	SHA256: 'SHA256',
	BASE64: 'BASE64',
}

let ERROR_CODE = {
	FILES_MAX_COUNT: 'FILES_MAX_COUNT',
	FILES_MAX_SIZE: 'FILES_MAX_SIZE',
	IS_DUPLICATE: 'IS_DUPLICATE',
}

let errorInitialState = {
	code: null,
	message: null,
}

let payloadInitialState = {
	loading: false,
	success: false,
	failed: errorInitialState,
	data: null,
}

/**
 * @description Компонент upload
 *
 * @param {Object} props – Типичный объект с пропсами
 *
 * @param {Array} 				props.value - параметр который передается сверху, является массивом
 * @param {String} 				props.backendFileType - параметр который передается сверху
 * @param {VoidFunction} 	props.onSuccess - результирующая функция, возвращет результат при успешной валидации/отправки файла на бэк в виде ОБЬЕКТА, тк выбор происходит по 1 элементу
 * @param {VoidFunction} 	props.onError - результирующая функция, возвращет ошибки в виде обьекта {code: ***, message: ***}
 * @param {String} 				props.uploadType - параметр который передается сверху, можно экспортировать енам с текущего файла. если выбрал SHA256, то будет валидация на фронте. если выбрал BASE64, то будет собираться файл наружу
 * @param {VoidFunction} 	props.acceptedFileTypes - массив с форматами, так же можно экспортировать енам с форматами
 * @param {Boolean} 	props.isAlwaysAttachButtonShow - всегда показать кнопку
 * @param {Boolean} 	props.isBigSize 
 * @param {String} 	props.buttonTitle
 */

export const Uploader = memo(({
																value = [],
																maxSize = 10485760,
																backendFileType = BACKEND_FILE_TYPE.COLLECTIVECONTRACT,
																maxFiles = Infinity,
																onSuccess,
																onError,
																uploadType = UPLOAD_TYPE.SHA256,
																acceptedFileTypes = [FILE_FORMAT.jpg, FILE_FORMAT.jpeg, FILE_FORMAT.png, FILE_FORMAT.pdf],
																isAlwaysAttachButtonShow = false,
																isBigSize = false,
																isEcFileUpload = false,
																buttonTitle
															}) => {
	const { t } = useTranslation()

	const [payload, setPayload] = useState(payloadInitialState)

	const { getRootProps, getInputProps } = useDropzone({
		noKeyboard: true,
		maxFiles: 0,
		multiple: false,
		accept: acceptedFileTypes,
		onDropAccepted: async (files) => {
			let file = files[0]

			setPayload({ ...payloadInitialState, loading: true })

			if (uploadType === UPLOAD_TYPE.BASE64) {
				return getBase64Handler(file).then(async (resArray) => {
					await onSuccessBase64(resArray)
				})
			} else {
				return validateSha256Handler(file).then(async (error) => {
					if (error.code) {
						onErrorHandler(error)
					} else {
						const res = await onUpload(file)

						await onSuccessSha256(res)
					}
				})
			}
		},
	})

	const onSuccessSha256 = async (file) => {
		const body = [...(value || []), file]

		setPayload(prev => ({
			...payloadInitialState,
			success: true,
			data: body,
		}))

		onSuccess(body)
	}

	const onSuccessBase64 = async (array) => {
		setPayload(prev => ({
			...payloadInitialState,
			success: true,
			data: array,
		}))

		onSuccess(array)
	}

	const onErrorHandler = (error) => {
		setPayload(prev => ({
			...payloadInitialState,
			failed: error,
		}))

		onError(error)
	}

	const getBase64Handler = useCallback(async (file) => {
		return getBase64([file]).then(resArray => {
			return resArray?.map(item => ({
				docData: item.base64StringFile,
				fileName: item.fileName,
				extension: item.fileName.substring(item.fileName.lastIndexOf('.') + 1),
			}))
		})
	}, [value])

	const validateSha256Handler = useCallback(async (file) => {
		return getSha256(file).then(res => {
			let error = errorInitialState
			const totalValues = [...(value || []), { ...file, fileSize: file.size }]
			const filesSizeByte = totalValues.reduce((acc, file) => (acc + file.fileSize), 0)
			const isDuplicate = (value || [])?.some(i => i.sha256hex === res)

			if (totalValues > maxFiles) {
				error = {
					code: ERROR_CODE.FILES_MAX_COUNT,
					message: t('profUnions.cannotUpload'),
				}

				return error
			}

			if (filesSizeByte >= maxSize) {
				error = {
					code: ERROR_CODE.FILES_MAX_SIZE,
					message: t('labor_records.error_size'),
				}

				return error
			}

			if (isDuplicate) {
				error = {
					code: ERROR_CODE.IS_DUPLICATE,
					message: t('pension.payments.form.doc_form.file_added'),
				}
				return error
			}

			return error
		})
	}, [value])

	const onUpload = async (file) => {
		try {
			const formData = new FormData()
			return getSha256(file).then(async () => {
				formData.set('file', file)
				let data
				if(isEcFileUpload) {
					data = await uploadFileEc(formData)
				} else {
					data = await uploadFile(formData, backendFileType)
				}
				return data
			})
		} catch (e) {
			console.log('e', e)
		}
	}

	const removeFile = (file) => {
		let body = (value || []).filter(item => item.id !== file.id)

		setPayload(prev => ({
			...payloadInitialState,
			success: true,
			data: body,
		}))

		onSuccess(body)
	}

	const filesSize = useMemo(() => {
		const size = (value || [])?.reduce((acc, file) => file.fileSize + acc, 0) / 1e6
		if (size < 0.1) {
			return size.toFixed(2)
		}
		return size.toFixed(1)
	}, [value])

	const enabledErrorCodes = {
		[ERROR_CODE.IS_DUPLICATE]: true,
		[ERROR_CODE.FILES_MAX_SIZE]: true,
		[ERROR_CODE.FILES_MAX_COUNT]: true,
	}

	return (
		<>
			<div className={styles.wrapper}>
				<ul className={styles.file}>
					{(value || [])?.map((file, idx) => (
						<li key={idx}>
							<div>
								<img src={doc} />
								<span>{file.fileName}</span>
							</div>
							<div
								className={styles.file__delete}
								onClick={() => removeFile(file)}
							>
								<img src={trash} />
								<span>{t('remove')}</span>
							</div>
						</li>
					))}
				</ul>

				{filesSize > 0.0 ? (
					<div className={styles.size}>
						{' '}
						<Tooltip
							placement="right"
							color="white"
							overlayInnerStyle={{ color: '#5B6880', width: '329px' }}
							title={t('labor_records.max_size_all')}
						>
							{t('labor_records.full_size') + ' (' + filesSize + ' МБ) '}
							<Info className={styles.hintIcon} />
						</Tooltip>
					</div>
				) : null}

				{
					((value || [])?.length >= maxFiles && !isAlwaysAttachButtonShow) ? (
						<div className={styles.formDisableBtn}>
							<span>{t('profUnions.cannotUpload')}</span>
						</div>
					) : (
						<div className={styles.formBtn}
								 {...getRootProps({
									 onClick: evt => evt.preventDefault(),
								 })}
						>
							<div className="greenBtn">
								<label className="input-file">
									<input type="file" {...getInputProps()} />
									<div className={ isBigSize ? cl("custom-file", styles.big) : "custom-file"}>
										<img src={paper} />
										<span>{ buttonTitle ? buttonTitle : t('attache_file')}</span>
									</div>
								</label>
							</div>
						</div>
					)
				}

				{enabledErrorCodes[payload.failed.code] && <p className={cl(styles.error, 'mt-4')}>{payload.failed.message}</p>}
			</div>

			{payload.loading && <LoadingBlocker />}
		</>
	)
})