import { RedirectUnAuthUser } from '@/processes'
import { ProfileTemplate } from '@/widgets'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
	eColContractsActions,
	eColContractsSelectors,
	eColContractsThunks,
	profUnionsSelectors,
	profUnionsThunks,
	userSelectors,
} from '@/entity'
import { useDispatch, useSelector } from 'react-redux'
import { Registration } from '@/features'
import ECollectiveContractTable from '@features/eCollectiveContracts/table/ECollectiveContractTable'
import { HrPagination } from '@/components/Pagination'
import { initialQueryState } from '@/entity/eCollectiveContracts/model/reducer'
import { View } from '@features/eCollectiveContracts/registration/view'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { Loader } from '@/components/Loader'
import { LoaderWrapper } from '../../ui/molecules/loaderWrapper/index'
import { useLocation } from 'react-router-dom'
import { Form } from '@features/eCollectiveContracts/registration/Form/Form'
import { useMemo } from 'react'
import { isEmpty } from 'lodash'
import { contractActionsSelectors } from '@features/contracts/model/index'

export const CollectiveContractsPage = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const [queryParams, setQueryParams] = useState(initialQueryState)
	const [isView, openView] = useState(false)
	const [isRemove, openRemove] = useState(false)
	const activeRole = useSelector(userSelectors.activeRole)
	const { bin } = useSelector(employerProfileSelectors.activeCompany)
	const user = useSelector(userSelectors.user)
	const isEmployee = activeRole === 'ROLE_EMPLOYEE'
	const isEmployer = activeRole === 'ROLE_EMPLOYER'
	const contractsData = useSelector(eColContractsSelectors.eColContracts)
	const contract = useSelector(eColContractsSelectors.eColContract)
	const tradeUnion = useSelector(profUnionsSelectors.tradeUnion)
	const [isTradeUnionNotEmpty, setIsTradeUnionNotEmpty] = useState(false)
	const [isTradeUnion, setIsTradeUnion] = useState(false)
	

	useEffect(() => {
		const { refValue, openModal } = location.state || {}
		if (refValue && openModal) {
			dispatch(eColContractsThunks.getColContract(refValue))
			openView(true)
		}
	}, [location.state, dispatch])

	useEffect(() => {
		dispatch(eColContractsThunks.getOptions())
	}, [])

	useEffect(() => {
		if (
			(!!tradeUnion && ['primary', 'local'].includes(tradeUnion?.typeCode) && isEmployer && bin === tradeUnion.bin) ||
			(isEmployee && user.iin === tradeUnion.chiefIin)
		) {
			setIsTradeUnion(true)
		} else {
			setIsTradeUnion(false)
		}
	}, [tradeUnion, isEmployee])

	useEffect(() => {
		if (!!tradeUnion && ['primary', 'local'].includes(tradeUnion?.typeCode)) {
			setIsTradeUnionNotEmpty(true)
		} else {
			setIsTradeUnionNotEmpty(false)
		}
	}, [tradeUnion, isEmployee])

	useEffect(() => {
		dispatch(profUnionsThunks.getTradeUnion(isEmployee))
	}, [activeRole])

	const fetchData = useCallback(
		(_queryParams = queryParams, _isEmployee = isEmployee) => {
			const { page, pageSize } = _queryParams
			dispatch(eColContractsThunks.getColContracts(page, pageSize, _isEmployee))
		},
		[queryParams, isEmployee]
	)

	useEffect(() => fetchData(), [queryParams.page, queryParams.pageSize, isEmployee])

	const handleChangePage = (e, page) => setQueryParams({ ...queryParams, page: page - 1 })
	const handleChangeRowsPerPage = (size) => setQueryParams((prev) => ({ ...prev, page: 0, pageSize: size }))

	const Close = () => {
		openView(false)
		dispatch(eColContractsActions.resetEColContract())
		dispatch(eColContractsActions.setEColContractSendSuccess(false))
	}

	return (
		<RedirectUnAuthUser>
			<ProfileTemplate title={'col_contract.contracts'}>
				<Registration
					fetchData={fetchData}
					isTradeUnionNotEmpty={isTradeUnionNotEmpty}
					tradeUnion={tradeUnion}
					isTradeUnion={isTradeUnion}
					isEmployee={isEmployee}
				></Registration>
				{contractsData ? (
					<>
						<ECollectiveContractTable
							data={contractsData?.eColContracts}
							view={openView}
							remove={openRemove}
							isTradeUnion={isTradeUnion}
						></ECollectiveContractTable>
						<HrPagination
							id="hr-pagination"
							rowsPerPageitems={queryParams.rowsPerPage}
							rowsPerPage={queryParams.pageSize}
							numberOfElements={contractsData.numberOfElements}
							totalElements={contractsData.totalElements}
							totalPages={contractsData.totalPages}
							handleChangePage={handleChangePage}
							handleChangeRowsPerPage={handleChangeRowsPerPage}
							page={queryParams.page}
						/>
					</>
				) : (
					<LoaderWrapper>
						<Loader />
					</LoaderWrapper>
				)}
				{isView && !!contract ? (
					<View
						contract={contract}
						onClose={Close}
						isEmployee={isEmployee}
						fetchData={fetchData}
						isTradeUnionNotEmpty={isTradeUnionNotEmpty}
						isTradeUnion={isTradeUnion}
					></View>
				) : null}
			</ProfileTemplate>
		</RedirectUnAuthUser>
	)
}
