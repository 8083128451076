export function updateStaffingStructure(childs, targetId, newData) {
	return childs.map((child) => {
		if (child.externalId === targetId) {
			const filteredData = Object.entries(newData).reduce((acc, [key, value]) => {
				if (value !== null && value !== '' && value !== undefined) {
					acc[key] = value
				}
				return acc
			}, {})
			const updatedChild = { ...child, ...filteredData }
			Object.keys(newData).forEach((key) => {
				if (!(key in filteredData)) {
					updatedChild[key] = child[key]
				}
			})
			return updatedChild // возвращаем обновленный объект
		} else if (child.childs) {
			return { ...child, childs: updateStaffingStructure(child.childs, targetId, newData) } // обновляем вложенные childs
		} else {
			return child // возвращаем оригинальный объект для всех остальных элементов
		}
	})
}
