import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ModalTemplate, Title } from '@/ui'
import styles from './RemoveStruct.module.scss'

export const RemoveModal = ({ onSubmit, onClose, type }) => {
	const { t } = useTranslation()

	// Маппинг типа на текст
	const typeToTextMap = {
		workplace: t('staff.remove_workplace'),
		group: t('staff.remove_group'),
		structure: t('staff.remove_struct'),
	}

	// Получаем текст на основании type, по умолчанию – t('confirm_delete_default')
	const text = typeToTextMap[type] || t('confirm_delete_default')

	return (
		<ModalTemplate onClose={onClose} hideBackButton={true}>
			<div className={styles.content}>
				<Title color={'darkBlue'}>{t('attention')}</Title>
				<div className={styles.sizeText16}>{text}</div>
			</div>
			<div className={styles.buttonStyle}>
				<Button type="submit" onClick={onSubmit}>
					{t('delete_yes')}
				</Button>
				<Button type="bare" onClick={onClose}>
					{t('no_cancel')}
				</Button>
			</div>
		</ModalTemplate>
	)
}
