export function removeGroupByExternalId(data, externalIdToRemove) {
	function recursiveRemove(childs) {
		if (!childs) return []
		const result = []

		for (const child of childs) {
			// Создаём копию child для предотвращения мутации
			const newChild = { ...child }

			if (newChild.positionGroups) {
				// Отфильтровываем positionGroups, чтобы удалить группы по externalId
				const filteredGroups = newChild.positionGroups.filter((group) => group.externalId !== externalIdToRemove)
				if (filteredGroups.length !== newChild.positionGroups.length) {
					// Найдена и удалена группа, возвращаем текущий результат
					newChild.positionGroups = filteredGroups
					result.push(newChild)
					return result.concat(childs.slice(childs.indexOf(child) + 1))
				}
				newChild.positionGroups = filteredGroups
			}

			if (newChild.childs) {
				// Рекурсивно обрабатываем childs
				const updatedChildren = recursiveRemove(newChild.childs)
				if (updatedChildren.length !== newChild.childs.length) {
					newChild.childs = updatedChildren
					result.push(newChild)
					return result.concat(childs.slice(childs.indexOf(child) + 1))
				}
				newChild.childs = updatedChildren
			}
			result.push(newChild)
		}

		return result
	}
	return recursiveRemove(data)
}
