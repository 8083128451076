import React, { useEffect } from 'react'
import { ModalTemplate, Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import styles from './StaffingPositionForm.module.scss'
import { Formik } from 'formik'
import { Form } from './Form'
import { useDispatch, useSelector } from 'react-redux'
import { contractActionsSelectors } from '@features/contracts/model'
import { staffingActions, staffingSelectors, staffingThunks } from '@/entity'
import { ValidationSchema } from './ValidationSchema'
import { useDigitalSign } from '@/shared'

export const StaffingPositionForm = ({ initialValues, onClose, actions, workPlaceRowSelect }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const options = useSelector(contractActionsSelectors.options)
	const { successPayload, onSign, failedPayload, onClearPayload } = useDigitalSign()
	const employeeEditXML = useSelector(staffingSelectors.employeeEditXML)

	useEffect(() => {
		failedPayload && onClear()
	}, [failedPayload])

	useEffect(() => employeeEditXML && onSign(employeeEditXML), [employeeEditXML])

	useEffect(() => {
		if (successPayload) {
			dispatch(staffingThunks.sendEditWorkPlaceEsutd(initialValues.workPlaceId, { signXml: successPayload }))
			onClose()
		}
	}, [successPayload])

	const onClear = () => {
		onClearPayload()
		dispatch(staffingActions.setEditEmployeeXml(null))
	}

	const preparedOnSubmit = (data) => {
		const {
			externalId,
			contractExternalId,
			factSalary,
			grossSalary,
			salarySupplement,
			iin,
			fullName,
			tariffRate,
			beginDate,
			endDate,
			reasonForAbsence,
			workPlaceId,
			substitutionExternalId,
			isCombining,
			positionCode,
			establishedPost,
		} = data

		//Добавлена трансформация объекта назначения, чтобы отправлять только нужные поля
		let transformedWorkPlace = {
			externalId,
			contractExternalId,
			factSalary,
			grossSalary,
			salarySupplement,
			iin,
			fullName,
			tariffRate,
			beginDate,
			endDate,
			reasonForAbsence,
			workPlaceId,
			substitutionExternalId,
			isCombining,
			positionCode,
			establishedPost,
		}
		if (initialValues.statusCode === 'SENT') {
			dispatch(staffingThunks.saveWorkPlaceEsutd(transformedWorkPlace))
		} else {
			onSubmit(transformedWorkPlace)
		}
	}

	const onSubmit = (transformedWorkPlace) => {
		dispatch(staffingThunks.saveWorkPlace(transformedWorkPlace, workPlaceRowSelect.externalId))
		onClose()
	}

	return (
		<ModalTemplate onClose={onClose} priority={actions.edit || actions.create ? 2 : 1}>
			<div className={`scroll ${styles.wrapper}`}>
				<div className={styles.mt10}>
					<Title color="black">
						{actions.edit
							? `${t('workplace.edit_position')} ${t('staff_modal.title_position')}`
							: t('staff_modal.title_position')}
					</Title>
					<Formik
						validationSchema={ValidationSchema}
						initialValues={initialValues}
						options={options}
						onSubmit={preparedOnSubmit}
					>
						<Form actions={actions} onClose={onClose} workPlaceRowSelect={workPlaceRowSelect}></Form>
					</Formik>
				</div>
			</div>
		</ModalTemplate>
	)
}
