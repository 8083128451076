import { api } from '@/shared'
import { referenceInformationActions } from '@/entity/referenceInformation/index'
import fileDownload from 'js-file-download'
import dayjs from 'dayjs'

export const getAgreementsData = () => {
	return async (dispatch) => {
		try {
			dispatch(referenceInformationActions.getAgreementsDataAction())

			const res = await api.referenceInformation.getFileInfo()

			if(res){
				dispatch(referenceInformationActions.getAgreementsDataActionDone(res))
			}

		} catch (error) {
			dispatch(referenceInformationActions.getAgreementsDataActionFail())
		}
	}
}

export const getDownloadByIdData = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(referenceInformationActions.getOneAgreementAction())

			const res = await api.referenceInformation.getFileByIdInfo(payload.id)

			if(res){
				dispatch(referenceInformationActions.getOneAgreementActionDone(res))

				fileDownload(res, payload.fileName)
			}

		} catch (error) {
			dispatch(referenceInformationActions.getOneAgreementActionFail())
		}
	}
}

export const getFaqData = () => {
	return async (dispatch) => {
		try {
			dispatch(referenceInformationActions.getFaqDataAction())

			const res = await api.referenceInformation.getFaq()
			if(res){
				dispatch(referenceInformationActions.getFaqDataActionDone(res))
			}

		} catch (error) {
			dispatch(referenceInformationActions.getFaqDataActionFail())
		}
	}
}

export const getFaqDict = () => {
	return async (dispatch) => {
		try {
			dispatch(referenceInformationActions.getFaqDictAction())

			const res = await api.referenceInformation.getDict()
			if(res){
				dispatch(referenceInformationActions.getFaqDictActionDone(res))
			}

		} catch (error) {
			dispatch(referenceInformationActions.getFaqDictActionFail())
		}
	}
}