import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ProfileTemplate } from '@/widgets'
import { Button } from '@/ui'
import { useDispatch, useSelector } from 'react-redux'
import { profUnionsSelectors, profUnionsThunks, userSelectors } from '@/entity'
import { useTranslation } from 'react-i18next'
import { InfoDevelopment } from '@/components/infoDevelopment'
import { ProfUnions } from '@/features/profUnions/ProfUnions'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { Docs } from '@features/profUnions/info-table/Docs'
import styles from './GeneralInformationPage.module.scss'
import { Composition } from '@features/profUnions/info-table/Composition'
import { getFromLocalStorage } from '@/shared'
import { UnionsData } from '@features/profUnions/info-table/UnionsData'
import { CancelCircle, Checked } from '@app/icons'

export const GeneralInformationPage = ({ buttonClassName = '' }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const user = useSelector(userSelectors.user)
	const activeRole = getFromLocalStorage('activeRole')
	const tradeUnion = useSelector(profUnionsSelectors.tradeUnion)
	const [isTradeUnionNotEmpty, setIsTradeUnionNotEmpty] = useState(false)

	useEffect(() => {
		if (!!tradeUnion && !!tradeUnion.externalId) {
			setIsTradeUnionNotEmpty(true)
		} else {
			setIsTradeUnionNotEmpty(false)
		}
	}, [tradeUnion])

	const approveTradeUnion = useCallback((externalId) => {
		dispatch(profUnionsThunks.approveTradeUnion(externalId, true, 'ROLE_EMPLOYEE' === activeRole))
	}, [dispatch])

	const rejectTradeUnion = useCallback((externalId) => {
		dispatch(profUnionsThunks.rejectTradeUnion(externalId, true, 'ROLE_EMPLOYEE' === activeRole))
	}, [dispatch])

	useEffect(() => {
		dispatch(profUnionsThunks.getOptions())
		dispatch(profUnionsThunks.getTradeUnion('ROLE_EMPLOYEE' === activeRole))
	}, [])

	const [actions, setActions] = useState({
		create: false,
		view: false,
		edit: false,
	})

	const onToggleAction = (action, value) => {
		setActions((prevState) => {
			return { ...prevState, [action]: value }
		})
	}

	const Close = () => {
		onToggleAction('create', false)
		onToggleAction('edit', false)
	}

	const isVisibleApproveRejectButton = tradeUnion.status === "APPROVED" &&
		tradeUnion.statusUnion === "DRAFT"


	return (
		<ProfileTemplate title={'profUnions.name'}>
			{
				(
					isVisibleApproveRejectButton
				) &&
				<div className={styles.infoWrapper}>
					<InfoDevelopment
						title={t('profUnions.approveProfUnionsField')}
						width48={true}
						child={
							<div className={styles.actions}>
								{
									isVisibleApproveRejectButton &&
									<>
										<Button
											onClick={() => approveTradeUnion(tradeUnion.externalId)}
											buttonClassName={styles.button}
											textColor="green"
											borderColor="green"
											transparent
											border
										>
											<Checked className={styles.iconGreen} />
											{t('actions.approve')}
										</Button>
										<Button
											onClick={() => rejectTradeUnion(tradeUnion.externalId)}
											buttonClassName={styles.buttonRed}
											textColor="red"
											borderColor="red"
											transparent
											border
										>
											<CancelCircle className={styles.iconGreen} />
											{t('actions.reject')}
										</Button>
									</>
								}
							</div>
						}
					>
					</InfoDevelopment>
				</div>

			}
			{!isTradeUnionNotEmpty ? (
				<Button
					onClick={() => {
						onToggleAction('create', true)
					}}
					buttonClassName={buttonClassName}
					disabled={user?.manager}
				>
					{t('profUnions.addGeneralInformation')}
				</Button>
			) : null}
			<div className={styles.wrapper}>
				{!isTradeUnionNotEmpty ? (
					<InfoDevelopment
						title={'ROLE_EMPLOYEE' === activeRole ? t('profUnions.info_list_employee') : t('profUnions.info')}
						width48={true}
					/>
				) : null}
				{actions.create || actions.edit ? (
					<ProfUnions
						onCancel={Close}
						actions={actions}
						activeCompany={activeCompany}
						cabinetTradeUnion={true}
						tradeUnion={tradeUnion}
					></ProfUnions>
				) : null}
				{isTradeUnionNotEmpty && !!tradeUnion ? (
					<>
						<UnionsData cabinetTradeUnion={true} tradeUnion={tradeUnion} onToggleAction={onToggleAction} isEmployee={'ROLE_EMPLOYEE' === activeRole}></UnionsData>
						<Docs tradeUnion={tradeUnion} onToggleAction={onToggleAction}></Docs>
						<Composition tradeUnion={tradeUnion} onToggleAction={onToggleAction}></Composition>
					</>
				) : null}
			</div>
		</ProfileTemplate>
	)
}
