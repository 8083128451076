import { RedirectUnAuthUser } from '@/processes'
import { ProfileTemplate } from '@/widgets'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentLanguage } from '@/i18next'
import { Tab, TabsList, Tabs, TabPanel } from '@mui/base'
import styles from './RequirementLaborResources.module.scss'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Table } from '@features/requirementLaborRecources'
import { Table as TableAntd } from 'antd'
import cl from 'classnames'
import { Tag } from 'antd'
import { requirementLaborResourcesThunks } from '@/entity/requirementLaborResources'
import { LoadingBlocker } from '@/components/Loader'
import { Alert } from '../../components/Alert/Alert'
import { InfoGreen } from '@app/icons'
import { Icon } from '@/components/Icon'
import { formatNumber } from '@/utils'

const TAB_KEY = {
	agroProducerDto: 'agroProducerDto',
	animalHusbandryDto: 'animalHusbandryDto',
	cropCultivationDto: 'cropCultivationDto',
}

const LANG = {
	kz: 'kz',
	rus: 'rus',
}

const Badge = ({
								 value,
								 label,
							 }) => {
	return (
		<div className={styles.badge}>
			<p className={styles.badge__value}>
				{value}
			</p>
			<p className={styles.badge__label}>
				{label}
			</p>
		</div>
	)
}

export const RequirementLaborResources = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const lang = getCurrentLanguage()

	const { dataState } = useSelector((state) => ({
		dataState: state.requirementLaborResources.data,
	}))

	useEffect(() => {
		dispatch(requirementLaborResourcesThunks.getData())
	}, [])

	const [activeTab, setActiveTab] = useState({
		1: null,
		2: null,
	})

	const renderAgroProducerDto = useMemo(() => {
		const columnLandRegistry = [
			{
				key: 'kdrNum',
				dataIndex: 'kdrNum',
				title: t('requirement_labor_resources.cadastral_number'),
				width: '50%',
			},
			{
				key: 'square',
				dataIndex: 'square',
				title: t('requirement_labor_resources.area_ha'),
				width: '50%',
			},
		]

		const columnSownAreas = [
			{
				key: 'cropName',
				dataIndex: 'cropName',
				title: t('requirement_labor_resources.crop'),
				width: '33%',
				render: (text, record, index) => {
					let label = {
						[LANG.kz]: record.cropKz,
						[LANG.rus]: record.cropRu,
					}
					return label[lang]
				},
			},
			{
				key: 'squareTotal',
				dataIndex: 'squareTotal',
				title: t('requirement_labor_resources.area_ha'),
				width: '33%',
				render: (text, record, index) => {
					return formatNumber(text || '')
				}
			},
			{
				key: 'maxTotalHeadCount',
				dataIndex: 'maxTotalHeadCount',
				title: t('requirement_labor_resources.norm_count_season'),
				width: '33%',
				render: (text, record, index) => {
					return formatNumber(text || '')
				}
			},
		]

		const columnlivestockCount = [
			{
				key: 'livestockName',
				dataIndex: 'livestockName',
				title: t('requirement_labor_resources.animals'),
				width: '33%',
				render: (text, record, index) => {
					let label = {
						[LANG.kz]: record.kindNameKz,
						[LANG.rus]: record.kindNameRu,
					}
					return label[lang]
				},
			},
			{
				key: 'count',
				dataIndex: 'count',
				title: t('requirement_labor_resources.headcount'),
				width: '33%',
				render: (text, record, index) => {
					return formatNumber(text || '')
				}
			},
			{
				key: 'maxHeadCount',
				dataIndex: 'maxHeadCount',
				title: t('requirement_labor_resources.norm_count_season'),
				width: '33%',
				render: (text, record, index) => {
					return formatNumber(text || '')
				}
			},
		]

		const renderArray = [
			...(dataState?.data?.agroProducerDto?.landRegistryDtos?.length ? [{
				title: t('requirement_labor_resources.land_fund'),
				render: (
					<Table
						columns={columnLandRegistry}
						dataSource={dataState?.data?.agroProducerDto?.landRegistryDtos}
						pagination={false}
						scroll={{y: 500}}
						rowKey={(record) => `${record.kdrNum}-${record.square}`}
						summary={(pageData) => {
							return (
								dataState?.data?.agroProducerDto?.totalSquare ?
								<TableAntd.Summary.Row>
									<TableAntd.Summary.Cell index={0}>{t('requirement_labor_resources.total')}</TableAntd.Summary.Cell>
									<TableAntd.Summary.Cell index={1}>{formatNumber(dataState?.data?.agroProducerDto?.totalSquare || '')}</TableAntd.Summary.Cell>
								</TableAntd.Summary.Row>
									: null
							)
						}}
					/>
				),
			}] : []),
			...(dataState?.data?.agroProducerDto?.sownAreasDto?.length ? [{
				title: t('requirement_labor_resources.sowing_area'),
				render: (
					<Table
						columns={columnSownAreas}
						dataSource={dataState?.data?.agroProducerDto?.sownAreasDto}
						pagination={false}
						scroll={{y: 500}}
						rowKey={(record) => `${record.cropName}-${record.squareTotal}`}
						summary={(pageData) => {
							return (
								(dataState?.data?.agroProducerDto?.totalSownAreas || dataState?.data?.agroProducerDto?.totalMaxSownAreaHeadCount) ?
								<TableAntd.Summary.Row>
									<TableAntd.Summary.Cell index={0}>{t('requirement_labor_resources.total')}</TableAntd.Summary.Cell>
									<TableAntd.Summary.Cell index={1}>{formatNumber(dataState?.data?.agroProducerDto?.totalSownAreas || '')}</TableAntd.Summary.Cell>
									<TableAntd.Summary.Cell index={1}>{formatNumber(dataState?.data?.agroProducerDto?.totalMaxSownAreaHeadCount || '')}</TableAntd.Summary.Cell>
								</TableAntd.Summary.Row>
									: null
							)
						}}
					/>
				),
			}] : []),
			...(dataState?.data?.agroProducerDto?.livestockCountDtos?.length ? [{
				title: t('requirement_labor_resources.livestock_headcount'),
				render: (
					<Table
						columns={columnlivestockCount}
						dataSource={dataState?.data?.agroProducerDto?.livestockCountDtos}
						pagination={false}
						scroll={{y: 500}}
						rowKey={(record) => `${record.kindNameRu}-${record.count}`}
						summary={(pageData) => {
							return (
								(dataState?.data?.agroProducerDto?.totalLivestock || dataState?.data?.agroProducerDto?.totalMaxLivestockHeadCount) ?
								<TableAntd.Summary.Row>
									<TableAntd.Summary.Cell index={0}>{t('requirement_labor_resources.total')}</TableAntd.Summary.Cell>
									<TableAntd.Summary.Cell index={1}>{formatNumber(dataState?.data?.agroProducerDto?.totalLivestock || '')}</TableAntd.Summary.Cell>
									<TableAntd.Summary.Cell index={1}>{formatNumber(dataState?.data?.agroProducerDto?.totalMaxLivestockHeadCount || '')}</TableAntd.Summary.Cell>
								</TableAntd.Summary.Row>
									: null
							)
						}}
					/>
				),
			}] : [])
		]

		return (
			<div className={cl('flex', 'flex-column', 'gap-3', 'pb-4')}>
				{renderArray.map((i, idx) => (
					<div key={`renderArray-${i.title}-${idx}`} className={cl('flex', 'flex-column', 'gap-3', styles.agroTable)}>
						<h4 className={cl('mb-0')}>{i.title}</h4>
						{i.render}
					</div>
				))}
			</div>
		)
	}, [dataState.data, t, lang])

	const renderCropCultivationDto = useCallback((item) => {
		const columnCropCultivation = [
			{
				key: 'stages_of_agricultural_work',
				dataIndex: 'stages_of_agricultural_work',
				title: t('requirement_labor_resources.stages_of_agricultural_work'),
				width: 500,
			},
			{
				key: 'labor_intensity_person_hours',
				dataIndex: 'labor_intensity_person_hours',
				width: 200,
				title: t('requirement_labor_resources.labor_intensity_person_hours'),
				render: (text, record, index) => {
					return formatNumber(text || '')
				}
			},
			{
				key: 'optimal_agrotechnical_terms',
				dataIndex: 'optimal_agrotechnical_terms',
				width: 200,
				title: t('requirement_labor_resources.optimal_agrotechnical_terms'),
			},
			{
				key: 'regulated_number_of_workers_persons',
				dataIndex: 'regulated_number_of_workers_persons',
				width: 200,
				title: t('requirement_labor_resources.regulated_number_of_workers_persons'),
			},
		]

		const data = item.agriculturalCropTypeDtos?.map((crop, cropIdx) => {
			let label = {
				[LANG.kz]: crop.seasonNameKz,
				[LANG.rus]: crop.seasonNameRu,
			}
			const date = crop?.seasonWotkDtos?.find((seasonWotk, seasonWotkIdx) => seasonWotk.startDdmm)
			const headCount = crop?.seasonWotkDtos?.find((seasonWotk, seasonWotkIdx) => seasonWotk?.totalHeadCount)

			return {
				key: cropIdx,
				stages_of_agricultural_work: (
					<div>
						<span>{label[lang]}</span>

						<div className={styles.tagContainer}>
							{crop?.rawDataDtos?.filter(rowData => rowData?.rawMaterialRu)?.map((rawData, rawDataIdx) => {
								let label = {
									[LANG.kz]: rawData.rawMaterialKz,
									[LANG.rus]: rawData.rawMaterialRu,
								}
								return <Tag color="#A5AEBE" key={`Tag-${rawDataIdx}`}
														style={{ borderRadius: 4 }}>{label[lang]}-{formatNumber(rawData.resourceInput || '')} тонн</Tag>
							})}
						</div>
					</div>
				),
				labor_intensity_person_hours: crop?.totalWorkLoadBySeason,
				regulated_number_of_workers_persons: headCount?.totalHeadCount,
				optimal_agrotechnical_terms: `${date?.startDdmm || '-'}-${date?.endDdmm || ''}`,
				children: crop?.seasonWotkDtos?.map((seasonWotk, seasonWotkIdx) => {
					let label = {
						[LANG.kz]: seasonWotk.groupWorkKz,
						[LANG.rus]: seasonWotk.groupWorkRu,
					}

					return {
						key: `${cropIdx}-${seasonWotkIdx}`,
						stages_of_agricultural_work: (
							<div className={cl('flex', 'flex-column', 'gap-1')}>
								<span>{label[lang]}</span>
								<span className={styles.secondary}>{t('requirement_labor_resources.works')}:</span>
							</div>
						),
						labor_intensity_person_hours: seasonWotk?.totalWorkLoad,
						optimal_agrotechnical_terms: `-`,
						regulated_number_of_workers_persons: seasonWotk.totalHeadCount,
						children: seasonWotk?.agriculturalWorkTypes?.map((agriculturalWork, agriculturalWorkIdx) => {
							let label = {
								[LANG.kz]: agriculturalWork.workNameKz,
								[LANG.rus]: agriculturalWork.workNameRu,
							}
							return {
								key: `${cropIdx}-${seasonWotkIdx}-${agriculturalWorkIdx}`,
								stages_of_agricultural_work: label[lang],
								labor_intensity_person_hours: agriculturalWork.workLoad,
								optimal_agrotechnical_terms: `-`,
								regulated_number_of_workers_persons: seasonWotk.totalHeadCount,
							}
						}),
					}
				}),
			}
		})

		return (
			<div className={cl('flex', 'flex-column', 'gap-3')}>
				<div className={cl('flex', 'gap-3')}>
					<Badge value={formatNumber(item.sowingArea || '')} label={t('requirement_labor_resources.sowingArea')} />
					<Badge value={formatNumber(item.maxHeadCount || '')} label={t('requirement_labor_resources.worker_count')} />
				</div>

				<div className={styles.table}>
					<Table
						columns={columnCropCultivation}
						dataSource={data}
						pagination={false}
						scroll={{x: true}}
					/>
				</div>
			</div>
		)
	}, [t, lang])

	const renderAnimalHusbandryDto = useCallback((item) => {
		const columnAnimalHusbandry = [
			{
				title: t('requirement_labor_resources.work_name'),
				dataIndex: 'workName',
				key: 'workName',
				render: (text, record, index) => {
					let label = {
						[LANG.kz]: record.workNameKz,
						[LANG.rus]: record.workNameRu,
					}
					return label[lang]
				},
			},
			{
				title: t('requirement_labor_resources.profession_name'),
				dataIndex: 'professionName',
				key: 'professionName',
				render: (text, record, index) => {
					let label = {
						[LANG.kz]: record.professionNameKz,
						[LANG.rus]: record.professionNameRu,
					}
					return label[lang]
				},
			},
			{
				title: t('requirement_labor_resources.standart_number'),
				dataIndex: 'headCount',
				key: 'headCount',
				render: (text, record, index) => {
					return formatNumber(text || '')
				}
			},
		]
		return (
			<div className={cl('flex', 'flex-column', 'gap-4')}>
				<div className={cl('flex', 'gap-3')}>
					<Badge label={t('requirement_labor_resources.headcount')} value={formatNumber(item.totalAnimalCnt || '')} />
					<Badge label={t('requirement_labor_resources.worker_count')} value={formatNumber(item.totalHeadCount || '')} />
				</div>

				<Table
					columns={columnAnimalHusbandry}
					dataSource={item?.data}
					pagination={false}
					rowKey={(record) => `${record.workNameRu}-${record.headCount}`}
				/>
			</div>
		)
	}, [t, lang])

	const tabs = useMemo(() => {
		let check = dataState?.data?.agroProducerDto?.landRegistryDtos?.length || dataState?.data?.agroProducerDto?.sownAreasDto?.length || dataState?.data?.agroProducerDto?.livestockCountDtos?.length

		return [
			{
				key: TAB_KEY.agroProducerDto,
				label: t('requirement_labor_resources.agroProducer'),
				render: check ? renderAgroProducerDto : (
					<Alert icon={<Icon component={InfoGreen} />} text={t('requirement_labor_resources.empty_alert')}/>
				),
			},
			{
				key: TAB_KEY.cropCultivationDto,
				label: t('requirement_labor_resources.cropCultivation'),
				...(dataState.data?.cropCultivationDto ? {
					children: dataState.data?.cropCultivationDto?.map((item, index) => {
						let label = {
							[LANG.kz]: item.corpNameKz,
							[LANG.rus]: item.corpNameRu,
						}
						return ({
							key: `cropCultivationDto-${index}`,
							label: label[lang],
							render: renderCropCultivationDto(item),
						})
					})
				} : {
					render: <Alert icon={<Icon component={InfoGreen} />} text={t('requirement_labor_resources.empty_alert')}/>
				})
			},
			{
				key: TAB_KEY.animalHusbandryDto,
				label: t('requirement_labor_resources.animalHusbandry'),
				...(dataState.data?.cropCultivationDto ? {
					children: dataState.data?.animalHusbandryDto?.map((item, index) => {
						let label = {
							[LANG.kz]: item.animalsNameKz,
							[LANG.rus]: item.animalsNameRu,
						}
						return ({
							key: `animalHusbandryDto-${index}`,
							label: label[lang],
							render: renderAnimalHusbandryDto(item),
						})
					})
				} : {
					render: <Alert icon={<Icon component={InfoGreen} />} text={t('requirement_labor_resources.empty_alert')}/>
				})
			},
		]
	}, [dataState.data, t, lang])

	useEffect(() => {
		if (dataState.data) {
			let res = TAB_KEY.agroProducerDto
			setActiveTab({
				1: res,
				2: null,
			})
		}
	}, [dataState.data])

	const handleChangeTab = (tabs) => {
		setActiveTab(tabs)
	}

	return (
		<>
			<RedirectUnAuthUser>
				<ProfileTemplate title={'requirement_labor_resources.title'}>
					{
						dataState?.success && (
							<Tabs
								value={activeTab[1]}
								onChange={(e, value) => handleChangeTab({
									1: value,
									2: tabs?.find(item => item.key === value)?.children?.[0]?.key,
								})}
								className={styles.tabOuter}>
								<TabsList className={styles.tabOuter__list}>
									{tabs?.map((item) => (
										<Tab value={item?.key} key={item?.key} id={item.key} className={styles.tabOuter__item}>
											{item?.label}
										</Tab>
									))}
								</TabsList>
								{tabs?.map((item) => (
									<TabPanel key={item.key} value={item.key}>
										{
											item?.children ?
												(
													<Tabs
														value={activeTab?.[2]}
														onChange={(e, value) => handleChangeTab({ 1: activeTab[1], 2: value })}
														className={styles.tabInner}
													>
														<TabsList>
															{item.children?.map((inner) => (
																<Tab value={inner?.key} key={inner?.key} id={inner?.key} className={styles.tabInner__item}>
																	{inner?.label}
																</Tab>
															))}
														</TabsList>
														{item.children?.map((inner) => (
															<TabPanel key={inner?.key} value={inner?.key}>
																<div className={styles.content_wrapper}>
																	{inner.render}
																</div>
															</TabPanel>
														))}
													</Tabs>
												)
												:
												item.render
										}

									</TabPanel>
								))}
							</Tabs>
						)
					}
				</ProfileTemplate>
			</RedirectUnAuthUser>


			{(dataState.loading) && <LoadingBlocker />}
		</>
	)
}