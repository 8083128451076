import { useTranslation } from 'react-i18next'
import styles from './IndustryAgreement.module.scss'
import { Field as FormikField, FieldArray, Form as FormikForm, useFormikContext } from 'formik'
import { Button, Field } from '@/ui'
import { Title } from '@/ui'
import React, { useEffect, useState } from 'react'
import { formFieldNames } from '../../lib/constants/formFieldNames'
import { Add, Trash } from '@app/icons'
import { Coefficients } from '@/entity/industryAgreement/ui/form/fields'
import { getCurrentLanguage } from '@/i18next'
import { ConfirmDocument } from '@/components/AddDocuments/ConfirmDocument'
import { UnionNameField } from '@/entity/collectiveContracts/ui/form/CollectiveForm'
import { api } from '@/shared'
import { message } from 'antd'
import { Loader } from '@/components/Loader'
import { ExtraGuarantee } from '@/entity/industryAgreement/ui/form/ExtraGuarantee/ExtraGuarantee'

export const IndustryAgreementForm = ({
	industryAgreement,
	extraGuaranteesCollectiveContract,
	attachFile,
	files,
	onCancel,
	isEdit,
}) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const { values, setFieldValue } = useFormikContext()
	const [documentList, setDocList] = useState([])
	const [disableErrorSize, setDisableErrorSize] = useState(true)
	const [checkDoc, setCheckDoc] = useState(true)
	const [isSearchUnionBin, setIsSearchUnionBin] = useState()

	useEffect(() => {
		setCheckDoc(documentList && !disableErrorSize)
	}, [documentList, disableErrorSize])

	const getDocument = (docList) => {
		setDocList(docList)
		attachFile(docList)
	}

	const getErrorSize = (getErrorSize) => {
		setDisableErrorSize(getErrorSize)
	}

	const removeUnion = (index) => {
		const updatedUnions = [...values.tradeUnionNames]
		updatedUnions.splice(index, 1)
		setFieldValue('tradeUnionNames', updatedUnions)
	}

	const removeAssociation = (index) => {
		const updatedAssociations = [...values.employerAssociationNames]
		updatedAssociations.splice(index, 1)
		setFieldValue('employerAssociationNames', updatedAssociations)
	}

	const addUnion = () => {
		setFieldValue('tradeUnionNames', [
			...values.tradeUnionNames,
			{
				tradeUnionNameKz: '',
				tradeUnionNameRu: '',
				tradeUnionBin: '',
			},
		])
	}

	const addAssociation = () => {
		if (values.employerAssociationNames) {
			setFieldValue('employerAssociationNames', [
				...values.employerAssociationNames,
				{
					employerAssociationNameKz: '',
					employerAssociationNameRu: '',
				},
			])
		} else {
			setFieldValue('employerAssociationNames', [
				{
					employerAssociationNameKz: '',
					employerAssociationNameRu: '',
				},
			])
		}
	}

	const removeGuarantee = (index) => {
		const updatedGuarantee = [...values.extraGuarantee]
		updatedGuarantee.splice(index, 1)
		setFieldValue('extraGuarantee', updatedGuarantee)
	}

	const addGuarantee = () => {
		setFieldValue(`extraGuarantee`, [
			...values.extraGuarantee,
			{
				code: '',
				valueKz: '',
				valueRu: '',
			},
		])
	}

	const getGuaranteeLabel = (id) => {
		const guarantee = extraGuaranteesCollectiveContract.find((item) => item.code === id.toString())
		return guarantee[lang]
	}

	const handleSearchUnionBin = async (bin, unionIndex) => {
		if (bin.length < 12) {
			return
		}
		setIsSearchUnionBin(unionIndex)
		try {
			const { fullNameKz, fullNameRu } = await api.profile.getEmployerActivityByBIN({ bin: bin })
			if (!fullNameKz || !fullNameRu) {
				return message.error(t('industryAgreement.error_message'))
			}
			setIsSearchUnionBin(undefined)
			setFieldValue(`tradeUnionNames[${unionIndex}].tradeUnionBin`, bin)
			setFieldValue(`tradeUnionNames[${unionIndex}].tradeUnionNameKz`, fullNameKz)
			setFieldValue(`tradeUnionNames[${unionIndex}].tradeUnionNameRu`, fullNameRu)
		} catch (error) {
			setIsSearchUnionBin(undefined)
		} finally {
			setIsSearchUnionBin(undefined)
		}
	}

	const handleChange = (event, unionIndex) => {
		setFieldValue(`tradeUnionNames.${unionIndex}.tradeUnionNameKz`, event)
		setFieldValue(`tradeUnionNames.${unionIndex}.tradeUnionNameRu`, event)
	}

	return (
		<FormikForm className={styles.form}>
			<section>
				<Title>{t('industryAgreement.agreement_data')}</Title>
				<div className={styles.fields}>
					<FormikField name={formFieldNames.industryAgreementNameKz}>
						{({ field, meta: { touched, error } }) => (
							<Field
								type="text"
								label={t('industryAgreement.industryAgreementNameKz')}
								placeholder={t('industryAgreement.placeholder_name')}
								error={touched && error}
								{...field}
								isRequired
							/>
						)}
					</FormikField>
					<FormikField name={formFieldNames.industryAgreementNameRu}>
						{({ field, meta: { touched, error } }) => (
							<Field
								type="text"
								label={t('industryAgreement.industryAgreementNameRu')}
								placeholder={t('industryAgreement.placeholder_name')}
								error={touched && error}
								{...field}
								isRequired
							/>
						)}
					</FormikField>
					<FormikField name={formFieldNames.authStateNameKz}>
						{({ field, meta: { touched, error } }) => (
							<Field
								type="text"
								label={t('industryAgreement.authStateNameKz')}
								placeholder={t('industryAgreement.placeholder_name')}
								error={touched && error}
								{...field}
								isRequired
							/>
						)}
					</FormikField>
					<FormikField name={formFieldNames.authStateNameRu}>
						{({ field, meta: { touched, error } }) => (
							<Field
								type="text"
								label={t('industryAgreement.authStateNameRu')}
								placeholder={t('industryAgreement.placeholder_name')}
								error={touched && error}
								{...field}
								isRequired
							/>
						)}
					</FormikField>
					<div>
					<FormikField name={formFieldNames.industryCodes}>
						{({ field, meta: { touched, error } }) => (
							<Field
								type="text"
								fieldType="multipleSelect"
								isRequired
								label={t('industryAgreement.industryAgreement')}
								placeholder={t('select_from_list')}
								options={industryAgreement}
								error={touched && error}
								{...field}
							/>
						)}
					</FormikField>
					</div>
				</div>
			</section>
			<section>
				<Title>{t('industryAgreement.information_industry')}</Title>
				{values.tradeUnionNames.map((union, unionIndex) => (
					<React.Fragment key={unionIndex}>
						<div className={unionIndex ? styles.fieldAndRemoveButton : null}>
							<p className={styles.subTitle}>
								{t('industryAgreement.union')} {unionIndex + 1}
							</p>
							{unionIndex > 0 && (
								<Button
									transparent
									onClick={() => removeUnion(unionIndex)}
									buttonClassName={styles.buttonRed}
									disabled={false}
								>
									<Trash className={styles.iconRed} />
									{t('remove')}
								</Button>
							)}
						</div>
						<div className={styles.fields__bin__wrapper}>
							<div className={styles.fieldAndButton}>
								<FormikField name={`tradeUnionNames.${unionIndex}.tradeUnionBin`}>
									{({ field, meta: { touched, error } }) => (
										<Field
											{...field}
											placeholder={t('enter_count_digitals', { count: 12 })}
											error={touched && error ? t(error) : ''}
											wrapperClassName={styles.field__input}
											label={t('industryAgreement.bin')}
											maxLength={12}
											pattern="[0-9]{12}"
											onInput={(e) => {
												e.target.value = e.target.value.replace(/[^0-9]/g, '')
											}}
										/>
									)}
								</FormikField>
								{isSearchUnionBin === unionIndex ? (
									<div className={styles.loading}>
										<Loader></Loader>
									</div>
								) : (
									<Button
										disabled={!union?.tradeUnionBin}
										onClick={() => handleSearchUnionBin(union?.tradeUnionBin, unionIndex)}
									>
										{t('find')}
									</Button>
								)}
							</div>
							{lang === 'rus' ? (
								<UnionNameField
									unionIndex={unionIndex}
									handleChange={handleChange}
									fieldName={`tradeUnionNames.${unionIndex}.tradeUnionNameRu`}
									disabled={true}
								/>
							) : (
								<UnionNameField
									unionIndex={unionIndex}
									handleChange={handleChange}
									fieldName={`tradeUnionNames.${unionIndex}.tradeUnionNameKz`}
									disabled={true}
								/>
							)}
						</div>
					</React.Fragment>
				))}
				<Button transparent onClick={addUnion} buttonClassName={styles.button}>
					<Add className={styles.iconGreen} />
					{t('industryAgreement.add_union')}
				</Button>
			</section>
			<section>
				<Title>{t('industryAgreement.information_associations')}</Title>
				<FieldArray name="employerAssociationNames">
					{(props) => {
						return (
							<div>
								{!!values.employerAssociationNames && values.employerAssociationNames.map((association, associationIndex) => (
									<React.Fragment key={associationIndex}>
										<div className={associationIndex ? styles.fieldAndRemoveButton : null}>
											<p className={styles.subTitle}>
												{t('industryAgreement.associations')} {associationIndex + 1}
											</p>
											{associationIndex > 0 && (
												<Button
													transparent
													onClick={() => removeAssociation(associationIndex)}
													buttonClassName={styles.buttonRed}
													disabled={false}
												>
													<Trash className={styles.iconRed} />
													{t('remove')}
												</Button>
											)}
										</div>
										<div className={styles.fields}>
											<FormikField name={`employerAssociationNames.${associationIndex}.employerAssociationNameKz`}>
												{({ field, meta, form }) => {
													return (
														<Field
															type="text"
															label={t('industryAgreement.employerAssociationNameKz')}
															placeholder={t('industryAgreement.placeholder_name')}
															error={!meta.value && form.errors?.employerAssociationNames?.[associationIndex]}
															{...field}
														/>
													)
												}}
											</FormikField>
											<FormikField name={`employerAssociationNames.${associationIndex}.employerAssociationNameRu`}>
												{({ field, meta, form }) => {
													return (
														<Field
															type="text"
															label={t('industryAgreement.employerAssociationNameRu')}
															placeholder={t('industryAgreement.placeholder_name')}
															error={!meta.value && form.errors?.employerAssociationNames?.[associationIndex]}
															{...field}
														/>
													)
												}}
											</FormikField>
										</div>
									</React.Fragment>
								))}
							</div>
						)
					}}
				</FieldArray>

				<Button transparent onClick={addAssociation} buttonClassName={styles.button}>
					<Add className={styles.iconGreen} />
					{t('industryAgreement.add_association')}
				</Button>
			</section>
			<section>
				<Title>{t('industryAgreement.information')}</Title>
				<div className={`${styles.fields} ${styles.threeFields}`}>
					<FormikField name={formFieldNames.createDate}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType="datePicker"
								label={t('industryAgreement.createDate')}
								isRequired
								placeholder={t('default_date_value')}
								minDate={new Date(1900, 0, 1)}
								error={touched && error}
								{...field}
							/>
						)}
					</FormikField>
					<FormikField name={formFieldNames.dateFrom}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType="datePicker"
								label={t('industryAgreement.dateFrom')}
								isRequired
								placeholder={t('default_date_value')}
								minDate={new Date(1900, 0, 1)}
								error={touched && error}
								{...field}
							/>
						)}
					</FormikField>
					<FormikField name={formFieldNames.dateTo}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType="datePicker"
								label={t('industryAgreement.dateTo')}
								isRequired
								placeholder={t('default_date_value')}
								minDate={new Date(1900, 0, 1)}
								error={touched && error}
								{...field}
							/>
						)}
					</FormikField>
				</div>
			</section>
			<section>
				<Title>{t('col_contract.different_coefficient')}</Title>
				<Coefficients />
			</section>
			<ExtraGuarantee
				values={values}
				extraGuaranteesCollectiveContract={extraGuaranteesCollectiveContract}
				removeGuarantee={removeGuarantee}
				addGuarantee={addGuarantee}
				getGuaranteeLabel={getGuaranteeLabel}
				t={t}
				title={t('industryAgreement.guarantee_title')}
			/>
			{isEdit ? (
				<section>
					<Title>{t('industryAgreement.edit_info')}</Title>
					<div className={styles.fields}>
						<div className={`${styles.fields} ${styles.threeFields}`}>
							<FormikField name={`updatesList[0].updateDateTime`}>
								{({ field, meta: { touched, error } }) => (
									<Field
										fieldType="datePicker"
										label={t('industryAgreement.updateDateTime')}
										isRequired
										placeholder={t('default_date_value')}
										minDate={new Date(1900, 0, 1)}
										error={touched && error}
										{...field}
									/>
								)}
							</FormikField>
						</div>
						<FormikField name={`updatesList[0].updateTextKz`}>
							{({ field, meta: { touched, error } }) => (
								<Field
									type="text"
									fieldType={'textarea'}
									isRequired
									label={t('industryAgreement.updateTextKz')}
									placeholder={t('industryAgreement.updateText_placeholder')}
									error={touched && error ? t(error) : ''}
									{...field}
									showCount
									maxLength={4000}
									rows={6}
									{...field}
								/>
							)}
						</FormikField>
						<FormikField name={`updatesList[0].updateTextRu`}>
							{({ field, meta: { touched, error } }) => (
								<Field
									type="text"
									fieldType={'textarea'}
									isRequired
									label={t('industryAgreement.updateTextRu')}
									placeholder={t('industryAgreement.updateText_placeholder')}
									error={touched && error ? t(error) : ''}
									{...field}
									showCount
									maxLength={4000}
									rows={6}
									{...field}
								/>
							)}
						</FormikField>
					</div>
				</section>
			) : null}
			<section>
				<Title>
					{t('industryAgreement.scanned')}
					{isEdit ? '' : ' *'}
				</Title>
				<ConfirmDocument
					getDocument={getDocument}
					getErrorSize={getErrorSize}
					fileType={'INDUSTRYAGREEMENT'}
					fileList={files}
				></ConfirmDocument>
			</section>
			<div className={styles.actions}>
				<Button type="bare" onClick={onCancel}>
					{t('cancel')}
				</Button>
				<Button disabled={!checkDoc} type="submit">
					{t('save')}
				</Button>
			</div>
		</FormikForm>
	)
}
