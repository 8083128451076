import React, { useMemo, useCallback, useEffect, useState } from 'react'
import { Field as FormikField, connect } from 'formik'
import { Field } from '@/ui'
import styles from './ContractPosition.module.scss'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import { usePrevious } from '@/shared'

export const ContractPosition = connect(
	({
		positions = [],
		positionsByKnz = [],
		workCondition = [],
		positionsByKnzFull = [],
		establishedPostPositionCategoryRelation = [],
		formik: { setFieldValue, values, errors },
		isFormDirty,
		isElectronicButton = true,
		immigrant = false,
		lmc = false, //Признак организации ЦТМ (центров трудовой мобильности)
		isEdit,
	}) => {
		const { t } = useTranslation()
		const lang = getCurrentLanguage()

		const { dpositionCode, dworkingConditionsCode } = values

		const prevValue = usePrevious(dworkingConditionsCode)

		const [currentWorkCondition, setCurrentWorkCondition] = useState(workCondition)
		const [filteredPositionsByKnzByLmc, setFilteredPositionsByKnzByLmc] = useState(positionsByKnz)
		const [isLmcDisabled, setIsLmcDisabled] = useState(true)

		let GetPositionTree = (code) => {
			const itemMap = {}
			positionsByKnzFull.forEach((item) => {
				itemMap[item.id] = item
			})
			const parentNames = []
			let currentItem = positionsByKnz.find((i) => i.code === code)

			while (currentItem && currentItem.parentId !== null) {
				parentNames.unshift(lang === 'rus' ? currentItem.rus : currentItem.kz)
				currentItem = itemMap[currentItem.parentId]
			}
			setFieldValue('positionParents', parentNames.join(' / '))
		}

		useEffect(() => {
			if (!lmc) {
				setIsLmcDisabled(false)
			}
		}, [])

		useEffect(() => {
			if (lmc) {
				if (filteredPositionsByKnzByLmc.length === 1) {
					setFieldValue('dpositionCode', filteredPositionsByKnzByLmc[0]?.code)
				}
			}
		}, [filteredPositionsByKnzByLmc, setFieldValue])

		useEffect(() => {
			if (!!dpositionCode) {
				GetPositionTree(dpositionCode)
			} else setFieldValue('positionParents', '')
		}, [dpositionCode])

		useEffect(() => {
			if (prevValue) {
				setFieldValue('positionParents', '')
				setFieldValue('dpositionCode', null)
				setFieldValue('dharmfulConditionsTypeCode', null)
			}
		}, [dworkingConditionsCode])

		useEffect(() => {
			if (immigrant) {
				setFieldValue('dworkingConditionsCode', '0')
			}
		}, [immigrant])

		useEffect(() => {
			if (!values.dateFrom || isAfter2014(values.dateFrom)) {
				setCurrentWorkCondition(workCondition)
			} else {
				if (values.dworkingConditionsCode === '2') {
					setFieldValue('dworkingConditionsCode', null)
					setFieldValue('dpositionCodeList1', null)
					setFieldValue('dpositionCodeList2', null)
					setFieldValue('dpositionCodeList3', null)
				}
				setCurrentWorkCondition(workCondition.filter((it) => it.code !== '2'))
			}
		}, [values.dateFrom])

		const fieldTypePosition = lmc ? 'selectSearch' : 'selectAndInput'
		const establishedPosition = useMemo(
			() => values.destablishedPostCode || values.establishedPost,
			[values.destablishedPostCode, values.establishedPost]
		)

		const handleEstablishedPositionChange = useCallback(
			(code) => {
				setIsLmcDisabled(false)
				let position = positions?.find((item) => item.code === code)
				if (positions?.find((item) => item.code === code)) {
					/** Если значение из справочника, то заполняем destablishedPostCode */
					setFieldValue('establishedPost', lang === 'rus' ? position[lang || 'rus'] : position[lang || 'kz'])
					setFieldValue('destablishedPostCode', code)
				} else {
					/** Если ручной ввод, то заполняем значение establishedPost */
					setFieldValue('establishedPost', code)
					setFieldValue('destablishedPostCode', null)
				}
				if (lmc) {
					setFieldValue('dpositionCode', null)

					if (!code) {
						setFieldValue('destablishedPostCode', null)
						return
					}
					setFieldValue('destablishedPostCode', code)
					const eppcr = establishedPostPositionCategoryRelation.filter((rel) => rel.establishedPostCode === code)
					setFilteredPositionsByKnzByLmc(
						positionsByKnz.filter((item) => eppcr.some((x) => x.positionCode === item.code))
					)
				}
			},
			[positions, setFieldValue]
		)

		// Здесь добавляем useMemo для фильтрации positionsByKnz
		const filteredPositionsByKnz = useMemo(() => {
			return immigrant ? positionsByKnz.filter((item) => item.laborImmigrant === true) : positionsByKnz
		}, [immigrant, positionsByKnz])

		const isAfter2014 = (dateString) => {
			// Разделение строки даты на день, месяц и год
			const [day, month, year] = dateString.split('.')
			// Создание объекта Date с правильным форматом (месяцы в JS начинаются с 0)
			const dateFrom = new Date(year, month - 1, day)
			const referenceDate = new Date('2014-01-01') // для начала 2014 года
			return dateFrom >= referenceDate
		}

		return isElectronicButton ? (
			<div className={styles.col}>
				<Field
					options={positions}
					type="text"
					fieldType={fieldTypePosition}
					isRequired
					placeholder={t('position_placeholder')}
					onChange={handleEstablishedPositionChange}
					value={establishedPosition}
					hint={t('hint_position')}
					label={t('contract.info.position')}
					error={isFormDirty && !establishedPosition && { key: 'required' }}
				/>
				<FormikField name="dpositionCode">
					{({ field, meta: { touched, error } }) => (
						<Field
							fieldType="selectSearch"
							label={t('contract.info.nkz_position')}
							hint={t('hint_position_knz')}
							placeholder={t('choose_from_directory')}
							options={lmc ? filteredPositionsByKnzByLmc : filteredPositionsByKnz}
							error={(touched || isFormDirty) && error}
							isRequired
							disabled={(isEdit && isLmcDisabled) || (!isEdit && lmc && filteredPositionsByKnzByLmc.length === 1)}
							{...field}
						/>
					)}
				</FormikField>
			</div>
		) : (
			<div className={styles.col}>
				<div className={styles.fields}>
					<Field
						options={positions}
						type="text"
						fieldType={fieldTypePosition}
						isRequired
						placeholder={t('position_placeholder')}
						onChange={handleEstablishedPositionChange}
						value={establishedPosition}
						hint={t('hint_position')}
						label={t('contract.info.position')}
						error={isFormDirty && !establishedPosition && { key: 'required' }}
					/>
					<FormikField name="dworkingConditionsCode">
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType="select"
								isRequired
								label={t('contract.info.working_condition')}
								placeholder={t('choose_from_directory')}
								options={currentWorkCondition}
								error={(touched || isFormDirty) && error}
								type="text"
								hint={immigrant ? '' : t('hint_working_condition')}
								{...field}
								disabled={immigrant}
							/>
						)}
					</FormikField>
				</div>
				<FormikField name="dpositionCode">
					{({ field, meta: { touched, error } }) => (
						<Field
							fieldType="selectSearch"
							label={t('contract.info.nkz_position')}
							hint={t('hint_position_knz')}
							placeholder={t('choose_from_directory')}
							options={lmc ? filteredPositionsByKnzByLmc : filteredPositionsByKnz}
							error={(touched || isFormDirty) && error}
							isRequired
							disabled={(isEdit && isLmcDisabled) || (!isEdit && lmc && filteredPositionsByKnzByLmc.length === 1)}
							{...field}
						/>
					)}
				</FormikField>
				<FormikField name="positionParents">
					{({ field }) => (
						<Field
							labelClassName={styles.formLabel}
							type="text"
							fieldType="textarea"
							label={t('hierarchy_categories')}
							rows={5}
							disabled={true}
							{...field}
						/>
					)}
				</FormikField>
			</div>
		)
	}
)
