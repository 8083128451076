import { getCurrentLanguage } from '@/i18next'
import { Clock, SuccessMark, WorkerDots, CrossRounded } from '@app/images'
import styles from './SignStatus.module.scss'
import { useTranslation } from 'react-i18next'

const colors = {
	1: styles.signed,
	2: styles.wait,
	3: styles.wait,
	4: styles.wait,
	5: styles.wait,
	6: styles.rejected,
	7: styles.wait,
	8: styles.rejected,
	9: styles.rejected,
	10: styles.rejected,
	11: styles.rejected,
	12: styles.rejected,
	13: styles.wait,
	15: styles.rejected,
	16: styles.wait,
	20: styles.signed,
}

const images = {
	1: SuccessMark,
	2: Clock,
	3: WorkerDots,
	4: WorkerDots,
	5: WorkerDots,
	6: CrossRounded,
	7: WorkerDots,
	8: CrossRounded,
	9: CrossRounded,
	10: CrossRounded,
	11: CrossRounded,
	12: CrossRounded,
	13: WorkerDots,
	15: CrossRounded,
	16: WorkerDots,
	20: SuccessMark,
}

export const SignStatus = ({ item }) => {
	const lang = getCurrentLanguage()
	const { t } = useTranslation()

	const titles = {
		1: t('signed_status'),
		2: t('pending_sign'),
	}

	const { code, nameRu, rus, nameKz, kz } = item || {}

	return (
		<span className={`${styles.item} ${colors[code || 2]}`}>
			<img src={images[code || 2]} alt="status" />
			<p>{(lang === 'rus' ? nameRu || rus : nameKz || kz) || titles[code || 2]}</p>
		</span>
	)
}
