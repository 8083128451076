import { staffingActions } from '@/entity/staffing'
import { api } from '@/shared'
import { message } from 'antd'
import {
	removeStaffingStructure,
	updateStaffingStructure,
	updateGroupWorkPlaces,
	updateEditChildWorkPlaces,
	deleteFromChildWorkPlaces,
	removeGroupByExternalId,
} from '@/utils'

export const getActualStaff = () => {
	return async (dispatch) => {
		try {
			dispatch(staffingActions.toggleLoading(true))

			let body = {}
			const staff = await api.staff.getActualStaff(body)
			dispatch(staffingActions.setStaffing(staff))
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			if (error.response) {
				dispatch(staffingActions.toggleError(error))
				message.error(
					'Ошибка получения данных:' +
						(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
					10
				)
			}
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const getActualXml = (orgId) => {
	return async (dispatch) => {
		try {
			dispatch(staffingActions.toggleLoading(true))

			let body = {
				payload: {
					orgId,
				},
			}
			const xmlData = await api.staff.getActualXml(body)
			dispatch(staffingActions.setStaffXml(xmlData))
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка получения данных для подписи:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const sendActualSignStaffing = (data) => {
	return async (dispatch) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			let body = {
				data: { data },
			}
			const response = await api.staff.sendActualSignStaffing(body)
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
			message.success('Запрос новой версии штатного расписания успешно отправлен!', 5)
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка отправки данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
			dispatch(staffingActions.setStaffXml(null))
		}
	}
}

export const saveWorkPlace = (data, wpId) => {
	return async (dispatch) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			let body = {
				payload: { wpId },
				data: data,
			}
			const response = await api.staff.saveWorkPlace(body)
			dispatch(staffingActions.update_WPlace_Employee(response.wplaceEmployee)) // обновляем wplaceEmployeeList
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
			message.success('Назначение на должность успешно сохранено!', 5)
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка сохранения места работы:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const getWorkPlaceList = (wpId) => {
	return async (dispatch) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			dispatch(staffingActions.setWPlaceEmployeeStatInfo(null))
			let body = {
				payload: { wpId },
			}

			let workPlaceList = await api.staff.getWorkPlaceList(body)
			// Проверка если workPlaceList пуст
			let workPlaceListNew = workPlaceList?.employeeList ? workPlaceList?.employeeList : []
			dispatch(staffingActions.setWPlaceEmployee(workPlaceListNew))
			dispatch(staffingActions.setWPlaceEmployeeStatInfo({
				freeWorkplaceTariffRateSum: workPlaceList?.freeWorkplaceTariffRateSum || 0,
				workplaceTariffRateSum: workPlaceList?.workplaceTariffRateSum || 0,
			}))
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка получения назначенных на должность:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const deleteWorkPlace = (data, wpId) => {
	return async (dispatch) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			let body = {
				data: data,
				payload: { wpId },
			}
			const response = await api.staff.deleteWorkPlace(body)
			if (response === '') {
				dispatch(staffingActions.delete_WPlace_Employee(data.externalId))
				dispatch(staffingActions.toggleLoading(false))
				dispatch(staffingActions.toggleSuccess(true))
				message.success('Сотрудник успешно удален!', 5)
			}
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка снятие с должности:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const getEmployeeListXml = () => {
	return async (dispatch) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			let body = {}
			const employeeListXml = await api.staff.getEmployeeListXml(body)
			dispatch(staffingActions.setEmployeeXml(employeeListXml))
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка получения списка сотрудников для подписания:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const sendEmployeeList = (data) => {
	return async (dispatch) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			let body = {
				data: data,
			}
			const response = await api.staff.sendEmployeeListSign(body)
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
			message.success('Назначения успешно отправлены!', 5)
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка отправки данных: ' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
			dispatch(staffingActions.setEmployeeXml(null))
		}
	}
}

export const deleteWorkPlaceEsutd = (wpEmployeeId) => {
	return async (dispatch) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			let body = {
				payload: { wpEmployeeId },
			}
			const xmlDel = await api.staff.delXMLEmployee(body)
			dispatch(staffingActions.setDelEmployeeXml(xmlDel))
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(false))
			message.error(
				'Ошибка удаления сотрудника:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const sendDelWorkPlaceEsutd = (externalId, signXml) => {
	return async (dispatch) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			let body = {
				data: { signXml },
			}

			const response = await api.staff.sendDelWorkPlaceEsutd(body)
			if (response === '') {
				dispatch(staffingActions.delete_WPlace_Employee(externalId))
				dispatch(staffingActions.toggleLoading(false))
				dispatch(staffingActions.toggleSuccess(true))
				message.success('Сотрудник успешно удален!', 5)
			}
		} catch (error) {
			dispatch(staffingActions.toggleError(error))
			dispatch(staffingActions.toggleLoading(false))
			message.error(
				'Ошибка отправки данных: ' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
			dispatch(staffingActions.setDelEmployeeXml(null))
		}
	}
}

export const saveWorkPlaceEsutd = (data) => {
	return async (dispatch) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			let body = {
				data: data,
			}
			const xmlEdit = await api.staff.saveXMLEmployee(body)
			dispatch(staffingActions.setEditEmployeeXml(xmlEdit))
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
		} catch (error) {
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка получения данных для подписания' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const sendEditWorkPlaceEsutd = (wpId, data) => {
	return async (dispatch) => {
		try {
			let body = {}
			dispatch(staffingActions.toggleLoading(true))
			const response = await api.staff.sendEditWorkPlaceEsutd({
				...body,
				data: data,
			})
			if (response === '') {
				let workPlaceList = await api.staff.getWorkPlaceList({
					...body,
					payload: { wpId },
				})
				// Проверка если workPlaceList пуст
				workPlaceList = workPlaceList ? workPlaceList : []
				dispatch(staffingActions.setWPlaceEmployee(workPlaceList))
				dispatch(staffingActions.setEmployeeXml(workPlaceList))
				dispatch(staffingActions.setEditEmployeeXml(null))
			}
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
			message.success('Изменения успешно отправлены!', 5)
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleError(error))
			dispatch(staffingActions.setEditEmployeeXml(null))
			message.error(
				'Ошибка добавления данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const setActualEditStructure = (payload) => {
	const { transformedStructure, onSuccess, type } = payload

	return async (dispatch) => {
		try {
			const editStructureXml = await api.staff.editActual(transformedStructure, type)

			if (editStructureXml) {
				dispatch(staffingActions.setEditActualStructureXml(editStructureXml))

				onSuccess(editStructureXml)
			}
		} catch (error) {
			message.error(
				'Ошибка добавления данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		}
	}
}

export const sendSignActualStructure = (payload) => {
	const { data, onSuccess, type } = payload

	return async (dispatch, getState) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			dispatch(staffingActions.sendSignedActualStructureXml())
			const res = await api.staff.sendSignedActualXml(data, type)
			const { structure } = res
			const { staffing } = getState()
			// функция обновления проекта
			const updatedProject = {
				...staffing.staffing.data,
				childs: staffing.staffing.data.childs.map((child) => ({
					...child,
					childs: updateStaffingStructure(child.childs, structure.externalId, structure),
				})),
				currVersion: res.staffVersion,
			}
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
			dispatch(staffingActions.setStaffing(updatedProject))

			onSuccess()
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка изменения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const setActualRemoveStructure = (payload) => {
	const { externalId, onSuccess } = payload

	return async (dispatch) => {
		try {
			const removeStructureXml = await api.staff.removeActualStructure(externalId)

			if (removeStructureXml) {
				dispatch(staffingActions.setRemoveActualStructureXml(removeStructureXml))

				onSuccess(removeStructureXml)
			}
		} catch (error) {
			message.error(
				'Ошибка удаления данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		}
	}
}

export const removeSignActualStructure = (payload) => {
	const { data, onSuccess, removeStructId } = payload

	return async (dispatch, getState) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			dispatch(staffingActions.sendSignedActualStructureXml())
			const res = await api.staff.sendActualStructure(data)

			const { staffing } = getState()
			// функция обновления проекта
			const updatedProject = {
				...staffing.staffing.data,
				childs: staffing.staffing.data.childs.map((child) => ({
					...child,
					childs: removeStaffingStructure(child.childs, removeStructId),
					currVersion: res,
				})),
			}
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
			dispatch(staffingActions.setStaffing(updatedProject))

			onSuccess()
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка удаления структуры:' +
					(error && error.response && error.response.data ? error.response.data.message : ' Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const setActualEditGroup = (payload) => {
	const { newGroupWorkPlace, onSuccess, type } = payload

	return async (dispatch) => {
		try {
			const editGroupXml = await api.staff.editActual(newGroupWorkPlace, type)

			if (editGroupXml) {
				dispatch(staffingActions.setEditActualGroupXml(editGroupXml))

				onSuccess(editGroupXml)
			}
		} catch (error) {
			message.error(
				'Ошибка изменения группы:' +
					(error && error.response && error.response.data ? error.response.data.message : ' Неизвестная ошибка'),
				10
			)
		}
	}
}

export const sendSignActualGroup = (payload) => {
	const { data, onSuccess, type, activeStructId } = payload

	return async (dispatch, getState) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			dispatch(staffingActions.sendSignedActualGroupXml())
			const res = await api.staff.sendSignedActualXml(data, type)
			const { posGroup } = res
			const { staffing } = getState()
			const updatedProject = {
				...staffing.staffing.data,
				childs: updateGroupWorkPlaces(staffing.staffing.data.childs, activeStructId, posGroup.externalId, posGroup),
				currVersion: res.staffVersion,
			}
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
			dispatch(staffingActions.setStaffing(updatedProject))

			onSuccess()
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка изменения группы:' +
					(error && error.response && error.response.data ? error.response.data.message : ' Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const setActualWorkPlace = (payload) => {
	const { newWorkPlace, onSuccess, type } = payload

	return async (dispatch) => {
		try {
			const editWorkPlaceXml = await api.staff.editActual(newWorkPlace, type)

			if (editWorkPlaceXml) {
				dispatch(staffingActions.setEditActualWorkPlaceXml(editWorkPlaceXml))

				onSuccess(editWorkPlaceXml)
			}
		} catch (error) {
			message.error(
				'Ошибка изменения рабочего места:' +
					(error && error.response && error.response.data ? error.response.data.message : ' Неизвестная ошибка'),
				10
			)
		}
	}
}

export const sendSignActualWorkPlace = (payload) => {
	const { data, onSuccess, type, activeStructId, positionGroupExternalId } = payload

	return async (dispatch, getState) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			dispatch(staffingActions.sendSignedActualWorkPlaceXml())

			const res = await api.staff.sendSignedActualXml(data, type)

			const { workPlace } = res
			const { staffing } = getState()
			const updatedProject = {
				...staffing.staffing.data,
				childs: updateEditChildWorkPlaces(
					staffing.staffing.data.childs,
					activeStructId,
					positionGroupExternalId,
					workPlace
				),
				currVersion: res.staffVersion,
			}
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
			dispatch(staffingActions.setStaffing(updatedProject))

			onSuccess()
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка изменения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const setActualRemoveGroup = (payload) => {
	const { externalId, onSuccess, type } = payload

	return async (dispatch) => {
		try {
			const removeGroupXml = await api.staff.removeActual(externalId, type)

			if (removeGroupXml) {
				dispatch(staffingActions.setRemoveActualGroupXml(removeGroupXml))

				onSuccess(removeGroupXml)
			}
		} catch (error) {
			message.error(
				'Ошибка удаления группы:' +
					(error && error.response && error.response.data ? error.response.data.message : ' Неизвестная ошибка'),
				10
			)
		}
	}
}

export const removeSignActualGroup = (payload) => {
	const { data, onSuccess, type, groupId } = payload

	return async (dispatch, getState) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			dispatch(staffingActions.sendRemoveActualGroupXml())

			const res = await api.staff.sendSignedActualXmlRemove(data, type)

			const { staffing } = getState()

			const updatedProject = {
				...staffing.staffing.data,
				childs: removeGroupByExternalId(staffing.staffing.data.childs, groupId),
				currVersion: res,
			}

			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
			dispatch(staffingActions.setStaffing(updatedProject))

			onSuccess()
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка удаления данных:' +
					(error && error.response && error.response.data ? error.response.data.message : ' Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const setActualRemoveWorkPlace = (payload) => {
	const { externalId, onSuccess, type } = payload

	return async (dispatch) => {
		try {
			const removeWorkPlaceXml = await api.staff.removeActual(externalId, type)

			if (removeWorkPlaceXml) {
				dispatch(staffingActions.setRemoveActualWorkPlaceXml(removeWorkPlaceXml))

				onSuccess(removeWorkPlaceXml)
			}
		} catch (error) {
			message.error(
				'Ошибка удаления рабочего места:' +
					(error && error.response && error.response.data ? error.response.data.message : ' Неизвестная ошибка'),
				10
			)
		}
	}
}

export const removeSignActualWorkPlace = (payload) => {
	const { data, onSuccess, type, externalId, removeStructId, positionGroupExternalId } = payload

	return async (dispatch, getState) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			dispatch(staffingActions.sendRemoveActualWorkPlaceXml())

			const res = await api.staff.sendSignedActualXmlRemove(data, type)

			const { staffing } = getState()

			const updatedProject = {
				...staffing.staffing.data,
				childs: deleteFromChildWorkPlaces(
					staffing.staffing.data.childs,
					removeStructId,
					positionGroupExternalId,
					externalId
				),
				currVersion: res,
			}

			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
			dispatch(staffingActions.setStaffing(updatedProject))

			onSuccess()
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка изменения данных:' +
					(error && error.response && error.response.data ? error.response.data.message : ' Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const getStatInfo = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			dispatch(staffingActions.getStatInfo(null))
			const res = await api.staff.getStatInfo(payload)

			if (res) {
				dispatch(staffingActions.getStatInfo(res))
				dispatch(staffingActions.toggleLoading(false))
				dispatch(staffingActions.toggleSuccess(true))
			}
		}  catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка:' +
				(error && error.response && error.response.data ? error.response.data.message : ' Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}