import styles from './Alert.module.scss'
import React from 'react'
import cl from 'classnames'

export const Alert = ({
												title,
												text,
												icon,
											}) => {
	return (
		<div className={styles.information}>
			<div className={styles.hintIcon}>
				{icon}
			</div>
			<div className={styles.textContainer}>
				<div className={cl(styles.title, styles.text)}>{title}</div>
				<div className={cl(styles.label, styles.text)}>{text}</div>
			</div>
		</div>
	)
}