import { Button as FormButton, ModalTemplate, Title } from '@/ui'
import styles from './Form.module.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, List, ListItemButton, ListItemText } from '@mui/material'
import cn from 'classnames'
import ModalReset from '@/entity/contracts/ui/form/components/NewTemplate/ModalReset'
import IconSave from '@features/contracts/actions/view/IconSave'
import ListItem from '@mui/material/ListItem'
import ModalConfirm from '@/entity/contracts/ui/form/components/NewTemplate/ModalConfirm'
import { datePickerlocaleKz, datePickerlocaleRu } from '@/i18next'
import TableSign from '@features/eCollectiveContracts/registration/view/TableSign/TableSign'
import { TitleContractInfo, TradeUnionDecline } from '@features/eCollectiveContracts/registration/component'
import { useDispatch, useSelector } from 'react-redux'
import { eColContractsSelectors, eColContractsThunks, userSelectors } from '@/entity'
import { Field } from '@/ui'
import {
	EmployerSignModal,
	EmployerDecline,
	TradeUnionSignModal,
} from '@features/eCollectiveContracts/registration/component/'
import { Edit } from '@app/icons'
import { RevertModal } from '@features/contracts/actions/view/components'
import { downloadFileByUid } from '@/shared/api/rest/pension/downloadFileByUid'
import ECollectivePdfViewer from '@/entity/eCollectiveContracts/ui/form/PdfViewer/ECollectivePdfViewer'
import { notypeFileByUid } from '@/shared/api/rest/pension/notypeFileByUid'

export const Project = ({
	contract,
	handleEdit,
	documentState,
	onCancel,
	handleSendToEmployer,
	currentEcd,
	isTradeUnion,
	onClose,
	isEmployee,
	attachProjectFileData
}) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()

	const dispatch = useDispatch()
	const activeRole = useSelector(userSelectors.activeRole)
	const currentEcdEdit = useSelector(eColContractsSelectors.currentEcdEdit)
	const refContainer = useRef(null)
	const refSubContainer = useRef(null)
	const [lang, setLang] = useState('rus')
	const [modalConfirmOpen, setModalConfirmOpen] = useState(false)
	const [modalConfirmMessage, setModalConfirmMessage] = useState('')
	//TradeUnion
	const [isTradeUnionOpenSign, setIsTradeUnionOpenSign] = useState(false)
	const [isOpenRevoke, setIsOpenRevoke] = useState(false)
	const [isTradeUnionDeclineOpen, setIsTradeUnionDeclineOpen] = useState(false)
	// EMPLOYER
	const [isEmployerOpenSign, setIsEmployerOpenSign] = useState(false)
	const [isEmployerDeclineOpen, setIsEmployerDeclineOpen] = useState(false)
	const [stateCode, setStateCode] = useState('')
	const [disableDownload, setDisableDownload] = useState(false)
	const [isPdfFile, setPdfFile] = useState(null)
	const [eColFile, setEColFile] = useState(attachProjectFileData)

	useEffect(async () => {
		if (eColFile) {
			const response = await notypeFileByUid(eColFile.id)
			setPdfFile(response)
		}
	}, [eColFile])

	useEffect(() => {
		if (contract && contract?.files && contract?.files?.length !== 0) {
			setEColFile(contract.files[0])
		}
	}, [contract])

	useEffect(() => {
		if (!!currentEcd) {
			setStateCode(contract.state.code)
		}
	}, [currentEcd])

	const handleSaveTemplate = async (isSendToEmployer) => {
		handleSendToEmployer(isSendToEmployer)
	}

	const handleDecline = () => {
		setIsEmployerDeclineOpen(false)
		setIsTradeUnionDeclineOpen(false)
		onCancel()
		onClose()
	}

	const handleCloseDecline = () => {
		setIsEmployerDeclineOpen(false)
		setIsTradeUnionDeclineOpen(false)
	}

	const changeEditStatus = () => {
		handleEdit()
	}


	const handleRevertTradeUnion = () =>
		dispatch(eColContractsThunks.revokeTradeUnion(contract.externalId, isEmployee, onClose))

	const downloadContractPDF = async (e) => {
		e.preventDefault()
		e.stopPropagation()
		setDisableDownload(true)
		setTimeout(() => {
			setDisableDownload(false)
		}, 5000)
		const response = await downloadFileByUid(currentEcd.downloadFile.id)
		const file = URL.createObjectURL(response)
		const a = document.createElement('a')
		a.href = file
		a.download = currentEcd.downloadFile.fileName
		document.body.appendChild(a)
		a.click()
	}

	return (
		<ModalTemplate onClose={onCancel}>
			<div className={`scroll ${styles.wrapper}`}>
				{!!contract && !!documentState ? (
					<>
						<TitleContractInfo contract={contract} documentState={documentState} t={t} />
						{stateCode === '1' ? (
							<div className={styles.actions}>
								<FormButton onClick={(e) => downloadContractPDF(e)} disabled={disableDownload}>
									{t('download_pdf_ekd')}
								</FormButton>
							</div>
						) : null}
					</>
				) : (
					<div className={styles.mainInformation}>
						<Title>{t('col_contract.project_title')}</Title>
					</div>
				)}
				<div>
					<ECollectivePdfViewer isPdfFile={isPdfFile} />
				</div>
				{!!currentEcd && stateCode === '6' && (
					<div className={styles.employee__comment__wrapper}>
						<div className={styles.ecd__title}>{t('col_contract.comment_employer')}</div>
						<div className={styles.employee__comment}>{currentEcd.note}</div>
					</div>
				)}
				<div className={isTradeUnion ? styles.buttons__wrapper : styles.buttons__wrapper_employer}>
					{stateCode !== '1' ? (
						<>
							{isTradeUnion ? (
								<>
									{documentState ? (
										<>
											{currentEcdEdit ? (
												<Button
													type="button"
													startIcon={<IconSave />}
													classes={{ root: cn(styles.button, styles.button__save) }}
													sx={{ paddingLeft: '20px !important' }}
													onClick={handleSaveTemplate.bind(null, false)}
												>
													{t('save')}
												</Button>
											) : (
												<Button
													type="button"
													disabled={!['3', '6', '8', '9', '10', "15"].includes(stateCode)}
													startIcon={<Edit fill={'#17B67C'} className={styles.edit__icon} />}
													classes={{
														root: cn(styles.button_etd, styles.button__save),
														disabled: styles.button__disabled,
													}}
													sx={{ paddingLeft: '20px !important' }}
													onClick={changeEditStatus}
												>
													{t('edit_button')}
												</Button>
											)}
										</>
									) : (
										<>
											<Button
												type="button"
												startIcon={<IconSave />}
												classes={{ root: cn(styles.button, styles.button__save) }}
												sx={{ paddingLeft: '20px !important' }}
												onClick={handleSaveTemplate.bind(null, false)}
											>
												{t('save')}
											</Button>
										</>
									)}
									<FormButton
										type="submit"
										disabled={!['3', '6', '8', '9', '10', '15', ""].includes(stateCode)}
										onClick={handleSaveTemplate.bind(null, true)}
									>
										{t('col_contract.send_to_sign_employer')}
									</FormButton>
								</>
							) : null}
						</>
					) : null}
				</div>
				<div className={styles.status__wrapper}>
					{!!currentEcd ? <TableSign currentEcd={currentEcd} /> : null}
					{
						(stateCode === "15")
							? <div className={styles.rejectWrapper} >
								<Title>{t('ecollective_contract.comment_from_the_labor_inspectorate')}</Title>
								<Field
									fieldType={'textarea'}
									label={""}
									value={currentEcd?.otibStatus?.note ?? ""}
									placeholder={""}
									showCount
									rows={6}
								/>
							</div> : <></>
					}

					{
						currentEcd && currentEcd?.otibStatus && currentEcd?.otibStatus?.inspectorList
							&& currentEcd.otibStatus.inspectorList.length !== 0 ?
							<>
								<section className={styles.sectionWrapper} >
									<Title>Информация о сотруднике инспекции по труду</Title>
									<div className={styles.container}>
										<div className="flex-1">
											{
												currentEcd.otibStatus.inspectorList.map((el, i) => (
													<div key={i} className={styles.tableContainer}>
														<p className={styles.subTitle}>Инспектор по труду:</p>
														<div className={styles.row}>
															<div className={styles.boldCell}>{t('staffing_table.iin')}:</div>
															<div className={styles.cell6}>{el.inspectorIin ?? "-"}</div>
														</div>
														<div className={styles.row}>
															<div className={styles.boldCell}>{t('fio')}:</div>
															<div className={styles.cell6}>{el.inspectorFio ?? "-"}</div>
														</div>
														<div className={styles.row}>
															<div className={styles.boldCell}>{t('profUnions.position')}:</div>
															<div className={styles.cell6}>{language === "rus" ? el.inspectorPosRu ?? "-" : el.inspectorPosKz ?? "-"}</div>
														</div>
														<div className={styles.row}>
															<div className={styles.boldCell}>{t('phoneMobile')}:</div>
															<div className={styles.cell6}>{el?.inspectorMobilePhone?.length !== 0 ? el.inspectorMobilePhone : "-"}</div>
														</div>
														<div className={styles.row}>
															<div className={styles.boldCell}>{t('phoneOffice')}:</div>
															<div className={styles.cell6}>{el?.inspectorWorkPhone?.length !== 0 ? el.inspectorWorkPhone : "-"}</div>
														</div>
													</div>
												))
											}
										</div>
									</div>
								</section>
							</>
							: <></>
					}
					{!!currentEcd && stateCode !== '1' ? (
						<Box display="flex" alignItems="center" justifyContent="flex-end" className={styles.buttons__wrapper_sign}>
							{isTradeUnion && stateCode === '4' && (
								<FormButton
									buttonClassName={styles.decline__button}
									onClick={setIsTradeUnionDeclineOpen.bind(null, true)}
								>
									{t('decline')}
								</FormButton>
							)}
							{isTradeUnion && stateCode === '7' && (
								<FormButton buttonClassName={styles.decline__button} onClick={setIsOpenRevoke.bind(null, true)}>
									{t('actions.revoke')}
								</FormButton>
							)}
							{!isTradeUnion && activeRole === 'ROLE_EMPLOYER' && (
								<FormButton
									disabled={stateCode === '4' || stateCode === "16"}
									buttonClassName={styles.decline__button}
									onClick={setIsEmployerDeclineOpen.bind(null, true)}
								>
									{t('decline')}
								</FormButton>
							)}
							{!isTradeUnion && activeRole === 'ROLE_EMPLOYER' && (
								<FormButton disabled={stateCode === '4' || stateCode === "16"} onClick={setIsEmployerOpenSign.bind(null, true)}>
									{t('sign_button')}
								</FormButton>
							)}
							{isTradeUnion && (
								<FormButton disabled={stateCode !== '4'} onClick={setIsTradeUnionOpenSign.bind(null, true)}>
									{t('sign_button')}
								</FormButton>
							)}
							<div className={styles.buttonEnd}>
								<FormButton onClick={onCancel}>{t('close')}</FormButton>
							</div>
						</Box>
					) : (
						<div className={styles.buttonEnd}>
							<FormButton onClick={onCancel}>{t('close')}</FormButton>
						</div>
					)}
				</div>
			</div>
			<ModalConfirm
				onClose={() => setModalConfirmOpen(false)}
				isOpen={modalConfirmOpen}
				message={modalConfirmMessage}
			/>
			{!!currentEcd ? (
				<>
					{isTradeUnion ? (
						<>
							<RevertModal
								title={'col_contract.revert_title'}
								onClose={setIsOpenRevoke.bind(null, false)}
								onRevert={handleRevertTradeUnion}
								isOpen={isOpenRevoke}
							/>
							<TradeUnionDecline
								title={'col_contract.decline_title_trade_union'}
								isEmployee={isEmployee}
								onClose={handleDecline}
								externalId={contract.externalId}
								isOpen={isTradeUnionDeclineOpen}
								handleCloseDecline={handleCloseDecline}
							></TradeUnionDecline>
							<TradeUnionSignModal
								onClose={setIsTradeUnionOpenSign.bind(null, false)}
								isOpen={isTradeUnionOpenSign}
								externalId={contract.externalId}
								isEmployee={isEmployee}
							></TradeUnionSignModal>
						</>
					) : (
						<>
							<EmployerDecline
								onClose={handleDecline}
								externalId={contract.externalId}
								isOpen={isEmployerDeclineOpen}
								handleCloseDecline={handleCloseDecline}
							></EmployerDecline>
							<EmployerSignModal
								onClose={setIsEmployerOpenSign.bind(null, false)}
								isOpen={isEmployerOpenSign}
								externalId={contract.externalId}
							></EmployerSignModal>
						</>
					)}
				</>
			) : null}
		</ModalTemplate>
	)
}
